/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { makeStyles, Grid, Typography, Paper } from '@material-ui/core';
import { useGlobalStyles } from '../../style/globalStyles';
import { Message } from './MessagerieType';
import clsx from 'clsx';
import { isEmptyVal } from '../../utils/utils';
import { useCurrentUser } from '../auth/authApi';


const useStyles = makeStyles(() => ({
  messageTextRightStyle: {
    color: "white"
  },
  backgroundRight: {
    backgroundColor: "#0075b5",
    padding: 5
  },
  backgroundLeft: {
    backgroundColor: "#f5f5f5",
    padding: 5
  },
})
)

export interface BlocMessageProps {
  message: Message;
}

const BlocMessage = (props: BlocMessageProps) => {
  const { backgroundLeft, backgroundRight, messageTextRightStyle } = useStyles();
  const { message } = props;
  const globalClasses = useGlobalStyles();
  const { mobileSpacing } = globalClasses;
  const {proprietes} = useCurrentUser();
  //nomUserExp

  return (
    <Grid container style={{ padding: 5 }} justify={message?.displayRight ? "flex-end" : "flex-start"}>
      <Grid item xs={9} sm={8}>
        <Paper className={clsx(message?.displayRight ? backgroundRight : backgroundLeft)}>
          <Grid item xs={12} sm={12} className={mobileSpacing}>
            <Typography
              variant="body1"
              style={{ fontSize: "95%", whiteSpace: "pre-line" }}
              className={message?.displayRight ? messageTextRightStyle : ""}
              align={message?.displayRight ? "right" : "left"}
              gutterBottom
              paragraph={true}
            >
              {message.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className={mobileSpacing} >
            <Typography
              variant="body1"
              style={{ fontSize: "85%", fontStyle: 'italic' }}
              className={message?.displayRight ? messageTextRightStyle : ""}
              align={message?.displayRight ? "right" : "left"}
              gutterBottom >
                {proprietes.TYPE_UTILISATEUR?.valeur === "UTILISATEUR" && (
                  message?.displayRight ? "Envoyé le : " + message.dateEnvoie + " par " + message.nomUserExp : "Reçu le : " + message.dateEnvoie
                )}
                {proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE" && (
                  (message?.displayRight ? "Envoyé le : " : "Reçu le : ") + message.dateEnvoie
                )}             
            </Typography>
          </Grid>
        </Paper>
        {!isEmptyVal(message.vu?.dateVu) && (
          <Grid container alignItems='center'>
            <Grid item xs={12} sm={12} className={mobileSpacing} >
              <Typography
                variant="body1"
                style={{ fontSize: "85%", color: "#808080" }}
                className={message?.displayRight ? messageTextRightStyle : ""}
                align={message?.displayRight ? "right" : "left"}
                gutterBottom >
                {"Vu le : " + message.vu.dateVu}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default BlocMessage;