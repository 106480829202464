const colors = {
  blanc: "#FFFFFF",

  gris40: "#F2F2F2",
  gris30: "#DDDDDD",
  gris20: "#B7B7B7",
  gris10: "#878787",
  gris: "#545454",
  noir: "#212121",

  bleu: "#0075B5",
  jaune: "#F8B413",
  vert: "#599B49",
  rouge: "#F04E40",

  contrat: "#2AB3A6",
  cotation: "#FF6B35",

  active: "#FCC031",

  emc2:"#0075B5",
  loeb: "#218666",
  houpiez: "#05603D",

  activeHoupiez: "#05603D",
  activeTextHoupiez: "#F8B413"
};



export default colors;