import { AppThunk } from "../../app/store";
import { simpleGet } from "../../services/restUtils";
import {  User, } from "./usersTypes";
import { Tag,UserInfoCompteDto } from "./MonCompteType";

export const fetchInfoUser = (funcSuccess: (data: User) => void): AppThunk => async dispatch => {
  simpleGet(dispatch, "users", "getinfouser", "", funcSuccess);
}

export const fetchListTag = (funcSuccess: (data: Tag[]) => void): AppThunk => async dispatch => {
  simpleGet(dispatch, "enquetes", "listtags", "", funcSuccess);
}

export const fetchListTagUser = (idUserConvention: number,funcSuccess: (data: string[]) => void): AppThunk => async dispatch => {
  simpleGet(dispatch, "users", "listtaguser", idUserConvention+"", funcSuccess);
}

export const fetchInfoUserPaneliste = (idUserConvention: number,funcSuccess: (data: UserInfoCompteDto) => void): AppThunk => async dispatch => {
  simpleGet(dispatch, "users", "getInfoComptePaneliste", idUserConvention+"", funcSuccess);
}

