import React from 'react';
import { SizeContainer } from '../components/MyContainer';
import { Typography, makeStyles } from '@material-ui/core';
import colors from '../style/colors';
import { BOTTOMNAV_HEIGHT } from '../style/theme';
import FooterImg from "../img/footer/footer.jpg";
import FooterImgMobile from "../img/footer/footer_mobile.jpg";
import { useMobileMode } from '../utils/utils';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down("xs")]: {
      marginBottom: BOTTOMNAV_HEIGHT
    },
    textAlign: "center"
  },
  typo: {
    color: colors.gris10
  }
}));

const Footer: React.FC = () => {
  const { footer, typo } = useStyles();
  const mobileMode = useMobileMode();

  return (
    <footer className={footer}>
      <SizeContainer>
        <img height={mobileMode?"75px":"75px"} src={mobileMode ? FooterImgMobile : FooterImg}/>
        <Typography variant="h6" align="center" component="p" className={typo}> 
          Copyright 2023 - EMC2
        </Typography>
      </SizeContainer>
    </footer>
  );
}

export default Footer