/* eslint-disable react/display-name */
import {createStyles, makeStyles, Typography} from "@material-ui/core";
import { Action, Column} from "material-table";
import {Accessibility, Edit} from '@material-ui/icons';
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import colors from "../../style/colors";
import { Tag } from "./TagType";

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    }
  })
)


export interface TableTagsProps<T extends object> extends MyMaterialTableProps<T> {
  fctEditTag: (tag: Tag) => void;
  fctDetailTag: (tag: Tag) => void;
}

export const TableTags = (props: TableTagsProps<Tag>) => {
  const { libelleTableau } = useStyles();

  const { fctEditTag, fctDetailTag } = props;
  
  const columns: Column<Tag>[] = [
    { title: "Code", render: sc => <Typography className={libelleTableau}>{sc.code}</Typography> ,align: "left" },
    { title: "Libellé", render: sc => <Typography className={libelleTableau}>{sc.libelle}</Typography> ,align: "left" },
    { title: "Actif", render: sc => <Typography className={libelleTableau}>{sc.statutLibelle}</Typography> ,align: "left" }
  ];

  const actions: (Action<Tag> | ((sc: Tag) => Action<Tag>))[] = [
    sc => 
    ({icon: () => ( <Edit htmlColor={colors.jaune} fontSize="large"></Edit> ),onClick: (event, sc) => fctEditTag(sc as Tag)}),
    ({icon: () => ( <Accessibility htmlColor={colors.jaune} fontSize="large"></Accessibility> ),onClick: (event, sc) => fctDetailTag(sc as Tag)})

  ]

  return <div>
            <MyMaterialTable<Tag> columns={columns} {...props}  actions={actions}  sort={true} searchAff={true} toolBarAff={true} customBodyHeight={"500px"} customHeaderBckGrdColor={"#F2F2F2"}/>
         </div>;
};
