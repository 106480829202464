/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid, Typography, TextareaAutosize, IconButton, Dialog, DialogContent, DialogContentText, DialogActions, Button, DialogTitle } from '@material-ui/core';
import { isEmpty, isEmptyVal, useMobileMode } from '../../utils/utils';
import { useGlobalStyles } from '../../style/globalStyles';
import { Conversation, ConversationCreate, CreateMessage, Message } from './MessagerieType';
import BlocMessage from './BlocMessage';
import { ArrowBack, CheckCircle, Lock, Send } from '@material-ui/icons';
import { ChangeHandlerTextArea } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { archiveConv, createConv, createMessage, fetchMessages } from './MessagerieApi';
import { useCurrentUser } from '../auth/authApi';
import logoMessagerie from "../../img/logo/logo_messagerie.png"


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10
  },
  textAreaStyle: {
    fontFamily: "IBM Plex Sans, sans-serif",
    fontSize: "0.875rem",
    lineHeight: "1.5",
    borderRadius: "12px",
    color: "#24292f",
    background: '#fff',
    border: "1px solid  #d0d7de",
    boxShadow: "0px 2px 2px #f6f8fa",
    "&:hover": {
      borderColor: "#3399FF",
    },
    "&:focus": {
      borderColor: "#3399FF",
      boxShadow: "0 0 0 3px #90CAF9",
    },
    // firefox
    "&:focus-visible": {
      outline: 0,
    }
  },
  blocEcritureStyle: {
    padding: 2,
    marginTop: 1,
    marginBottom: 0
  },
  headerStyle: {
    paddingBottom: 10,
    marginBottom: 5,
    maxHeight: 50,
    overflow: "visible"
  },
  clickStyle: {
    cursor: "pointer"
  }
})
)

export interface ConversationPageProps {
  currentConversation: Conversation;
  fctSelectConversation: (conversation: Conversation) => void;
  refreshConversation: () => void;
  updateLocaleConversation: (conversation: Conversation) => void;
}

const ConversationPage = (props: ConversationPageProps) => {
  const dispatch = useDispatch();
  const { userConvention, proprietes } = useCurrentUser();
  const { textAreaStyle, blocEcritureStyle, headerStyle, clickStyle } = useStyles();
  const { currentConversation, fctSelectConversation, refreshConversation, updateLocaleConversation } = props;
  const globalClasses = useGlobalStyles();
  const { mobileSpacing } = globalClasses;
  const mobileMode = useMobileMode();
  const [currentMessages, setCurrentMessages] = useState<Message[]>([]);
  const [messageToSend, setMessageToSend] = useState<string>("");
  const [popUpArchive, setPopUpArchive] = useState<boolean>(false);
  const [heightMessages, setHeightMessages] = useState<string>("");
  const [popUpMessage, setPopUpMessage] = useState<boolean>(false);

  const messagesEndRef = useRef(null)
  const textEndRef = useRef(null)


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto", block: "nearest" })
    textEndRef.current?.scrollIntoView({ behavior: "auto", block: "nearest" })
  }

  function closeConv(conv: Conversation) {
    setPopUpArchive(false);

    function closeConvSuccess(data: boolean) {
      if (data) {
        refreshConversation()
      }
    }
    dispatch(archiveConv(conv.idConversation, closeConvSuccess))
  }

  const handleChangeMessage: ChangeHandlerTextArea = e => {
    const target = e.target
    setMessageToSend(target.value)
  }

  function displayPopUpMessage() {
    setPopUpMessage(true)

    setTimeout(() => {
      setPopUpMessage(false)
    }, 4000);
  }

  function initMessages(data: Message[]) {
    if (data) {
      setCurrentMessages(data)
    }
    scrollToBottom()
  }

  function addMessage() {
    const newMessage: CreateMessage = {
      idConversation: currentConversation.idConversation,
      idUsersconvention: userConvention.idUsersConvention,
      message: messageToSend,
    }

    function addMessageSucess(data: boolean) {
      if (data) {
        //RAZ du Message
        setMessageToSend("")
        //RETIRE LE MESSAGE DE CLOTURE
        currentConversation.canClose = false;
        updateLocaleConversation(currentConversation)
        //RECHARGE LES MESSAGES POUR LA CONV
        dispatch(fetchMessages(userConvention.idUsersConvention, currentConversation.idConversation, currentConversation.idUsersconvention, initMessages))
        //POPUP MESSAGE
        if (proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE") {
          displayPopUpMessage()
        }
      }
    }
    dispatch(createMessage(newMessage, addMessageSucess))
  }

  function createConversation() {
    const convApi: ConversationCreate = {
      idUsersconvention: currentConversation.idUsersconvention,
      sujet: currentConversation.sujet
    }

    function updateConv(data: Conversation) {
      if (data) {
        currentConversation.idConversation = data.idConversation;
        currentConversation.new = false
        addMessage();
      }
    }
    dispatch(createConv(convApi, updateConv))
  }

  function fctSendMessage() {
    if (!(messageToSend.trim().length > 0)) {
      return;
    }
    if (currentConversation.new === true) {
      createConversation()
    } else {
      addMessage()
    }
  }

  function initHeight() {
    if (!isEmpty(currentConversation) && currentConversation?.statut === "ACTIF") {
      if (mobileMode) {
        setHeightMessages("350px")
      } else {
        setHeightMessages("390px")
      }
    } else {
      if (mobileMode) {
        setHeightMessages("500px")
      } else {
        setHeightMessages("520px")
      }
    }
  }

  useEffect(() => {
    initHeight()
  }, [mobileMode]);

  useEffect(() => {
    //init des messages
    if (!isEmpty(currentConversation) && !currentConversation?.new) {
      dispatch(fetchMessages(userConvention.idUsersConvention, currentConversation.idConversation, currentConversation.idUsersconvention, initMessages))
    } else {
      setCurrentMessages([])
    }
    initHeight()

  }, [currentConversation]);

  return (
    <Grid container spacing={mobileMode ? 0 : 10} style={{ padding: 10, height: "625px" }}>
      <Grid item xs={12} sm={12} className={mobileSpacing}>
        {/* HEADER CONVERSATION */}
        {!mobileMode && (
          <Grid container alignItems="center" justify="center" className={headerStyle}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h3" align='center' gutterBottom >
                {currentConversation ?
                  (proprietes.TYPE_UTILISATEUR?.valeur === "UTILISATEUR" ? currentConversation.titre + " : " + currentConversation.sujet.libelle : currentConversation.titre)
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        )}
        {mobileMode && (
          <Grid container alignItems="center" justify="center" className={headerStyle}>
            <Grid item xs={2} >
              <IconButton size='medium' onClick={() => fctSelectConversation(null)}>
                <ArrowBack fontSize='large' htmlColor='#cdcdcd' />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h4" align='center' gutterBottom style={{ fontSize: "90%" }}>
                {currentConversation ?
                  (proprietes.TYPE_UTILISATEUR?.valeur === "UTILISATEUR" ? currentConversation.titre + " : " + currentConversation.sujet.libelle : currentConversation.titre)
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        )}
        {/* PAS DE CONVERSATION */}
        {isEmptyVal(currentConversation) && (
          <Grid container alignItems="center" justify="center" style={{ height: heightMessages }}>
            <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
              <img height={"275px"} src={logoMessagerie} />
              <Typography variant="h4" align='center' gutterBottom >Sélectionnez une conversation</Typography>
              {proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE" && (
                <Typography variant="h5" align='center' gutterBottom >Cliquez sur une conversation existante ou sur « Nouvelle conversation » pour en créer une</Typography>
              )}
            </Grid>
          </Grid>
        )}
        {/* CONVERSATION */}
        {currentConversation && (
          <>
            <Grid container alignItems="center" justify="center" spacing={mobileMode ? 0 : 10} style={{ height: heightMessages, overflow: "auto", marginBottom: 2 }} >
              {currentMessages.map(message => (
                // eslint-disable-next-line react/jsx-key
                <Grid container>
                  <BlocMessage message={message} />
                  <div ref={messagesEndRef}></div>
                </Grid>
              ))}
              {currentMessages.length === 0 && (
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h4" align='center' gutterBottom >Cette conversation est vide</Typography>
                    <Typography variant="h5" align='center' gutterBottom >Envoyez un message pour nous contacter</Typography>
                  </Grid>
                </Grid>
              )}
              {currentConversation.canClose && currentConversation.statut === "ACTIF" && proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE" && (
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h4" align='center' gutterBottom >{"Sans nouveau message de votre part cette conversation sera automatiquement archivée le : " + currentConversation.dateCloture}</Typography>
                    <Typography variant="h5" align='center' gutterBottom className={clickStyle} onClick={() => setPopUpArchive(true)}>Cliquez ici pour archiver la conversation maintenant</Typography>
                  </Grid>
                  <div ref={textEndRef}></div>
                </Grid>
              )}
              {currentConversation.statut === "INACTIF" && (
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h4" align='center' gutterBottom >{"Cette conversation à été archivée le : " + currentConversation.dateCloture}</Typography>
                    {proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE" && (
                      <Typography variant="h5" align='center' gutterBottom >Créez une conversation si vous souhaitez de nouveau nous contacter</Typography>
                    )}
                  </Grid>
                  <div ref={textEndRef}></div>
                </Grid>
              )}
            </Grid>
            {/* ENVOIE MESSAGE */}
            {currentConversation.statut === "ACTIF" && (
              <Grid container alignItems="center" justify="center"
                spacing={mobileMode ? 0 : 5}
                className={blocEcritureStyle}>
                <Grid item xs={7} sm={9}>
                  <Typography
                    variant='h5'
                    align='right'>
                    {messageToSend.length + "/1000"}
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={11}>
                  <TextareaAutosize
                    className={textAreaStyle}
                    value={messageToSend}
                    rowsMin={6}
                    rowsMax={6}
                    style={{ width: "100%", resize: "none" }}
                    name="message"
                    placeholder="Ecrivez nous"
                    onChange={handleChangeMessage}
                    maxLength={1000}
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  <Grid container>
                    <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                      <IconButton size='medium'
                        onClick={fctSendMessage}
                      >
                        <Send fontSize='large' htmlColor='#0075b5' />
                      </IconButton>
                    </Grid>
                    {proprietes.TYPE_UTILISATEUR?.valeur === "UTILISATEUR" && (
                      <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                        <IconButton size='medium'
                          onClick={() => setPopUpArchive(true)}
                          disabled={messageToSend.length > 0 ? true : false}
                        >
                          <Lock fontSize='small' htmlColor={messageToSend.length > 0 ? "#B7B7B7" : "#E60000"} />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {/*DIALOG ARCHIVER*/}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={popUpArchive}
        >
          <DialogContent>
            <DialogContentText color="initial">
              <Typography variant='h5'>Attention vous allez archiver la conversation</Typography>
              <Typography variant='h5'>Voulez vous continuer ?</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button key={"NON"} onClick={() => setPopUpArchive(false)} color="secondary">NON</Button>
            <Button key={"OUI"} onClick={() => closeConv(currentConversation)} color="secondary">OUI</Button>
          </DialogActions>
        </Dialog>

        {/*DIALOG ENVOIE MESSAGE*/}
        <Dialog
          open={popUpMessage}
        >
          <DialogContent>
            <DialogTitle color='initial'>
              <Grid container>
                <Grid item xs={4}>
                  <CheckCircle htmlColor='green' />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='h4'>Message envoyé !</Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContentText color="initial">
              <Typography variant='h5'>Nous allons répondre prochainement</Typography>
              <Typography variant='h5'>Vous serez notifié quand vous recevrez une réponse</Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  )
}

export default ConversationPage;