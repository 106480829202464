/* eslint-disable react/display-name */
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { Column } from "material-table";
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import { ConnexionDetail } from "./AccueilType";
import { getHoursMinutes } from "../../utils/utils";

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    }
  })
)


export const MaterialTableUserConnexion = (props: MyMaterialTableProps<ConnexionDetail>) => {
  const { libelleTableau } = useStyles();

  const columns: Column<ConnexionDetail>[] = [
    { title: "Date",field: "date", render: sc => <Typography className={libelleTableau}>{new Date(sc.date).toLocaleDateString('fr-FR')}</Typography>, align: "left"},
    { title: "Heure", render: sc => <Typography className={libelleTableau}>{getHoursMinutes(new Date(sc.date))}</Typography>, align: "left"},
    { title: "Nom",field: "nomContact", render: sc => <Typography className={libelleTableau}>{sc.nomContact}</Typography>, align: "left", filtering: true, sorting: true },
    { title: "Terminal",field: "device", render: sc => <Typography className={libelleTableau}>{sc.device}</Typography>, align: "left",filtering: true, sorting: true }
  ];

  return <div>
    <MyMaterialTable<ConnexionDetail> columns={columns} {...props} sort={true} searchAff={true} exportAff={true} toolBarAff={true} customBodyHeight={"500px"} customHeaderBckGrdColor={"#F2F2F2"} />
  </div>;
};
