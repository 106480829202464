/* eslint-disable react/display-name */
import {createStyles, makeStyles, Typography} from "@material-ui/core";
import { Column} from "material-table";
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import {  EnqueteResult,EnqueteEntete,PublicationTBD } from "./EnqueteType";
import * as XLSX from 'xlsx';

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 10,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 9,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    }
  })
)

const exportExcelAny= (renderData: {}[],fileName: string,enteteName: string[]) => {  
  const workSheet = XLSX.utils.json_to_sheet([]);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(workSheet, [enteteName]);
  XLSX.utils.sheet_add_json(workSheet, renderData, { origin: 'A2', skipHeader: true });
  XLSX.utils.book_append_sheet(workBook,workSheet,"export");
  XLSX.write(workBook,{bookType:"xlsx",type:"buffer"});
  XLSX.write(workBook,{bookType:"xlsx",type:"binary"});
  XLSX.writeFile(workBook,fileName+".xlsx");
};


export interface TableDataEnquetesProps<T extends object> extends MyMaterialTableProps<T> {
  entetes: EnqueteEntete[];
  afficheLibData: boolean;
  enquete: PublicationTBD;
}

export const TableDataEnquete = (props: TableDataEnquetesProps<EnqueteResult>) => {
  const { libelleTableau } = useStyles();

  const {entetes,afficheLibData,enquete} = props;

  const columns: Column<EnqueteResult>[] = [];

  function getData(result: EnqueteResult,code: string){
    for(const data of result.datas){
      if(data.code===code){
        return data.valeur;
      }
    }
    return "";
  }

  const headers: string[]=[];
  for(const entete of entetes){
    if(entete.code !== "IDPUBUSER"){
      columns.push({ title: afficheLibData?entete.libelle:entete.code
                    ,field: entete.code
                    ,searchable:true
                    ,customFilterAndSearch: (term, rowData) => getData(rowData,entete.code).toLocaleUpperCase().indexOf(term.toLocaleUpperCase()) !=-1
                    , render: sc => <Typography className={libelleTableau}>{getData(sc,entete.code)}</Typography> ,align: "left",width: "50px"
                    });
      headers.push(afficheLibData?entete.libelle:entete.code);
    }
   
  }

  const exportExcel= (columns: Column<EnqueteResult>[], renderData: EnqueteResult[]) => {
    const arr = [];
    for (const ligne of renderData) {
      const obj = entetes.reduce((o, entete) => ({ ...o, [afficheLibData?entete.libelle:entete.code]: getData(ligne,entete.code)}), {})
      arr.push(obj);
    }
    exportExcelAny(arr,"export_data_enquete"+(enquete?enquete.id:""),headers);
  };


  return <div>
            <MyMaterialTable<EnqueteResult> columns={columns} {...props} exportAff={true} toolBarAff={true} exportExcel={exportExcel} searchAff={true} customBodyHeight={"400px"} customHeaderBckGrdColor={"#F2F2F2"} />
         </div>;
};
