import * as React from "react";
import { IconButton, IconButtonProps, makeStyles } from "@material-ui/core";
import colors from "../style/colors";

interface SwitchImageProps extends IconButtonProps {
  image: string;
  hoverImage: string;
  height: number;
  actif?: boolean;
}

const SwitchImageButton = (props: SwitchImageProps) => {
  const { image, hoverImage, height, actif, ...otherProps } = props;
  const [hover, setHover] = React.useState(false);

  const onHoverToggle = () => setHover(!hover)

  return (
    <IconButton {...otherProps} onMouseEnter={onHoverToggle} onMouseLeave={onHoverToggle}>
      <img src={image} hidden={actif || hover} alt="defaultImage" height={height} />
      <img src={hoverImage} hidden={!actif && !hover} alt="hoverImage" height={height} />
    </IconButton>
  );
};

export default SwitchImageButton;

const useStyles = makeStyles({
  icon: {
    color: colors.gris,
    "& svg": {
      fontSize: (height: number) => height,
    },
    "&:hover": {
      color: colors.jaune
    }
  }
})

export const SwitchIconButton = (props: (IconButtonProps & { height: number })) => {
  const { children, height, ...otherProps } = props;
  const { icon } = useStyles(height);
  return <IconButton {...otherProps} color="inherit" className={icon}>{children}</IconButton>
}