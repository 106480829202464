import 'reactjs-popup/dist/index.css';
import { Button, Checkbox, FormControlLabel, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGlobalStyles } from '../../style/globalStyles';
import { useMobileMode } from '../../utils/utils';
import { fetchSaveTag, fetchTags } from './TagApi';
import { Tag, ListeTag } from './TagType';
import { TableTags } from './MaterialTableTags';
import MyTextField from '../../components/MyTextField';
import { ChangeHandler, NavElement } from '../../utils/types';
import PageTemplate from '../../page/PageTemplate';
import DetailTagPage from './DetailTagPage';

const NAV_ELEMENTS: NavElement[] = [{ name: "Tags", path: "/Tags" }];

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10
  },
  selectField: {
    paddingLeft: "6px"
  }
}))

const TagsPage = () => {
  const { paper } = useStyles();

  const dispatch = useDispatch();
  const [stepParamTag, setStepParamTag] = useState<number>(0);
  const [tagParamEncours, setTagParamEncours] = useState<Tag>(null);
  const globalClasses = useGlobalStyles();
  const { mobileSpacing } = globalClasses;
  const mobileMode = useMobileMode();
  const [listeTag, setListeTag] = useState<Tag[]>(null);
  const [showError, setShowError] = useState<boolean>(false);

  const regExCode = new RegExp('^[A-Z_]+$');

  const validForm = tagParamEncours !== null && tagParamEncours.code.trim() !== "" && tagParamEncours.libelle.trim() !== "" && regExCode.test(tagParamEncours.code);

  const handleChange: ChangeHandler = e => {
    const target = e.target

    setTagParamEncours((prevState) => ({
      ...prevState, [target.name]: target.value
    }))
  }

  const handleChangeCheckBoxActif: ChangeHandler = e => {
    const target = e.target
    let value = 'I'

    if (target.checked) {
      value = '*'
    }

    setTagParamEncours((prevState) => ({
      ...prevState, [target.name]: value
    }))
  }


  function initTags(data: ListeTag) {
    if (data) {
      setListeTag(data.tags)
    }
  }

  useEffect(() => {
    dispatch(fetchTags(initTags))
  }, []);


  function fctCreateTag() {
    const nouveauTag: Tag = {
      code: "",
      libelle: "",
      type: "",
      typeLibelle: "",
      question: "",
      statut: "*",
      statutLibelle: "Actif"
    }
    setTagParamEncours(nouveauTag)
    setStepParamTag(1)
  }

  function fctEnregistrementSuccess(data: boolean) {
    if (data) {
      setShowError(false)
      dispatch(fetchTags(initTags))
      setStepParamTag(0)
    }
  }

  function fctEnregisterTag() {
    if (validForm) {
      const tagToSave: Tag = {
        code: tagParamEncours.code,
        libelle: tagParamEncours.libelle,
        type: "ABO",
        question: tagParamEncours.libelle,
        statut: tagParamEncours.statut,
        typeLibelle: tagParamEncours.typeLibelle,
        statutLibelle: tagParamEncours.statutLibelle
      }
      dispatch(fetchSaveTag(tagToSave, fctEnregistrementSuccess))
    } else {
      setShowError(true)
    }


  }

  function fctEditTag(tag: Tag) {
    setTagParamEncours(tag)
    setStepParamTag(2)
  }

  function fctDetailTag(tag: Tag) {
    setTagParamEncours(tag)
    setStepParamTag(3)
  }

  function fctAnnuler() {
    setStepParamTag(0)
    setTagParamEncours(null)
  }


  return (
    <PageTemplate elements={NAV_ELEMENTS}>
      <Grid container spacing={mobileMode ? 0 : 10}>
        <Grid item xs={12} sm={12} className={mobileSpacing}>
          {stepParamTag == 0 && (
            <>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12} sm={12}>
                  <Typography variant="h2" gutterBottom >Tableau de bord tags</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={fctCreateTag}>Créer un nouveau tag</Button>
                </Grid>
                <Grid item xs={12} sm={9} className={mobileSpacing}>
                  <TableTags fctEditTag={fctEditTag} fctDetailTag={fctDetailTag} values={listeTag}></TableTags>
                </Grid>
              </Grid>
            </>
          )}
          {/* Création / Edition d'un tag */}
          {(stepParamTag == 1 || stepParamTag == 2) && (
            <>
              <Paper className={paper}>
                <Typography variant="h3" gutterBottom >{stepParamTag == 1 ? "Création d'un nouveau tag" : "Edition du tag : " + tagParamEncours.libelle}</Typography>
                <Grid container>
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Code"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MyTextField fullWidth disabled={stepParamTag == 2} margin="normal" label="" name="code" value={tagParamEncours.code} onChange={handleChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                      errorMsg={(tagParamEncours.code == null || tagParamEncours.code.trim() === "") && showError ? "Veuillez remplir le champ" : (!regExCode.test(tagParamEncours.code) && showError) ? "Format invalid : le code doit être en majuscule sans caractères spéciaux (excepté \"_\") et sans accent" : ""} />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Libellé"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MyTextField fullWidth margin="normal" label="" name="libelle" value={tagParamEncours.libelle} onChange={handleChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                      errorMsg={(tagParamEncours.libelle == null || tagParamEncours.libelle.trim() === "") && showError ? "Veuillez remplir le champ" : ""} />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Statut"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <FormControlLabel control={
                      <Checkbox color="primary" name="statut" checked={tagParamEncours.statut === '*'} onChange={handleChangeCheckBoxActif} />}
                      label={"Actif"}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" justify="center" spacing={10}>
                  <Grid item xs={12} sm={5} >
                    <Button fullWidth size="small" variant="outlined" color="primary" onClick={fctAnnuler}>ANNULER</Button>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Button fullWidth size="small" disabled={!validForm && showError} variant="contained" color="primary" onClick={fctEnregisterTag}>ENREGISTRER</Button>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}

        </Grid>
      </Grid >
      <Grid>
        {/* Détail panéliste*/}
        {stepParamTag == 3 && (
          <DetailTagPage tag={tagParamEncours} fctAnnuler={fctAnnuler} />
        )}
      </Grid>
    </PageTemplate>

  );
}

export default TagsPage;