import { createMuiTheme } from "@material-ui/core";
import colors from "./colors";
import "npm-font-open-sans";
import { pink } from "@material-ui/core/colors";

export const HEADER_HEIGHT = 136;
export const DRAWER_WIDTH = 80;
export const BOTTOMNAV_HEIGHT = 56;

const FONTWEIGHT_LIGHT = 300;
const FONTWEIGHT_REGULAR = 400;
const FONTWEIGHT_MEDIUM = 600;
const FONTWEIGHT_BOLD = 700;

const DISABLED_COLOR = colors.gris20;
const ABLED_COLOR = colors.bleu;
const ACTIVE_COLOR = colors.active;
const SUCCESS_COLOR = colors.vert;
const ERROR_COLOR = colors.rouge;
const SPACING = 2;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: ACTIVE_COLOR,
      contrastText: colors.noir
    },
    secondary: {
      main: ABLED_COLOR,
      dark: colors.noir,
      contrastText: colors.noir
    },
    background: {
      paper: colors.blanc,
      default: colors.gris40
    },
    text: {
      primary: colors.noir,
      secondary: colors.blanc,
      disabled: DISABLED_COLOR
    },
    action: {
      active: ACTIVE_COLOR
    },
    info: {
      main: colors.bleu,
      contrastText: colors.blanc
    },
    success: {
      main: SUCCESS_COLOR,
      contrastText: colors.noir
    },
    error: {
      main: ERROR_COLOR,
      contrastText: colors.noir
    },

  },
  typography: {
    fontFamily: "Open Sans",
    fontWeightLight: FONTWEIGHT_LIGHT,
    fontWeightRegular: FONTWEIGHT_REGULAR,
    fontWeightMedium: FONTWEIGHT_MEDIUM,
    fontWeightBold: FONTWEIGHT_BOLD,
    h1: {
      fontSize: 42,
      fontWeight: FONTWEIGHT_BOLD,
      color: colors.noir
    },
    h2: {
      fontSize: 26,
      fontWeight: FONTWEIGHT_BOLD,
      color: colors.noir
    },
    h3: {
      fontSize: 20,
      fontWeight: FONTWEIGHT_BOLD,
      color: colors.noir
    },
    h4: {
      fontSize: 16,
      fontWeight: FONTWEIGHT_BOLD,
      color: colors.gris
    },
    h5: {
      fontSize: 14,
      fontWeight: FONTWEIGHT_MEDIUM,
      color: colors.gris
    },
    h6: {
      fontSize: 14,
      fontWeight: FONTWEIGHT_REGULAR,
      color: colors.gris
    },
    subtitle1: {
      fontWeight: FONTWEIGHT_BOLD,
      color: colors.noir,
      fontSize: 16
    },
    subtitle2: {
      fontWeight: FONTWEIGHT_LIGHT,
      color: colors.blanc,
      fontSize: 11
    },
    body1: {
      fontWeight: FONTWEIGHT_REGULAR,
      color: colors.noir,
      fontSize: 14
    },
    body2: {
      color: colors.gris,
      fontSize: 13
    },
    button: {
      color: colors.blanc
    }
  },
  overrides: {
    MuiGrid: {
      "spacing-xs-10": {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    MuiFab: {
      extended: {
        fontWeight: "bold",
        padding: "5px 25px"
      }
    },
    MuiLink: {
      root: {
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        "&$disabled": {
          "opacity": 0.35
        }
      },
      text: {
        fontWeight: "bold",
        color: colors.blanc
      },
      outlined: {
        fontWeight: FONTWEIGHT_MEDIUM,
        fontSize: 14
      },
      outlinedPrimary: {
        borderRadius: 21,
        color: colors.noir,
        borderColor: colors.jaune
      },
      outlinedSecondary: {
        color: colors.vert,
        borderColor: colors.vert,
      },
      outlinedSizeSmall: {
        fontWeight: FONTWEIGHT_REGULAR,
        fontSize: 14
      },
      contained: {
        fontSize: 14,
        fontWeight: FONTWEIGHT_MEDIUM,
        color: colors.gris,
        backgroundColor: colors.gris30,
        letterSpacing: 0.3
      },
      containedSizeSmall: {
        fontWeight: FONTWEIGHT_REGULAR,
        fontSize: 14
      },
      containedPrimary: {
        borderRadius: 21
      }
    },
    MuiTextField: {
      root: {
        "&:hover": {
          "& .MuiOutlinedInput-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
            borderColor: ACTIVE_COLOR
          },
          "& .MuiInputLabel-root:not(.Mui-disabled)": {
            color: ACTIVE_COLOR
          }
        },
        "&:focused": {
          "& .MuiOutlinedInput-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
            borderColor: ACTIVE_COLOR,
          },
          "& .MuiInputLabel-root:not(.Mui-disabled)": {
            color: ACTIVE_COLOR
          }
        }
      }
    },
    MuiInputLabel: {
      root: {
        fontWeight: FONTWEIGHT_MEDIUM,
        fontSize: 14,
        marginLeft: 5,
        color: ABLED_COLOR,
        "&.Mui-disabled": {
          color: DISABLED_COLOR
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        "&.Mui-disabled": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: DISABLED_COLOR
          },
          "& .MuiSelect-icon": {
            color: DISABLED_COLOR
          }
        },
        "&.Mui-error": {
          "& .MuiInputAdornment-root": {
            color: ERROR_COLOR
          }
        }
      },
      input: {
        paddingLeft: 26.5, // initial 18.5 + 8
        letterSpacing: 0,
        fontSize: 16,
        fontWeight: FONTWEIGHT_LIGHT
      },
      notchedOutline: {
        borderWidth: 1,
        paddingLeft: 13,
        borderColor: colors.noir
      }
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "initial"
        }
      },
      icon: {
        color: colors.gris
      }
    },
    MuiCheckbox: {
      root: {
        color: colors.gris10
      }
    },
    MuiRadio: {
      root: {
        color: colors.gris10
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: 34
      },
      fontSizeSmall: {
        fontSize: 24
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: SPACING * 5
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 5
      }
    },
    MuiMenuItem: {
      root: {
        color: colors.noir
      }
    },
    MuiTableBody: {
      root: {
        background: colors.blanc
      }
    },
    MuiTableCell: {
      head: {
        color: colors.gris10,
        fontSize: 12,
        paddingTop: 5,
        paddingBottom: 5
      },
      body: {
        color: colors.gris,
        fontSize: 14,
        fontWeight: FONTWEIGHT_MEDIUM
      }
    },
    MuiBackdrop: {
      root: {
        backdrop: {
          zIndex: 2,
          color: colors.blanc,
        },
      }
    }
  },
  spacing: 2,
  shape: {
    borderRadius: 5
  }
})

export default theme;