import 'reactjs-popup/dist/index.css';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, Grid, Hidden, makeStyles, MenuItem, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGlobalStyles } from '../../style/globalStyles';
import { formatDateStr, useMobileMode } from '../../utils/utils';
import { fetchSaveUserFct, fetchSendMail, fetchTiers, fetchUserProfil, fetchUsersAdmin, fetchUserType } from './UsersAdminApi';
import { AdminUser, Tiers, UserCreationProps, UserModification, UserProfil, UserType } from './UsersAdminType';
import { TableUsers } from './MaterialTableUsers';
import { ChangeHandler, NavElement } from '../../utils/types';
import PageTemplate from '../../page/PageTemplate';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fr } from "date-fns/locale";
import MyTextField, { MySelectField } from '../../components/MyTextField';
import InputMask from "react-input-mask";
import { TableTiers } from './MaterialTableTiers';
import { UserCreation } from '../auth/InscriptionType';
import { fetchCreateUserFct } from '../auth/InscriptionApi';
import { ArrowLeft } from '@material-ui/icons';
import { useCurrentUser } from '../auth/authApi';
import { TableTagToSelect } from '../enquete/MaterialTableTagToSelect';
import { Tag } from '../users/MonCompteType';
import { fetchListTag } from '../enquete/PublicationApi';
import { addUserListTags, listTagUser } from '../tags/TagApi';

const NAV_ELEMENTS: NavElement[] = [{ name: "users", path: "/Users" }];

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10
  },
  selectField: {
    paddingLeft: "6px"
  }, blockBouton: {
    padding: "25px"
  }
}))

const TagsPage = () => {
  const { paper, selectField, blockBouton } = useStyles();
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const [stepUsers, setStepUsers] = useState<number>(0);
  const globalClasses = useGlobalStyles();
  const { mobileSpacing } = globalClasses;
  const mobileMode = useMobileMode();
  const [listeUsers, setListeUsers] = useState<AdminUser[]>([]);
  const [userModification, setUserModification] = useState<AdminUser>(null);
  const [listeUserType, setListeUserType] = useState<UserType[]>([]);
  const [listeUserProfil, setListeUserProfil] = useState<UserProfil[]>([]);
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);

  const [infoactuOui, setInfoactuOui] = useState<boolean>(false);
  const [infoactuNon, setInfoactuNon] = useState<boolean>(false);

  const onToggleInfoactuOui: ChangeHandler = () => { setInfoactuOui(!infoactuOui); setInfoactuNon(false); }
  const onToggleInfoactuNon: ChangeHandler = () => { setInfoactuNon(!infoactuNon); setInfoactuOui(false); }


  const [checkSMS, setCheckSMS] = useState<boolean>(false);
  const [checkMail, setCheckMail] = useState<boolean>(false);

  const [showError, setShowError] = useState<boolean>(false);

  const [searchUser, setSearchUser] = useState<string>(null);
  const [listeTiers, setListeTiers] = useState<Tiers[]>([]);
  const [userCreation, setUserCreation] = useState<Tiers>(null);

  {/* TAGS */ }
  const [tagSelected, setTagSelected] = useState<Tag[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);

  const onToggleCheckSMS: ChangeHandler = () => {
    //avant de passer la coche à false on vérifie qu'il y ai un autre moyen de contact d'activé
    if (!checkSMS === false) {
      if (checkMail) {
        setCheckSMS(!checkSMS);
      }
    } else {
      setCheckSMS(!checkSMS);
    }
  }
  const onToggleCheckMail: ChangeHandler = () => {
    //avant de passer la coche à false on vérifie qu'il y ai un autre moyen de contact d'activé
    if (!checkMail === false) {
      if (checkSMS) {
        setCheckMail(!checkMail);
      }
    } else {
      setCheckMail(!checkMail);
    }
  }

  const [alerteSendMail, setAlerteSendMail] = useState<boolean>(false);
  const [userToSendMail, setUserToSendMail] = useState<AdminUser>(null);

  const [genre, setGenre] = useState<string>(null);

  const regExEmail = new RegExp('^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$');

  const validFormEdit: boolean = userModification !== null && userModification.nom != null && userModification.nom.trim() !== "" && userModification.type != null
    && userModification.mail != null && userModification.mail.trim() !== ""
    && genre != null && genre != undefined && genre.trim() !== "" && !(userModification.profilId === null && userModification.type === 13);

  const validFormEditAD: boolean = userModification !== null && (userModification.type == 12 && userModification.raisonSociale != null && userModification.raisonSociale.trim() !== "" && userModification.telephone != null && userModification.telephone.trim() !== ""
    && userModification.dateDeNaissance != null && userModification.dateDeNaissance.trim() !== ""
    && userModification.contactPrivilegie != null && userModification.contactPrivilegie.trim() !== "" && (infoactuNon || infoactuOui));

  const validFormCrea: boolean = userCreation !== null && userCreation.nom !== null && userCreation.nom.trim() !== ""
    && userCreation.prenom !== null && userCreation.prenom.trim() !== "" && userCreation.mail !== null
    && userCreation.mail.trim() !== "" && userCreation.codeTiers !== null && userCreation.codeTiers.trim() !== "" && regExEmail.test(userCreation.mail) && userCreation.profilId !== null && genre != null && genre != undefined && genre.trim() !== "";


  function initUsers(data: AdminUser[]) {
    if (data) {
      setListeUsers(data)
    }
  }

  function initUserType(data: UserType[]) {
    if (data) {
      setListeUserType(data);
    }
  }

  function initUserProfil(data: UserProfil[]) {
    if (data) {
      setListeUserProfil(data);
    }
  }

  function initTiers(data: Tiers[]) {
    if (data) {
      setListeTiers(data);
    }
  }

  // GESTION SELECTION TAG DANS TABLEAU
  function selectTag(tagParam: Tag) {
    const tmpTagSelected: Tag[] = [];
    let find = false;
    for (const tag of tagSelected) {
      if (tagParam.code === tag.code) {
        find = true;
      } else {
        tmpTagSelected.push(tag);
      }
    }
    if (!find) {
      tmpTagSelected.push(tagParam);
    }
    setTagSelected(tmpTagSelected);
  }

  useEffect(() => {
    let contact = "";
    if (checkSMS && !checkMail) {
      contact = "SMS";
    } else if (checkMail && !checkSMS) {
      contact = "EMAIL";
    } else if (checkMail && checkSMS) {
      contact = "SMS#EMAIL";
    }

    setUserModification((prevState) => ({
      ...prevState, ["contactPrivilegie"]: contact
    }))

  }, [checkSMS, checkMail]);

  useEffect(() => {
    const isAdmin = user.isAdmin;
    setUserIsAdmin(isAdmin)
  }, []);

  useEffect(() => {
    dispatch(fetchUsersAdmin(initUsers))
  }, []);

  useEffect(() => {
    const search = setTimeout(() => {
      if (searchUser !== null && searchUser !== undefined && searchUser.trim() !== "") {
        dispatch(fetchTiers(searchUser, initTiers));
      } else {
        setListeTiers([]);
      }
    }, 700)

    return () => clearTimeout(search)

  }, [searchUser]);



  const handleChange: ChangeHandler = e => {
    const target = e.target;

    setUserModification((prevState) => ({
      ...prevState, [target.name]: target.value
    }))
  }

  const handleChangeSearchUser: ChangeHandler = e => {
    const target = e.target;
    setSearchUser(target.value);
  }

  const handleChangeCreation: ChangeHandler = e => {
    const target = e.target;

    setUserCreation((prevState) => ({
      ...prevState, [target.name]: target.value
    }))
  }
  const onToggleCheckHomme: ChangeHandler = (e) => {
    const target = e.target
    if (target.checked) {
      setGenre('H');
    }
  }

  const onToggleCheckFemme: ChangeHandler = (e) => {
    const target = e.target
    if (target.checked) {
      setGenre('F');
    }
  }

  const handleChangeCheckBoxActif: ChangeHandler = e => {
    const target = e.target
    let value = 'N'

    if (target.checked) {
      value = 'O'
    }

    setUserModification((prevState) => ({
      ...prevState, [target.name]: value
    }))
  }

  function selectTiers(data: Tiers) {
    data.profilId = userCreation.profilId;
    setUserCreation(data);
  }

  function fctCreateUser() {
    const nouveauUser: Tiers = {
      codeTiers: "",
      nom: "",
      prenom: "",
      mail: "",
      profilId: 1,
      telephone: "",
      login: ""
    }

    setUserCreation(nouveauUser);
    dispatch(fetchUserProfil(initUserProfil));
    setStepUsers(1);
  }

  function fctSendMail(user: AdminUser) {
    setAlerteSendMail(true)
    setUserToSendMail(user)
  }

  function fctRefreshPage() {
    setStepUsers(0);
    setUserCreation(null);
    setUserModification(null);
    setSearchUser(null);
    setGenre(null);
    setTagSelected([])
    setTags([])
    dispatch(fetchUsersAdmin(initUsers))
  }

  function fctSuccess(data: boolean) {
    if (data) {
      fctRefreshPage();
    }
  }

  function fctSuccessCreat(data: string) {
    if (data) {
      fctRefreshPage();
    }
  }

  function fctConfirmSendMail() {
    setAlerteSendMail(false)
    dispatch(fetchSendMail(userToSendMail.idUserconvention, fctSuccess));
  }

  function initTag(data: Tag[]) {
    if (data) {
      setTags(data);
    } else {
      setTags([]);
    }
  }

  function initTagSelected(data: Tag[]) {
    if (data) {
      setTagSelected(data);
    } else {
      setTagSelected([]);
    }
  }

  function fctEditUser(user: AdminUser) {
    if (user.contactPrivilegie === 'EMAIL') {
      setCheckMail(true);
      setCheckSMS(false);
    } else if (user.contactPrivilegie === 'SMS') {
      setCheckSMS(true);
      setCheckMail(false);
    } else if (user.contactPrivilegie.includes('SMS') && user.contactPrivilegie.includes('EMAIL')) {
      setCheckSMS(true);
      setCheckMail(true);
    }
    if (user.infoActu) {
      setInfoactuNon(false);
      setInfoactuOui(true);
    } else {
      setInfoactuNon(true);
      setInfoactuOui(false);
    }
    if (user.type === 13 && user.profilId === null) {
      user.profilId = 0;
    }
    setGenre(user.genre);
    dispatch(fetchUserType(initUserType));
    dispatch(fetchUserProfil(initUserProfil));
    setUserModification(user);

    if (user.type === 12) {
      dispatch(fetchListTag(initTag));
      dispatch(listTagUser(user.codeTiers, initTagSelected));
    }
    setStepUsers(2);
  }

  function fctAnnuler() {
    fctRefreshPage()
  }

  function addProps(code: string, valeur: string) {
    const prop: UserCreationProps = {
      code: code,
      valeur: valeur
    }
    return prop;
  }

  function fctEnregistrementCreation() {

    if (validFormCrea) {
      const userProps: UserCreationProps[] = [];
      userProps.push(addProps("GENRE", genre))
      const userCre: UserCreation = {
        nom: userCreation.nom,
        prenom: userCreation.prenom,
        typeTiers: "SA",
        profilId: userCreation.profilId,
        codeTiers: userCreation.codeTiers,
        email: userCreation.mail,
        gsm: userCreation.telephone,
        contactPrivilegie: "EMAIL",
        raisonSociale: null,
        dateNaissance: null,
        userProps: userProps,
        loginExtranet: userCreation.login
      }

      dispatch(fetchCreateUserFct(userCre, fctSuccessCreat));
    } else {
      setShowError(true)
    }
  }

  function fctEnregistrementEdit() {
    const isValid: boolean = userModification.type == 12 ? validFormEdit && validFormEditAD : validFormEdit;
    if (isValid) {
      const userProps: UserCreationProps[] = [];
      userProps.push(addProps("GENRE", genre))
      userProps.push(addProps("INFO_ACTU", infoactuOui ? "O" : "N"));
      const userModif: UserModification = {
        nom: userModification.nom,
        typeTiers: userModification.typeTiers,
        profilId: userModification.profilId,
        codeTiers: userModification.codeTiers,
        email: userModification.mail,
        gsm: userModification.telephone,
        contactPrivilegie: userModification.contactPrivilegie,
        raisonSociale: userModification.raisonSociale,
        dateNaissance: formatDateStr(userModification.dateDeNaissance),
        userProps: userProps,
        login: userModification.login,
        statut: userModification.statut,
        idUser: userModification.idUser
      }

      const fctSuccessTag = (data: boolean) => {
        if (data) {
          dispatch(fetchSaveUserFct(userModif, fctSuccess))
        }
      }

      const listCodesTags: string[] = tagSelected.map(tag => tag.code)
      dispatch(addUserListTags(listCodesTags, userModification.codeTiers, fctSuccessTag))
    } else {
      setShowError(true)
    }
  }

  return (
    <PageTemplate elements={NAV_ELEMENTS}>
      <Grid container spacing={mobileMode ? 0 : 10}>
        <Grid item xs={12} sm={10} className={mobileSpacing}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2" gutterBottom >Tableau de bord utilisateurs</Typography>
          </Grid>

          {stepUsers == 0 && (
            <>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12} sm={12} className={mobileSpacing} style={{marginBottom: 10}}>
                  <Paper style={{padding:5}}>
                    <Grid container alignItems="center" justify="center">
                      <Grid item xs={12} sm={12} className={mobileSpacing}>
                        <Typography variant="h4" gutterBottom >{"Nombre total : " +  listeUsers.length}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} className={mobileSpacing}>
                        <Typography variant="h5" gutterBottom >{`Nombre panélistes : ${listeUsers.filter((el) => el.type === 12).length} dont ${listeUsers.filter((el) => el.type === 12 && el.statut !== "O").length} inactif(s)`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} className={mobileSpacing}>
                        <Typography variant="h5" gutterBottom >{`Nombre utilisateurs : ${listeUsers.filter((el) => el.type === 13).length} dont ${listeUsers.filter((el) => el.type === 13 && el.statut !== "O").length} inactif(s)`}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={8} className={mobileSpacing}>
                  <Button fullWidth variant="contained" color="primary" disabled={!userIsAdmin} onClick={fctCreateUser}>Créer un nouveau compte</Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} className={mobileSpacing}>
                <TableUsers fctEditUser={fctEditUser} fctSendMail={fctSendMail} values={listeUsers} userIsAdmin={userIsAdmin}></TableUsers>
              </Grid>
            </>
          )}
          {stepUsers == 1 && (
            <>
              <Button variant="text" color="primary" disableElevation startIcon={<ArrowLeft htmlColor="#A0A0A0" fontSize="large" />} size="large" onClick={fctAnnuler}>
                {"Retour au tableau de bord"}
              </Button>
              <Paper className={paper}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography style={{ margin: "5px" }} variant="h3">Création utilisateur (accès salarié)</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ margin: "5px" }} variant="h5">{"Recherche d'un utilisateurs"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <MyTextField fullWidth margin="normal" label="" placeholder="Saisissez un nom ou un prénom" name="search" value={searchUser} onChange={handleChangeSearchUser} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }} />
                  </Grid>
                  {(listeTiers != null && listeTiers.length !== 0) && (
                    <Grid item xs={12} sm={12} className={mobileSpacing}>
                      <TableTiers values={listeTiers} onRowClick={selectTiers} selectedData={userCreation}></TableTiers>
                    </Grid>
                  )}
                  {(listeTiers == null || listeTiers.length) === 0 && (
                    <Grid item xs={12} sm={12} className={mobileSpacing}>
                      <Typography variant="h5" color="error" gutterBottom align="center" >{"Aucun tiers trouvé"}</Typography>
                    </Grid>
                  )}
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Nom"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MyTextField fullWidth margin="normal" label="" name="nom" value={userCreation.nom} onChange={handleChangeCreation} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                      errorMsg={(userCreation.nom == null || userCreation.nom.trim() == "" && showError) ? "Merci de saisir un nom" : ""} />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Prénom"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MyTextField fullWidth margin="normal" label="" name="prenom" value={userCreation.prenom} onChange={handleChangeCreation} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                      errorMsg={(userCreation.prenom == null || userCreation.prenom.trim() == "" && showError) ? "Merci de saisir un prenom" : ""} />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Profil"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MySelectField
                      value={userCreation.profilId}
                      style={{ margin: "5px" }}
                      name="profilId"
                      onChange={handleChangeCreation}
                      size="small"
                      InputProps={{
                        classes: {
                          input: selectField
                        }
                      }}
                    >
                      {listeUserProfil?.map((userProfil: UserProfil) => (
                        <MenuItem key={userProfil.code} value={userProfil.code}>{userProfil.libelle}</MenuItem>
                      ))}
                    </MySelectField>
                  </Grid>
                  {showError && (userCreation.profilId == null || userCreation.profilId == undefined) &&
                    (
                      <>
                        <Grid item xs={3} sm={3}></Grid>
                        <Grid item xs={9} sm={9}>
                          <Typography variant="subtitle2" color="error">{"Vous devez sélectionner un profil"}</Typography>
                        </Grid>
                      </>
                    )}
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Email"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MyTextField fullWidth margin="normal" label="" name="mail" value={userCreation.mail} onChange={handleChangeCreation} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                      errorMsg={((userCreation.mail == null || userCreation.mail.trim() == "" || !regExEmail.test(userCreation.mail)) && showError) ? "Merci de saisir une adresse mail valide" : ""} />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Genre : "}</Typography>
                  </Grid>
                  <Hidden mdUp><Grid item xs={3}></Grid></Hidden>
                  <Grid item xs={4} sm={2}>
                    <FormControlLabel control={<Checkbox
                      color="primary"
                      checked={genre === 'H'}
                      onChange={onToggleCheckHomme}
                    />}
                      label="Homme" />
                  </Grid>
                  <Grid item xs={5} sm={7}>
                    <FormControlLabel control={<Checkbox
                      color="primary"
                      checked={genre === 'F'}
                      onChange={onToggleCheckFemme}
                    />}
                      label="Femme"
                    />
                  </Grid>
                  {showError && (genre == null || genre == undefined || genre.trim() == "") &&
                    (
                      <>
                        <Grid item xs={3} sm={3}></Grid>
                        <Grid item xs={9} sm={9}>
                          <Typography variant="subtitle2" color="error">{"Vous devez sélectionner une des options"}</Typography>
                        </Grid>
                      </>
                    )}
                  {showError && (userCreation.codeTiers == null || userCreation.codeTiers.trim() === "") && (
                    <>
                      <Grid item xs={3} sm={3}></Grid>
                      <Grid item xs={9} sm={9}>
                        <Typography variant="h4" color="error">{"Vous devez sélectionner un tiers"}</Typography>
                      </Grid>
                    </>
                  )}
                  <Grid container alignItems="center" justify="center" className={blockBouton} spacing={10}>
                    <Grid item xs={12} sm={5} >
                      <Button fullWidth size="small" variant="outlined" color="primary" onClick={fctAnnuler}>ANNULER</Button>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Button fullWidth size="small" variant="contained" color="primary" onClick={fctEnregistrementCreation}>ENREGISTRER</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {stepUsers == 2 && (
            <>
              <Button variant="text" color="primary" disableElevation startIcon={<ArrowLeft htmlColor="#A0A0A0" fontSize="large" />} size="large" onClick={fctAnnuler}>
                {"Retour au tableau de bord"}
              </Button>
              <Paper className={paper}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography style={{ margin: "5px" }} gutterBottom align="center" variant="h3">Modification utilisateur</Typography>
                  </Grid>
                  {userModification.statut === 'W' && (
                    <Grid item xs={12}>
                      <Typography style={{ margin: "5px" }} color="error" variant="h5">{"Cet utilisateur n'est pas encore activé"}</Typography>
                    </Grid>
                  )}
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Nom"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MyTextField fullWidth margin="normal" label="" name="nom" disabled={!userIsAdmin} value={userModification.nom} onChange={handleChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                      errorMsg={(userModification.nom == null || userModification.nom.trim() == "" && showError) ? "Merci de saisir un nom" : ""} />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Type"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MySelectField
                      value={userModification.type}
                      style={{ margin: "5px" }}
                      name="type"
                      onChange={handleChange}
                      size="small"
                      disabled={true}
                      InputProps={{
                        classes: {
                          input: selectField
                        }
                      }}
                    >
                      {listeUserType?.map((userType: UserType) => (
                        <MenuItem key={userType.code} value={userType.code}>{userType.libelle}</MenuItem>
                      ))}
                    </MySelectField>
                  </Grid>
                  {userModification.type === 13 && (
                    <>
                      <Grid item xs={3} sm={3}>
                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Profil"}</Typography>
                      </Grid>
                      <Grid item xs={9} sm={9}>
                        <MySelectField
                          value={userModification.profilId}
                          disabled={!userIsAdmin}
                          style={{ margin: "5px" }}
                          name="profilId"
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            classes: {
                              input: selectField
                            }
                          }}
                        >
                          {listeUserProfil?.map((userProfil: UserProfil) => (
                            <MenuItem key={userProfil.code} value={userProfil.code}>{userProfil.libelle}</MenuItem>
                          ))}
                        </MySelectField>
                      </Grid>
                      {showError && (userModification.profilId == null || userModification.profilId == undefined && userModification.type === 13) &&
                        (
                          <>
                            <Grid item xs={3} sm={3}></Grid>
                            <Grid item xs={9} sm={9}>
                              <Typography variant="subtitle2" color="error">{"Vous devez sélectionner un profil"}</Typography>
                            </Grid>
                          </>
                        )}
                    </>
                  )}
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Email"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MyTextField fullWidth margin="normal" label="" name="mail" disabled={!userIsAdmin} value={userModification.mail} onChange={handleChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                      errorMsg={((userModification.mail == null || userModification.mail.trim() == "" || !regExEmail.test(userModification.mail)) && showError) ? "Merci de saisir une adresse mail valide" : ""} />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Login"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MyTextField fullWidth margin="normal" label="" name="login" disabled={true} value={userModification.login} onChange={handleChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }} />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Genre : "}</Typography>
                  </Grid>
                  <Hidden mdUp><Grid item xs={3}></Grid></Hidden>
                  <Grid item xs={4} sm={2}>
                    <FormControlLabel control={<Checkbox
                      color="primary"
                      disabled={!userIsAdmin}
                      checked={genre === 'H'}
                      onChange={onToggleCheckHomme}
                    />}
                      label="Homme" />
                  </Grid>
                  <Grid item xs={5} sm={7}>
                    <FormControlLabel control={<Checkbox
                      color="primary"
                      disabled={!userIsAdmin}
                      checked={genre === 'F'}
                      onChange={onToggleCheckFemme}
                    />}
                      label="Femme"
                    />
                  </Grid>
                  {showError && (genre == null || genre == undefined && genre.trim() == "") &&
                    (
                      <>
                        <Grid item xs={3} sm={3}></Grid>
                        <Grid item xs={9} sm={9}>
                          <Typography variant="subtitle2" color="error">{"Vous devez sélectionner une des options"}</Typography>
                        </Grid>
                      </>
                    )}
                  {userModification.type === 12 && (
                    <>
                      <Grid item xs={3} sm={3}>
                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Raison Sociale"}</Typography>
                      </Grid>
                      <Grid item xs={9} sm={9}>
                        <MyTextField fullWidth margin="normal" label="" name="raisonSociale" disabled={!userIsAdmin} value={userModification.raisonSociale} onChange={handleChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                          errorMsg={(userModification.raisonSociale == null || userModification.raisonSociale.trim() == "" && showError) ? "Merci de saisir une raison sociale" : ""} />
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Numero Téléphone"}</Typography>
                      </Grid>
                      <Grid item xs={9} sm={9}>
                        <InputMask
                          mask='99 99 99 99 99'
                          name="telephone"
                          value={userModification.telephone}
                          disabled={!userIsAdmin}
                          onChange={handleChange}
                          style={{ margin: "5px" }}
                        >
                          <MyTextField fullWidth margin="normal" label="" inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                            errorMsg={(userModification.telephone == null || userModification.telephone.trim() == "" && showError) ? "Merci de saisir un numero de téléphone valide" : ""} />
                        </InputMask>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Date de naissance"}</Typography>
                      </Grid>
                      <Grid item xs={9} sm={9}>
                        <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                          <DatePicker
                            autoOk
                            format="dd/MM/yyyy"
                            name="dateDeNaissance"
                            value={userModification.dateDeNaissance}
                            onChange={(date) => {
                              const dateString = new Date(date).toLocaleDateString('en-US')
                              setUserModification((prevState) => ({
                                ...prevState, ["dateDeNaissance"]: dateString
                              }))
                            }}
                            disabled={!userIsAdmin}
                            disableFuture
                            animateYearScrolling
                            variant="inline"
                            inputVariant="outlined"
                            inputProps={{ style: { padding: "6px" } }}
                            style={{ marginLeft: "5px", marginBottom: "5px" }}
                            invalidDateMessage={"Format invalide, le format doit être JJ/MM/AAAA"}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Je souhaite être contacté de préférence par "}</Typography>
                      </Grid>
                      <Hidden mdUp><Grid item xs={3}></Grid></Hidden>
                      <Grid item xs={4} sm={2}>
                        <FormControlLabel control={<Checkbox
                          color="primary"
                          disabled={!userIsAdmin}
                          checked={checkSMS}
                          onChange={onToggleCheckSMS}
                        />}
                          label="SMS" />
                      </Grid>
                      <Grid item xs={5} sm={7}>
                        <FormControlLabel control={<Checkbox
                          color="primary"
                          disabled={!userIsAdmin}
                          checked={checkMail}
                          onChange={onToggleCheckMail}
                        />}
                          label="Email" />
                      </Grid>
                      {showError && !(checkSMS || checkMail) &&
                        (
                          <>
                            <Grid item xs={3} sm={3}></Grid>
                            <Grid item xs={9} sm={9}>
                              <Typography variant="subtitle2" color="error">{"Vous devez choisir un mode de contact"}</Typography>
                            </Grid>
                          </>
                        )}
                      {/* droit contacte actu */}
                      <Grid item xs={12} sm={3}>
                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Être informé dès qu'une nouvelle actualité est publiée "}</Typography>
                      </Grid>
                      <Hidden mdUp><Grid item xs={3}></Grid></Hidden>
                      <Grid item xs={4} sm={2}>
                        <FormControlLabel control={<Checkbox
                          color="primary"
                          disabled={!userIsAdmin}
                          checked={infoactuOui}
                          onChange={onToggleInfoactuOui}
                        />}
                          label="OUI" />
                      </Grid>
                      <Grid item xs={5} sm={7}>
                        <FormControlLabel control={<Checkbox
                          color="primary"
                          disabled={!userIsAdmin}
                          checked={infoactuNon}
                          onChange={onToggleInfoactuNon}
                        />}
                          label="NON" />
                      </Grid>
                      {showError && !(infoactuNon || infoactuOui) &&
                        (
                          <>
                            <Grid item xs={3} sm={3}></Grid>
                            <Grid item xs={9} sm={9}>
                              <Typography variant="subtitle2" color="error">{"Merci de répondre à la question"}</Typography>
                            </Grid>
                          </>
                        )}
                    </>
                  )}
                  <Grid item xs={12} sm={12}></Grid>
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Statut"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <FormControlLabel control={
                      <Checkbox color="primary" disabled={userModification.statut === 'W' || !userIsAdmin} name="statut" checked={userModification.statut === 'O'} onChange={handleChangeCheckBoxActif} />}
                      label={"Actif"}
                    />
                  </Grid>
                  {/* Selection des tags */}
                  {userModification.type === 12 && (
                    <>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Sélection des tags"}</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}></Grid>
                      <Grid item xs={9} sm={9} >
                        <Grid container spacing={10} >
                          <TableTagToSelect tagSelected={tagSelected} onSelect={selectTag} values={tags} userIsAdmin={userIsAdmin}></TableTagToSelect>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid container alignItems="center" justify="center" className={blockBouton} spacing={10}>
                    <Grid item xs={12} sm={5} >
                      <Button fullWidth size="small" variant="outlined" color="primary" onClick={fctAnnuler}>ANNULER</Button>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Button fullWidth size="small" disabled={!userIsAdmin} variant="contained" color="primary" onClick={fctEnregistrementEdit}>ENREGISTRER</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </Grid>
      </Grid >
      {/*DIALOG*/}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={alerteSendMail}
      >
        <DialogContent>
          <DialogContentText color="initial">Attention vous allez renvoyer le mail de validation
            Confirmez-vous ce choix ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button key={"NON"} onClick={() => { setAlerteSendMail(false); setUserToSendMail(null) }} color="secondary">NON</Button>
          <Button key={"OUI"} onClick={fctConfirmSendMail} color="secondary">OUI</Button>
        </DialogActions>
      </Dialog>
    </PageTemplate>
  );
}

export default TagsPage;