import { Container, makeStyles, createStyles } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles(theme => createStyles({
  root: {
    padding: 0,
    [theme.breakpoints.between("sm", 1280+2*65)]: {
      paddingLeft: 65
    }
  }
}));

export interface MyContainerProps {
  children?: React.ReactNode;
}

export const SizeContainer: React.FC = (props: MyContainerProps) => {
  const { root } = useStyles();

  return (
    <Container className={root}>
      {props.children}
    </Container>
  );
}
export default SizeContainer