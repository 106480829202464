import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import type {Action} from "@reduxjs/toolkit"
import createRootReducer, { RootState } from './rootReducer'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import thunk , { ThunkAction }from 'redux-thunk'
import cleanMiddleware from './cleanMiddleware'
import storageSession from 'redux-persist/lib/storage/session'
import { persistStore, persistReducer } from 'redux-persist'

export const history = createBrowserHistory();

const middleware = [...getDefaultMiddleware(), thunk, routerMiddleware(history), cleanMiddleware]

const persistConfig = {
  key: 'root',
  storage: storageSession
}


const persistedReducer = persistReducer(persistConfig, createRootReducer(history))


const store = configureStore({
  reducer: persistedReducer,
  middleware
});


export const persistor = persistStore(store);

export default store;

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>