
import { AppThunk } from "../../app/store";
import { simpleLoaderGet, simpleLoaderPost } from "../../services/restUtils";
import { ConnexionDetail, ConnexionsKpi, ConnexionsKpiObj, PublicationDate, PublicationKpi, PublicationSelect } from "./AccueilType";

export const fetchKpiPublication = (idPublication: number,funcSuccess: (data: PublicationKpi) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "enquetes", "getkpipublication", idPublication+"",funcSuccess);
}

export const fetchListActus = (funcSuccess: (data: PublicationSelect[]) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "enquetes", "listeactustdb", "", funcSuccess);
}

export const fetchListEnquetes = (funcSuccess: (data: PublicationSelect[]) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "enquetes", "listeenquetetdb", "", funcSuccess);
}

export const fetchLastPublication = (typePublication: string,funcSuccess: (data: PublicationKpi) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "enquetes", "getlastpublication", typePublication+"", funcSuccess);
}

export const fetchKpiConnexions = (connexionsObj: ConnexionsKpiObj,funcSuccess: (data: ConnexionsKpi[]) => void): AppThunk => async dispatch => {
  simpleLoaderPost(dispatch, "users", "getconnexionskpi", "",connexionsObj, funcSuccess);
}

export const fetchPublicationDate = (connexionsObj: ConnexionsKpiObj,funcSuccess: (data: PublicationDate[]) => void): AppThunk => async dispatch => {
  simpleLoaderPost(dispatch, "enquetes", "getpublicationdate", "",connexionsObj, funcSuccess);
}

export const fetchPublicationLibelle = (type: string,funcSuccess: (data: string) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "enquetes", "getlibpublication", type+"", funcSuccess);
}

export const addFcmToken = (idUserConvention: number,token: string,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
  const obj = {
    idUserConvention: idUserConvention,
    token: token,
  }
  simpleLoaderPost(dispatch, "users", "addfcmtoken", "",obj, funcSuccess);
}

export const fetchKpiConnexionsDetails = (connexionsObj: ConnexionsKpiObj,funcSuccess: (data: ConnexionDetail[]) => void): AppThunk => async dispatch => {
  simpleLoaderPost(dispatch, "users", "getconnexionsdetailskpi", "",connexionsObj, funcSuccess);
}