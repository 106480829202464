import authReducer, { AuthState } from '../features/auth/authSlice';
import errorReducer, { ErrorState } from '../features/error/errorSlice';
import { connectRouter } from 'connected-react-router';
import type {RouterState} from 'connected-react-router';
import { combineReducers } from 'redux';
import loaderReducer, { LoaderState } from '../features/loader/loaderSlice';
import menuReducer, { MenuState } from '../features/menu/menuSlice';
import enqueteReducer, { EnqueteState } from '../features/enquete/EnqueteSlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  error: errorReducer,
  loader: loaderReducer,
  menu: menuReducer,
  enquete: enqueteReducer
})

export interface RootState {
  router: RouterState;
  auth: AuthState;
  error: ErrorState;
  loader: LoaderState;
  menu: MenuState;
  enquete: EnqueteState;
}

export default createRootReducer