import React from 'react';
import { Paper, createStyles, makeStyles,Grid,Hidden,Button } from "@material-ui/core";
import clsx from 'clsx';
import { useGlobalStyles } from '../../style/globalStyles';
import colors from "../../style/colors";
import { useMobileMode } from '../../utils/utils';
import PAS_ACTU_GENERIQUE from "../../img/illustrations/Pas actu générique.png";
import PAS_ACTU_MOISSON from "../../img/illustrations/Pas actu moisson.png";
import PAS_ENQUETE_MOISSON from "../../img/illustrations/Pas enquête moisson.png";
import PAS_ENQUETE_GENERIQUE from "../../img/illustrations/Pas enquête générique.png";
import { PATH } from "../../utils/constants";
import { push } from "connected-react-router";
import { useDispatch } from 'react-redux';

interface StyleProps {
    small?: boolean;
  }

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      position: "relative",
      minWidth: 350,
      minHeight: 115,
      backgroundColor: colors.blanc,
      padding: theme.spacing(5)
    },
    title: {
      padding: theme.spacing(5),
      fontSize: 20,
      color:colors.bleu
    },
    slide: {
      padding: theme.spacing(5)
    },
    mobileStepperButton: {
      width: 32
    }
  }));

  export interface BlocEnqueteAccueilAucuneProps{
    origine: string;
    type: string;
}
    
const BlocEnqueteAccueilAucune = (props: BlocEnqueteAccueilAucuneProps) => {
    const {type,origine} = props;
    const classes = useStyles();
    const { paper} = classes;
    const globalClasses = useGlobalStyles();
    const { marginBottom } = globalClasses;
    const mobileMode = useMobileMode();
    const dispatch = useDispatch();

    const pasActu = ((new Date().getMonth()+1)==7 ||(new Date().getMonth()+1)==8)?PAS_ACTU_MOISSON:PAS_ACTU_GENERIQUE;
    const pasEnquete= ((new Date().getMonth()+1)==7 ||(new Date().getMonth()+1)==8)?PAS_ENQUETE_MOISSON:PAS_ENQUETE_GENERIQUE;

    function openFiche(){
      let menu: string=null;
      if(type==="ENQUETE"){
        menu ="MesEnquetes";
      }
      if(type==="ACTUALITE"){
        menu ="LesActus";
      }
      if(menu!==null){
        dispatch(push(PATH + "/"+menu))
      }
    }

    return (
      <Paper className={clsx(paper, marginBottom)} elevation={0} >
          <Grid container direction="row" spacing={1} justify="center" alignItems="center">
            <img height={mobileMode?"100px":"200px"} src={type==="ENQUETE"?pasEnquete:pasActu}/>
          </Grid>
          {origine==="ACCUEIL" && (
            <>
              <Grid container>
                <Hidden xsDown><Grid item xs={4}></Grid></Hidden>
                <Grid item xs={12} sm={4}>
                  <Button fullWidth variant="contained" color="primary" onClick={openFiche} >{type==="ENQUETE"?"Toutes mes enquêtes":type==="ACTUALITE"?"Tous les actualités":""}</Button>
                </Grid>
                <Hidden xsDown><Grid item xs={4}></Grid></Hidden>
              </Grid>
            </>
          )}
      </Paper>
      )
}

export default BlocEnqueteAccueilAucune;