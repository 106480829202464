import 'reactjs-popup/dist/index.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGlobalStyles } from '../../style/globalStyles';
import { isEmptyVal, useMobileMode } from '../../utils/utils';
import { ChangeHandler } from '../../utils/types';
import { Tag, UserTagAutoDto } from './TagType';
import { addListCodeTiersWithFile, addUserTagAuto, codeTierExiste, disableUserTagAuto, fetchTagUserAuto, mergeListCodeTiersWithFile } from './TagApi';
import { TableUserTags } from './MaterialTableUserTags';
import MyTextField from '../../components/MyTextField';
import { ArrowLeft } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10
  },
  selectField: {
    paddingLeft: "6px"
  },
  divider: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '5px',
  }
}))

export interface DetailTagPageProps {
  tag: Tag;
  fctAnnuler: () => void;
}

const DetailTagPage = (props: DetailTagPageProps) => {
  const { paper, divider } = useStyles();

  const { tag, fctAnnuler } = props;

  const dispatch = useDispatch();
  const globalClasses = useGlobalStyles();
  const { mobileSpacing } = globalClasses;
  const mobileMode = useMobileMode()
  const [listeUser, setListeUser] = useState<UserTagAutoDto[]>([]);
  const [addCodeTiers, setAddCodeTiers] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFilePicked, setIsFilePicked] = useState<boolean>(false);
  const [errorCodeTiers, setErrorCodeTiers] = useState<boolean>(false)
  const [codeRejetes, setCodeRejetes] = useState<string[]>([]);
  const [alerteEcrase, setAlerteEcrase] = useState<boolean>(false);
  const [alerteDelete, setAlerteDelete] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<UserTagAutoDto>();

  const regExNumber = new RegExp('^[0-9]+$');

  const handleChangeCodeTiers: ChangeHandler = e => {
    const target = e.target

    setAddCodeTiers(target.value)
    setErrorCodeTiers(false);

  }

  const setFile: ChangeHandler = e => {
    setSelectedFile(e.target.files[0]);
    setIsFilePicked(true);
  }

  function initUser(data: UserTagAutoDto[]) {
    if (data) {
      setListeUser(data)
    }
  }

  function clearFile() {
    setSelectedFile(null)
    setIsFilePicked(false);
  }

  function fctRefresh() {
    dispatch(fetchTagUserAuto(tag.code, initUser))
  }

  function fctRemoveUser(user: UserTagAutoDto) {
    setAlerteDelete(true)
    setUserToDelete(user)
  }

  function fctCancelDelete(){
    setAlerteDelete(false)
    setUserToDelete(null)
  }

  function fctDeleteUser(){
    setAlerteDelete(false)
    function success(data: boolean) {
      if (data) {
        fctRefresh()
      }
    }
    dispatch(disableUserTagAuto(tag.code, userToDelete.codeTiers, success))
  }

  function fctaddUser(codeTiers: string) {
    function success(data: boolean) {
      if (data) {
        setAddCodeTiers("")
        fctRefresh()
      }
    }
    dispatch(addUserTagAuto(tag.code, codeTiers, success))
  }

  function fctAddCodeTier() {

    function add() {
      fctaddUser(addCodeTiers)
    }

    function existe(data: boolean) {
      if (data) {
        add()
      } else {
        setErrorCodeTiers(true)
      }
    }

    dispatch(codeTierExiste(addCodeTiers, existe))
  }

  // CHARGER LE FICHIER 
  function uploadedFile(mode: string) {
    function initFile(data: string[]) {
      if (data && data.length > 0) {
        setCodeRejetes(data)
        clearFile()
        fctRefresh()
      } else {
        setCodeRejetes([])
        clearFile()
        fctRefresh()
      }
    }
    if (selectedFile && isFilePicked) {
      if (mode === "ADD") {
        dispatch(addListCodeTiersWithFile(selectedFile, tag.code, initFile));
      }

      if (mode === "MERGE") {
        dispatch(mergeListCodeTiersWithFile(selectedFile, tag.code, initFile));
      }

    }
  }

  function eraseAndAddFile() {
    setAlerteEcrase(false)
    uploadedFile("ADD")
  }

  function mergeFile() {
    uploadedFile("MERGE")
  }

  useEffect(() => {
    fctRefresh()
  }, []);

  return (
    <>
      <Grid container spacing={mobileMode ? 0 : 10}>
        <Grid item xs={12} sm={12} className={mobileSpacing}>
          <Button variant="text" color="primary" disableElevation startIcon={<ArrowLeft htmlColor="#A0A0A0" fontSize="large" />} size="large" onClick={fctAnnuler}>
            {"Retour au tableau de bord"}
          </Button>
        </Grid>
        <Grid item xs={12} sm={8} className={mobileSpacing}>
          <Paper className={paper}>
            <Typography variant="h3" gutterBottom >{"Code tiers affectés au tag : " + tag.libelle}</Typography>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TableUserTags fctRemoveUser={fctRemoveUser} values={listeUser} ></TableUserTags>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} className={mobileSpacing}>
          <Paper className={paper}>
            <Typography variant="h3" gutterBottom >Mise à jour des codes tiers</Typography>
            <Grid container justify='center'>
              <Grid item xs={12} sm={12}>
                <Typography variant='h6'>Ajouter un code Tiers</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant='body2' color='error'>{errorCodeTiers ? "Le code tiers n'existe pas dans la base" : ""}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} justify='center' alignContent='center'>
                <MyTextField fullWidth margin="normal" label="" name="titre" value={addCodeTiers} onChange={handleChangeCodeTiers} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                  errorMsg={(!isEmptyVal(addCodeTiers) && !regExNumber.test(addCodeTiers)) ? "Merci de saisir un code tiers valide" : ""} />
              </Grid>
              <Grid item xs={6} sm={6} justify='center' alignContent='center' style={{ textAlign: "center", padding: 5 }}>
                <Button onClick={fctAddCodeTier} variant='contained' disabled={isEmptyVal(addCodeTiers) || !regExNumber.test(addCodeTiers)} color="primary">Ajouter</Button>
              </Grid>
              <Divider className={divider} />
              <Grid container justify='center'>
                <Grid item xs={12} sm={12}>
                  <Typography variant='h6'>Mettre à jour avec un fichier</Typography>
                </Grid>
                {!isFilePicked && (
                  <>
                    <input type="file" disabled={false} style={{ marginTop: "15px" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={setFile} />
                  </>
                )}
                {isFilePicked && (
                  <>
                    <Grid item xs={12} sm={12} style={{ textAlign: "center", padding: 5 }}>
                      <Button onClick={mergeFile} size='small' variant='contained' color='primary'>Ajouter les codes tiers</Button>
                    </Grid>
                    <Grid item xs={12} sm={8} style={{ textAlign: "center", padding: 5 }}>
                      <Button onClick={() => setAlerteEcrase(true)} size='small' variant='contained' color='primary'>écraser les codes tiers</Button>
                    </Grid>
                    <Grid item xs={12} sm={8} style={{ textAlign: "center", padding: 5 }}>
                      <Button onClick={clearFile} size='small' variant='outlined' color='primary'>annuler</Button>
                    </Grid>
                  </>
                )}
              </Grid>
              {codeRejetes && codeRejetes.length > 0 && (
                <>
                  <Divider className={divider} />
                  <Grid container justify='center'>
                    <Grid item xs={12}>
                      <Typography color="error"> Code tiers non existant dans la base</Typography>
                    </Grid>
                    {codeRejetes.map(code =>
                      <>
                        <Grid item xs={12}>
                          <Typography color="error">- {code}</Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid >
      {/*DIALOG SUPPRIME*/}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={alerteDelete}
      >
        <DialogContent>
          <DialogContentText color="initial">
            <Typography variant='h5'>Attention vous allez supprimer le code tiers</Typography>
            <Typography variant='h5'>Voulez vous continuer ?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button key={"NON"} onClick={fctCancelDelete} color="secondary">NON</Button>
          <Button key={"OUI"} onClick={fctDeleteUser} color="secondary">OUI</Button>
        </DialogActions>
      </Dialog>
      {/*DIALOG ECRASE & AJOUTE*/}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={alerteEcrase}
      >
        <DialogContent>
          <DialogContentText color="initial">
            <Typography variant='h5'>Attention vous allez supprimer tous les codes tiers actuels</Typography>
            <Typography variant='h5'>Voulez vous continuer ?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button key={"NON"} onClick={() => setAlerteEcrase(false)} color="secondary">NON</Button>
          <Button key={"OUI"} onClick={eraseAndAddFile} color="secondary">OUI</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DetailTagPage;