import { useQuery } from "../../utils/utils";
import { fetchLoginExtranet } from "./authApi";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Backdrop, CircularProgress } from "@material-ui/core";

export default function LoginExtranetPage() {
  const query = useQuery();
  const dispatch = useDispatch()

  const loginTiers = query.get("loginTiers");
  const jeton = query.get("jeton");
  const adresseIpSlc = query.get("AdresseIpSlc");

  useEffect(() => {
    dispatch(fetchLoginExtranet(loginTiers, jeton, adresseIpSlc));
  }, []);

  return (
    <Backdrop open>
      <CircularProgress/>
    </Backdrop>
  );
}