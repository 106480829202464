import { Backdrop, CircularProgress, createStyles, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";
import colors from "../../style/colors";

const useStyles = makeStyles(theme =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: colors.blanc,
      flexDirection: "column"
    }
  })
);

const Loader = () => {
  const { open } = useSelector((state: RootState) => state.loader)
  const { backdrop } = useStyles();
  return (
    <Backdrop open={open} className={backdrop}>
      <CircularProgress color="inherit" />
      <Typography variant="h2" align="center" color="inherit">Veuillez patienter, traitement en cours...</Typography>
    </Backdrop>
  );
}

export default Loader;