/* eslint-disable react/display-name */
import {createStyles, makeStyles, Typography} from "@material-ui/core";
import {Action, Column} from "material-table";
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import {  EnquetePanelisteDetailDto } from "./EnqueteType";
import {AddCircle, Visibility,VisibilityOffOutlined } from "@material-ui/icons";
import colors from "../../style/colors";

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 10,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 9,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    }
  })
)


export interface TableDetailPanelisteEnquetesProps<T extends object> extends MyMaterialTableProps<T> {
  type: string;
  fctDetailNotification: (user: EnquetePanelisteDetailDto) => void;
  showSheet: (data: EnquetePanelisteDetailDto) => void;
}

export const TableDetailPanelisteEnquete = (props: TableDetailPanelisteEnquetesProps<EnquetePanelisteDetailDto>) => {
  const { libelleTableau } = useStyles();
  const {showSheet, fctDetailNotification, type} = props;
  
   const columns: Column<EnquetePanelisteDetailDto>[] = [
    { title: "Code Tiers", field:"codeTiers", render: sc => <Typography className={libelleTableau}>{sc.codeTiers}</Typography> ,align: "left",filtering: true ,searchable:true},
    { title: "Nom", field:"nom", render: sc => <Typography className={libelleTableau}>{sc.nom}</Typography> ,align: "left",filtering: true ,searchable:true},
    { title: "Raison Sociale", field:"raisonSociale", render: sc => <Typography className={libelleTableau}>{sc.raisonSociale}</Typography> ,align: "left",filtering: true,searchable:true },
    { title: "Date Affectation", field:"d_affectation", render: sc => <Typography className={libelleTableau}>{sc.d_affectation}</Typography> ,align: "left",filtering: true,searchable:true },
    { title: "Date Validation", field:"d_validation", render: sc => <Typography className={libelleTableau}>{sc.d_validation}</Typography> ,align: "left",filtering: true,searchable:true, hidden: type === "ACTUALITE" },
    { title: "Date Ouverture", field:"d_ouverture", render: sc => <Typography className={libelleTableau}>{sc.d_ouverture ? sc.d_ouverture : "-" }</Typography> ,align: "left",filtering: true,searchable:true, hidden: type === "ENQUETE" },
    { title: "Dernière notification", field:"lastNotification", render: sc => <Typography className={libelleTableau}>{sc.lastNotification ? sc.lastNotification.libelle : "-"}</Typography> ,align: "left",filtering: true,searchable:true },
    { title: "Media", field:"lastNotification", render: sc => <Typography className={libelleTableau}>{sc.lastNotification ? sc.lastNotification.media : "-"}</Typography> ,align: "left",filtering: true,searchable:true },
    { title: "Date", field:"lastNotification", render: sc => <Typography className={libelleTableau}>{sc.lastNotification ? sc.lastNotification.date : "-"}</Typography> ,align: "left",filtering: true,searchable:true },
  ];

  const actions: (Action<EnquetePanelisteDetailDto> | ((sc: EnquetePanelisteDetailDto) => Action<EnquetePanelisteDetailDto>))[] = [
    sc => (
      {tooltip: "Voir toutes les notifications",icon: () => ( sc.lastNotification!=null?<AddCircle htmlColor={colors.jaune} fontSize="small"></AddCircle>:<AddCircle htmlColor={colors.gris} fontSize="small"></AddCircle>),onClick: (event, sc) => fctDetailNotification(sc as EnquetePanelisteDetailDto)}),
    sc => (
    {icon: () => ( sc.d_validation!=null?<Visibility htmlColor={colors.jaune} fontSize="large"></Visibility>:<VisibilityOffOutlined htmlColor={colors.gris} fontSize="large"></VisibilityOffOutlined>),onClick: (event, sc) => showSheet(sc as EnquetePanelisteDetailDto), hidden: type === "ACTUALITE"}),
  ]

  return <div>
            <MyMaterialTable<EnquetePanelisteDetailDto> columns={columns} {...props} exportAff={false} toolBarAff={true} searchAff={true} actions={actions} customBodyHeight={"400px"} customHeaderBckGrdColor={"#F2F2F2"}/>
         </div>;
};
