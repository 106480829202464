/* eslint-disable react/display-name */
import {createStyles,  makeStyles, Typography} from "@material-ui/core";
import {Column} from "material-table";
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import { Tag } from "../users/MonCompteType";

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    },cadrePaneliste:{
      border: "1px solid #32a1ce",
      borderRadius: "10px",
      paddingBottom:"20px",
      height:"200px",
      overflow:"auto"
    }
  })
)

export interface TableTagProps<T extends object> extends MyMaterialTableProps<T> {
  tagSelected: Tag[];
  onSelect?: (tag: Tag) => void;
  userIsAdmin: boolean;
}

export const TableTagToSelect = (props: TableTagProps<Tag>) => {
  const { libelleTableau ,cadrePaneliste,} = useStyles();

  const {tagSelected,onSelect,userIsAdmin} = props;
 

  const columns: Column<Tag>[] = [
    { title: "Code Tag", render: sc => <Typography className={libelleTableau}>{sc.code}</Typography> ,align: "left" },
    { title: "Nom", render: sc => <Typography className={libelleTableau}>{sc.libelle}</Typography> ,align: "left" },
    { title: "", render: () => <Typography className={libelleTableau}></Typography> ,align: "left" },
  ];

 
  function isRowSelected(rowData: Tag){
    for (const tag of tagSelected) {
      if(rowData.code === tag.code){
        return true;
      }
    }
    return false;
  }
  return <div className={cadrePaneliste}>
            <MyMaterialTable<Tag> columns={columns} {...props} isRowSelected={isRowSelected} onRowClick={userIsAdmin ? onSelect : null} fullWidth smallLign={true}  />
         </div>;
};
