/* eslint-disable react/display-name */
import {createStyles, makeStyles, Typography} from "@material-ui/core";
import { Action, Column} from "material-table";
import { Delete} from '@material-ui/icons';
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import colors from "../../style/colors";
import { UserTagAutoDto } from "./TagType";

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    }
  })
)


export interface TableUserTagsProps<T extends object> extends MyMaterialTableProps<T> {
  fctRemoveUser: (tag: UserTagAutoDto) => void;
}

export const TableUserTags = (props: TableUserTagsProps<UserTagAutoDto>) => {
  const { libelleTableau } = useStyles();

  const { fctRemoveUser } = props;
  
  const columns: Column<UserTagAutoDto>[] = [
    { title: "Code Tiers",field:"codeTiers", render: sc => <Typography className={libelleTableau}>{sc.codeTiers}</Typography> ,align: "left" },
    { title: "Nom",field:"nomPrenom", render: sc => <Typography className={libelleTableau}>{sc.nomPrenom}</Typography> ,align: "left" },
  ];

  const actions: (Action<UserTagAutoDto> | ((sc: UserTagAutoDto) => Action<UserTagAutoDto>))[] = [
    sc => 
    ({icon: () => ( <Delete htmlColor={colors.jaune} fontSize="large"></Delete> ),onClick: (event, sc) => fctRemoveUser(sc as UserTagAutoDto)}),

  ]

  return <div>
            <MyMaterialTable<UserTagAutoDto> columns={columns} {...props} searchAff={true} toolBarAff={true}  actions={actions}  sort={true} customBodyHeight={"500px"} customHeaderBckGrdColor={"#F2F2F2"}/>
         </div>;
};
