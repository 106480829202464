/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { Edit, Mail, Star } from "@material-ui/icons";
import { Action, Column } from "material-table";
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import colors from "../../style/colors";
import { AdminUser } from "./UsersAdminType";
import { compareDateTime, parseDateGetTime } from "../../utils/utils";

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 10,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    }
  })
)


export interface TableUsersProps<T extends object> extends MyMaterialTableProps<T> {
  fctEditUser: (user: AdminUser) => void;
  fctSendMail: (user: AdminUser) => void;
  userIsAdmin: boolean;
}

export const TableUsers = (props: TableUsersProps<AdminUser>) => {
  const { libelleTableau } = useStyles();

  const { fctEditUser, fctSendMail, userIsAdmin } = props;


  function backColorNonActif(_e: any, user: AdminUser) {
    if (user.statut == 'W') {
      return { backgroundColor: "red" }
    } else {
      return {};
    }
  }


  const triDernierConnexion = (a: AdminUser, b: AdminUser) => {
    const dateA = parseDateGetTime(a.dateDerniereConnexion);
    const dateB = parseDateGetTime(b.dateDerniereConnexion);

    return compareDateTime(dateA, dateB)
  }

  const triDernierInscription = (a: AdminUser, b: AdminUser) => {
    const dateA = parseDateGetTime(a.dateInscription);
    const dateB = parseDateGetTime(b.dateInscription);

    return compareDateTime(dateA, dateB)
  }

  const columns: Column<AdminUser>[] = [
    { title: "Nom", field: "nom", render: sc => <Typography className={libelleTableau}>{sc.nom}</Typography>, align: "left", filtering: true },
    { title: "", render: sc => sc.profilId !== null && sc.profilId === 1 && (<Star htmlColor={colors.jaune} fontSize="small"></Star>), align: "left" },
    { title: "Login", field: "login", render: sc => <Typography className={libelleTableau}>{sc.login}</Typography>, align: "left", filtering: true },
    { title: "Raison Sociale", field: "raisonSociale", render: sc => <Typography className={libelleTableau}>{sc.raisonSociale}</Typography>, align: "left", filtering: true },
    { title: "Code Tiers", field: "codeTiers", render: sc => <Typography className={libelleTableau}>{sc.codeTiers}</Typography>, align: "left", filtering: true },
    { title: "Type", field: "typeLibelle", render: sc => <Typography className={libelleTableau}>{sc.typeLibelle}</Typography>, align: "left" },
    { title: "Statut", field: "statutLibelle", cellStyle: backColorNonActif, render: sc => <Typography className={libelleTableau}>{sc.statutLibelle}</Typography>, align: "left" },
    { title: "Inscription", field: "dateInscription", render: sc => <Typography className={libelleTableau}>{sc.dateInscription}</Typography>, align: "left", customSort: triDernierInscription, sorting: true },
    { title: "Connexion", field: "dateDerniereConnexion", render: sc => <Typography className={libelleTableau}>{sc.dateDerniereConnexion}</Typography>, align: "left", customSort: triDernierConnexion, sorting: true },
    { title: "Application", field: "application", render: sc => <Typography className={libelleTableau}>{sc.usePwa ? "OUI" : "NON"}</Typography>, align: "left", filtering: true },
    { title: "Terminal", field: "terminal", render: sc => <Typography className={libelleTableau}>{sc.device}</Typography>, align: "left", filtering: true },
  ];



  const actions: (Action<AdminUser> | ((sc: AdminUser) => Action<AdminUser>))[] = [
    () => ({
      icon: () => (<Edit htmlColor={colors.jaune} fontSize="large"></Edit>),
      onClick: (event, sc) => fctEditUser(sc as AdminUser)
    }),
    sc => ({
      disabled: sc.statut !== 'W' || !userIsAdmin,
      hidden: sc.statut !== 'W' || !userIsAdmin,
      icon: () => (<Mail htmlColor={colors.jaune} fontSize="large"></Mail>),
      onClick: (event, sc) => fctSendMail(sc as AdminUser)
    })
  ];

  return <div>
    <MyMaterialTable<AdminUser> columns={columns} {...props} actions={actions} sort={true} searchAff={true} exportAff={true} toolBarAff={true} customBodyHeight={"500px"} customHeaderBckGrdColor={"#F2F2F2"} />
  </div>;
};
