import { createMuiTheme, Theme } from "@material-ui/core";
import colors from "./colors";

const theme = (theme: Theme) => createMuiTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDrawer: {
      paper: {
        backgroundColor: colors.emc2
      }
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: colors.emc2
      }
    }
  }
})

export default theme;
