import React , { useEffect,useCallback } from 'react';
import { makeStyles, createStyles} from '@material-ui/core';
import { useGlobalStyles } from '../../style/globalStyles';
import { EnqueteSendUp } from "./EnqueteType";


const useStyles = makeStyles(
  createStyles({
    iframe: {
      width: "100%",
      border: 0,
      height: 640
    }
  })
)

export interface EnqueteSendUpFrameProps{
  enquete: EnqueteSendUp;
  exitSave?: (idSheet: number) => void;
}

const EnqueteSendUpFrame = (props: EnqueteSendUpFrameProps) => {
  
  const {enquete,exitSave} = props;
  const { iframe} = useStyles();
  const {margeContainer} = useGlobalStyles();
   
  
  // GESTION DES EVENEMENTS
  const handleMessage = useCallback(event => {
      if(event.data.type=="DRAFT"){
        //
      }
      if(event.data.type=="FINAL"){
        exitSave(event.data.sheetId);
      }
  }, []);

  useEffect(() => {
    window.addEventListener('message',handleMessage);
    return () => {
      window.removeEventListener('message',handleMessage);
    };
  }, [handleMessage]);


  const blocFrame =   <div className={margeContainer}><iframe className={iframe} src={enquete.url}/></div>;
  
  return (
      <>
      {blocFrame}
      </>
  );
}

export default EnqueteSendUpFrame;