import { createSlice } from '@reduxjs/toolkit'

export interface LoaderState {
  open: boolean;
}

const initialState: LoaderState = {
  open: false
}

const loader = createSlice({
  name: "loader",
  initialState,
  reducers: {
    showLoader(state) {
      state.open = true
    },
    hideLoader(state) {
      state.open = false
    }
  }
})

export const { showLoader, hideLoader } = loader.actions;

export default loader.reducer;