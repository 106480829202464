import { createSlice } from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit';
import { MenuItem } from './menuTypes'
import { NavElement } from '../../utils/types';

export interface MenuState {
  menuItem: MenuItem;
  nav: NavElement[];
  openedSousMenus: string[];
}

const initialState: MenuState = {
  menuItem: null,
  nav: [],
  openedSousMenus: []
}

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    resetMenuState: state => {
      Object.assign(state, initialState);
    },
    setMenuItem(state, action: PayloadAction<MenuItem>) {
      state.menuItem = action.payload
    },
    setNav(state, action: PayloadAction<NavElement[]>) {
      state.nav = action.payload
    },
    toggleOpenedSousMenus(state, action: PayloadAction<string[]>) {
      state.openedSousMenus = action.payload; 
    }
  }
})

export const { resetMenuState, setMenuItem, setNav, toggleOpenedSousMenus } = menu.actions;

export default menu.reducer;