import React, { useState } from 'react';
import { Paper, Grid, Typography, TextField, Fab, makeStyles, Hidden, Link, InputAdornment, IconButton, Input } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { fetchLogin } from './authApi';
import { ChangeHandler } from '../../utils/types';
import images from '../../img/images';
import { fetchDemandeChangeMDP,fetchRechercheIdentifiant } from "./InscriptionApi";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { isEmptyVal } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}))

const LoginPage = () => {
  const { paper } = useStyles();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showPassWord, setShowPassWord] = useState<boolean>(false);
  const dispatch = useDispatch()
  const handleLoginChange: ChangeHandler = e => setLogin(e.target.value)
  const handlePasswordChange: ChangeHandler = e => setPassword(e.target.value)

  const [rechercheIdentifiant, setRechercheIdentifiant] = useState("");
  const handleAdresseMailChange: ChangeHandler = e => setRechercheIdentifiant(e.target.value);

  const [step, setStep] = useState<number>(1);

  function handleShowPassWord() {
    setShowPassWord(!showPassWord);
  }

  function fctMdpOublie() {
    setStep(2);
  }

  function fctIdentifiantOublie() {
    setRechercheIdentifiant("");
    setStep(4);
  }

  function fctMdpOublieAnnuler() {
    setStep(1);
  }

  function demandeChangementMdp() {
    function success(data: boolean) {
      if (data) {
        setStep(3);
      }

    }
    dispatch(fetchDemandeChangeMDP(login, success));
  }

  function demandeObtenirIdentifiant() {
    function success(data: boolean) {
      if (data) {
        setStep(3);
      }

    }
    dispatch(fetchRechercheIdentifiant(rechercheIdentifiant, success));
  }

  const handleLoginClick = () => { dispatch(fetchLogin(login, password)) }
  return (
    <Paper className={paper}>
      <Grid container direction="row">
        <Hidden xsDown><Grid item xs={4}></Grid></Hidden>
        <Grid item xs={12} sm={4}>
          <Grid container alignContent="center" justify="center" alignItems="center">
            <Grid item xs={12}><img src={images.logos.emc2[0]} height={80} /></Grid>
            {step === 1 &&
              (
                <>
                  <Grid item xs={12}><Typography variant="h4">Veuillez saisir vos identifiants de connexion</Typography></Grid>
                  <Grid item xs={12}><Typography style={{ margin: "5px" }} variant="h5">Identifiant :</Typography></Grid>
                  <Grid item xs={12} sm={12}>
                    <Input
                      style={{ margin: "5px" }}
                      id="outlined-adornment-login"
                      type={'text'}
                      value={login}
                      onChange={handleLoginChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}><Typography style={{ margin: "5px" }} variant="h5">Mot de passe :</Typography></Grid>
                  <Grid item xs={12} sm={12}>
                    <Input
                      style={{ margin: "5px" }}
                      id="outlined-adornment-password"
                      type={showPassWord ? 'text' : 'password'}
                      value={password}
                      onChange={handlePasswordChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassWord} >
                            {showPassWord ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}><Fab variant="extended" color="secondary" onClick={handleLoginClick}>SE CONNECTER</Fab></Grid>
                  <Grid item xs={12} style={{ textAlign: "right" }}><Link onClick={fctMdpOublie}>{"J'ai oublié mon mot de passe"}</Link></Grid>
                  <Grid item xs={12} style={{ textAlign: "right" }}><Link onClick={fctIdentifiantOublie}>{"J'ai oublié mon identifiant"}</Link></Grid>    
                </>
              )}
            {step === 2 &&
              (
                <>
                  <Grid item xs={12}><Typography variant="h4">{"Veuillez saisir votre identifiant"}</Typography></Grid>
                  <Grid item xs={12}><Typography variant="h6">{"Si votre identifiant existe vous allez recevoir un email avec la procédure de modification de votre mot de passe."}</Typography></Grid>
                  <Grid item xs={12}><TextField fullWidth margin="normal" label="Identifiant" value={login} onChange={handleLoginChange} /></Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}><Fab variant="extended" color="secondary" disabled={login === null || login === undefined || login === ""} onClick={demandeChangementMdp}>MODIFIER MON MOT DE PASSE</Fab></Grid>
                  <Grid item xs={12} style={{ textAlign: "right" }}><Link onClick={fctMdpOublieAnnuler}>{"Annuler"}</Link></Grid>
                </>
              )}

            {step === 3 &&
              (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h4">{"Votre demande a bien été prise en compte."}</Typography>
                    <Typography variant="h6">{"Si vous ne recevez pas d'email pensez à verifier vos spams/mails indésirables."}</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "right" }}><Link onClick={fctMdpOublieAnnuler}>{"Retour à la page de connexion"}</Link></Grid>
                </>
              )}

            {step === 4 &&
              (
                <>
                  <Grid item xs={12}><Typography variant="h4">{"Veuillez saisir le numéro de téléphone ou l'adresse email renseignés lors de votre inscription."}</Typography></Grid>
                  <Grid item xs={12}><Typography variant="h6">{"Si il y a une correspondance avec un compte existant vous receverez un email avec votre identifiant."}</Typography></Grid>
                  <Grid item xs={12}><TextField fullWidth margin="normal" label="" value={rechercheIdentifiant} onChange={handleAdresseMailChange} /></Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}><Fab variant="extended" color="secondary" disabled={isEmptyVal(rechercheIdentifiant)} onClick={demandeObtenirIdentifiant}>OBTENIR MON IDENTIFIANT</Fab></Grid>
                  <Grid item xs={12} style={{ textAlign: "right" }}><Link onClick={fctMdpOublieAnnuler}>{"Annuler"}</Link></Grid>
                </>
              )}

          </Grid>
        </Grid>
        <Hidden xsDown><Grid item xs={4}></Grid></Hidden>
      </Grid>
    </Paper>
  );
}

export default LoginPage;