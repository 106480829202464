import { HttpClient, ProcessUtils } from "mooltycommons";


//const baseUrlDev = "http://127.0.0.1:8080";
const baseUrlDev = "..";
//const baseUrlDev = "http://10.57.5.2:8179";
//const baseUrlDev = "https://yaka.emc2.coop:9451";
//const baseUrlProd = "http://127.0.0.1:8080";
const baseUrlProd = "http://10.57.5.2:8179";
const relativeUrl = "/e-agriskop/apirestagriskop/v1";

class RestClient {
  httpClient: HttpClient;
  baseUrl: string;

  constructor() {
    this.httpClient = new HttpClient();

    if (ProcessUtils.isDev()) {
      this.baseUrl = baseUrlDev + relativeUrl;
    } else {
      this.baseUrl = baseUrlProd + relativeUrl;
    }
  }

  get = <T>(service: string, method: string, parameters: string, fctSuccess: (data: T) => void, fctError: (error: Error) => void) => {
    const url = this.baseUrl + "/" + service + (method !== "" ? "/" + method : "") + (parameters !== "" ? "/" : "") + parameters;

    this.httpClient.getResponseJson(url, (response: Response) => {
      if (!response.ok) {
        let message = response.statusText;

        if (response.status === 440) {
          message = "Expired";
        }

        throw new Error(message);
      }
    }, fctSuccess, fctError);
  }

  delete = <T>(service: string, method: string, parameters: string, fctSuccess: (data: T) => void, fctError: (error: Error) => void) => {
    const url = this.baseUrl + "/" + service + (method !== "" ? "/" + method : "") + (parameters !== "" ? "/" : "") + parameters;

    this.httpClient.deleteResponseJson(url, (response: Response) => {
      if (!response.ok) {
        let message = response.statusText;

        if (response.status === 440) {
          message = "Expired";
        }

        throw new Error(message);
      }
    }, fctSuccess, fctError);
  }

  post = <U, V >(service: string, method: string, parameters: string, data: U, fctSuccess: (data: V) => void, fctError: (error: Error) => void) => {
    const url = this.baseUrl + "/" + service + (method !== "" ? "/" + method : "") + (parameters !== "" ? "/" : "") + parameters;

    this.httpClient.postJsonResponseJson<U, V>(url, data, (response: Response) => {
      if (!response.ok) {
        let message = response.statusText;

        if (response.status === 440) {
          message = "Expired";
        }

        throw new Error(message);
      }
    }, fctSuccess, fctError);
  }

  put = <U, V >(service: string, method: string, parameters: string, data: U, fctSuccess: (data: V) => void, fctError: (error: Error) => void) => {
    const url = this.baseUrl + "/" + service + (method !== "" ? "/" + method : "") + (parameters !== "" ? "/" : "") + parameters;

    this.httpClient.putJsonResponseJson<U, V>(url, data, (response: Response) => {
      if (!response.ok) {
        let message = response.statusText;

        if (response.status === 440) {
          message = "Expired";
        }

        throw new Error(message);
      }
    }, fctSuccess, fctError);
  }

  download = <T>(service: string, method: string, parameters: string) => {
    const url = this.baseUrl + "/" + service + (method !== "" ? "/" + method : "") + (parameters !== "" ? "/" : "") + parameters;

    window.location.href = url;
  }
}

const restClient: RestClient = new RestClient();

export default restClient;
