/* eslint-disable react/display-name */
import {Button, createStyles, makeStyles, Paper, Typography} from "@material-ui/core";
import {Column} from "material-table";
import React, { useEffect, useState } from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import { PublicationTBD, NotificationDto } from "./EnqueteType";
import { ArrowLeft } from "@material-ui/icons";
import { fetchNotification } from "./PublicationApi";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(5),
      padding: theme.spacing(10),
      alignItems: 'left',
    },
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 10,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 9,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    }
  })
)

export interface DetailNotificationProps {
  publication: PublicationTBD;
  typePubTitle: string;
  idUsersConvention: number;
  fctAnnuler: () => void;
}


const DetailNotification = (props: DetailNotificationProps) => {
  const { publication, fctAnnuler, idUsersConvention, typePubTitle } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [notification, setNotification] = useState<NotificationDto[]>(null);

  const { paper } = classes;

  function initNotification(data: NotificationDto[]) {
    if (data) {
      setNotification(data)
    }
  }


  useEffect(() => {
    dispatch(fetchNotification(idUsersConvention, publication.id ,initNotification))
  }, []);

  return (
    <>
      <Button variant="text" color="primary" disableElevation startIcon={<ArrowLeft htmlColor="#A0A0A0" fontSize="large" />} size="large" onClick={fctAnnuler}>
        {"Retour au détail panéliste"}
      </Button>
      <Typography variant="h3" gutterBottom >{"Détail des notifications"}</Typography>
      <Paper className={paper} elevation={0}>
        <Typography variant="h4" gutterBottom color="secondary">{typePubTitle + " n° " + publication.id}</Typography>
        <Typography variant="h4" gutterBottom color="secondary" >{"Titre : " + publication.titre}</Typography>
        <TableDetailNotification values={notification} />
      </Paper>
    </>
  );
}

export default DetailNotification;

export const TableDetailNotification = (props: MyMaterialTableProps<NotificationDto>) => {
  const { libelleTableau } = useStyles();
  
   const columns: Column<NotificationDto>[] = [
    { title: "Notification", field:"lastNotification", render: sc => <Typography className={libelleTableau}>{sc.libelle}</Typography> ,align: "left",filtering: true,searchable:true },
    { title: "Media", field:"lastNotification", render: sc => <Typography className={libelleTableau}>{sc.media}</Typography> ,align: "left",filtering: true,searchable:true },
    { title: "Date", field:"lastNotification", render: sc => <Typography className={libelleTableau}>{sc.date}</Typography> ,align: "left",filtering: true,searchable:true },
  ];


  return <div>
            <MyMaterialTable<NotificationDto> columns={columns} {...props} exportAff={false} toolBarAff={true} searchAff={true} />
         </div>;
};
