import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography, Paper, Checkbox, FormControlLabel, Hidden } from '@material-ui/core';
import PageTemplate from '../../page/PageTemplate';
import { NavElement, ChangeHandler } from '../../utils/types';
import { formatDateStringFrToUs, isEmptyVal, useMobileMode } from '../../utils/utils';
import MyTextField, { MyNumberTextField } from "../../components/MyTextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { fr } from "date-fns/locale";
import { useCurrentUser } from '../auth/authApi';
import { fetchInfoUserPaneliste } from "./usersApi";
import { UserInfoCompteDto } from "./MonCompteType";
import { useDispatch } from 'react-redux';
import AvatarPanelHomme from "../../img/avatar/avatar_paneliste_homme.jpg";
import AvatarPanelFemme from "../../img/avatar/avatar_paneliste_femme.png";
import epis from "../../img/illustrations/epis.svg";

const colorsDonut = {
    //[active, fade]
    greenColor: ["#11cd5c", "#c3f0b8"],
    redColor: ["#990000", "#FF4C4C"]
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10
    }, avatarClass: {
        height: "180px",
        width: "180px",
        alignSelf: 'center'
    }, donutParticipation: {
        width: '224px',
        height: '224px',
        display: 'inline-block',
        borderRadius: '50%',
        textAlign: 'center'
    }, imgAvatar: {
        borderRadius: '50%',
        width: '200px',
        height: '200px',
        marginTop: '12px'
    }
})
)

const NAV_ELEMENTS: NavElement[] = [{ name: "Mon Compte", path: "/MonCompte" }];

const MonComptePage = () => {

    const mobileMode = useMobileMode();
    const { paper, donutParticipation, imgAvatar } = useStyles();
    const user = useCurrentUser();
    const dispatch = useDispatch();

    const [nom, setNom] = useState<string>(null);
    const [email, setEmail] = useState<string>(null);
    const [numeroTelephone, setNumeroTelephone] = useState<string>(null);
    const [dateNaissance, setDateNaissance] = useState(new Date());

    const [checkSMS, setCheckSMS] = useState<boolean>(false);
    const [checkMail, setCheckMail] = useState<boolean>(false);
    const [infoactuOui,setInfoactuOui] = useState<boolean>(false);
    const [infoactuNon,setInfoactuNon] = useState<boolean>(false);

    const handleNomChange: ChangeHandler = e => setNom(e.target.value)
    const handleEmailChange: ChangeHandler = e => setEmail(e.target.value)
    const handleNumTelChange: ChangeHandler = e => setNumeroTelephone(e.target.value)

    const onToggleCheckSMS: ChangeHandler = () => {setCheckSMS(!checkSMS); }
    const onToggleCheckMail: ChangeHandler = () => {setCheckMail(!checkMail);}
  
    const onToggleInfoactuOui: ChangeHandler = () => {setInfoactuOui(!infoactuOui);setInfoactuNon(false); }
    const onToggleInfoactuNon: ChangeHandler = () => {setInfoactuNon(!infoactuNon);setInfoactuOui(false); }

    const [infoUsersEnquete, setInfoUsersEnquete] = useState<UserInfoCompteDto>();

    {/* MON EXPLOITATION */ }
    const [cerealier, setCerealier] = useState<boolean>(false);
    const [eleveur, setEleveur] = useState<boolean>(false);
    const [arboriculteur, setArboriculteur] = useState<boolean>(false);
    const [autre, setAutre] = useState<boolean>(false);
    const [repAutre, setRepAutre] = useState<string>(null);

    const onToggleCerealier: ChangeHandler = () => { setCerealier(!cerealier); }
    const onToggleEleveur: ChangeHandler = () => { setEleveur(!eleveur); }
    const onToggleArboriculteur: ChangeHandler = () => { setArboriculteur(!arboriculteur); }
    const onToggleAutre: ChangeHandler = () => { setAutre(!autre); if (autre) { setRepAutre("") } }
    const handleRepAutre: ChangeHandler = e => setRepAutre(e.target.value);

    {/* ELEVEUR */ }
    const [bovin, setBovin] = useState<boolean>(false);
    const [ovin, setOvin] = useState<boolean>(false);
    const [porcin, setPorcin] = useState<boolean>(false);
    const [autreEleveur, setAutreEleveur] = useState<boolean>(false);
    const [repAutreEleveur, setRepAutreEleveur] = useState<string>(null);
    const [nbBovin, setNbBovin] = useState<number>(null);
    const [nbOvin, setNbOvin] = useState<number>(null);
    const [nbPorcin, setNbPorcin] = useState<number>(null);

    const onToggleBovin: ChangeHandler = () => { setBovin(!bovin); if (bovin) { setNbBovin(null); } }
    const onToggleOvin: ChangeHandler = () => { setOvin(!ovin); if (ovin) { setNbOvin(null); } }
    const onTogglePorcin: ChangeHandler = () => { setPorcin(!porcin); if (porcin) { setNbBovin(null); } }
    const onToggleAutreEleveur: ChangeHandler = () => { setAutreEleveur(!autreEleveur); if (autreEleveur) { setRepAutreEleveur("") } }
    const handleRepAutreEleveur: ChangeHandler = e => setRepAutreEleveur(e.target.value);

    const [sau, setSau] = useState<number>(null);
    const [surfaceHerbe, setSurfaceHerbe] = useState<number>(null);

    const [showError, setShowError] = useState<boolean>(false);

    const [genre, setGenre] = useState<string>(null);
    const [tauxParticipation, setTauxParticipation] = useState<number>(0);
    const [backgroundDonut, setBackgroundDonut] = useState<string>("#FF4C4C");

    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = today.getMonth() + 1; // Months start at 0!
    const dd = today.getDate();
    let DD,MM;
    DD=dd;
    MM=mm;
    if (dd < 10) DD = '0' + dd;
    if (mm < 10) MM = '0' + mm;

    const formattedToday = DD + '/' + MM + '/' + yyyy;

    const onToggleCheckHomme: ChangeHandler = (e) => {
        const target = e.target
        if (target.checked) {
            setGenre('H');
        }
    }

    const onToggleCheckFemme: ChangeHandler = (e) => {
        const target = e.target
        if (target.checked) {
            setGenre('F');
        }
    }

    useEffect(() => {
        if (infoUsersEnquete !== null && infoUsersEnquete !== undefined) {
            if (infoUsersEnquete.nombreEnquetesValidee != null && infoUsersEnquete.nombreEnquetesSoumise !== null) {
                const moyenne: number = Math.round(((infoUsersEnquete.nombreEnquetesValidee * 100) / infoUsersEnquete.nombreEnquetesSoumise));
                if (Number.isNaN(moyenne)) {
                    setTauxParticipation(0)
                } else {
                    setTauxParticipation(moyenne);
                }
            }
        }
    }, [infoUsersEnquete]);

    useEffect(() => {
        let couleurs: string[] = [];
        if (tauxParticipation < 50) {
            couleurs = colorsDonut.redColor;
        } else if (tauxParticipation >= 50) {
            couleurs = colorsDonut.greenColor;
        }
        const couleurActive = couleurs[0];
        const couleurFade = couleurs[1];
        const background = "radial-gradient(white 10%, transparent 0%), conic-gradient( " + couleurActive + " 0% " + tauxParticipation + "%, " + couleurFade + " " + tauxParticipation + "% 100%)";
        setBackgroundDonut(background)
    }, [tauxParticipation]);


    useEffect(() => {

        function initInfoCompte(data: UserInfoCompteDto) {
            setInfoUsersEnquete(data);
        }

        if (user) {
            setNom(user.user.nom);
            setEmail(user.userConvention.email);
            setNumeroTelephone(user.userConvention.gsm);
            if (user.proprietes !== null && user.proprietes !== undefined && user.proprietes.DATE_NAISSANCE !== null && user.proprietes.DATE_NAISSANCE !== undefined) {
                setDateNaissance(new Date(formatDateStringFrToUs(user.proprietes.DATE_NAISSANCE.valeur)));
            }
            if (user.proprietes !== null && user.proprietes !== undefined && user.proprietes.GENRE !== null && user.proprietes.GENRE !== undefined) {
                setGenre(user.proprietes.GENRE.valeur);
            }

            if (user.userConvention.mediaContact.indexOf("SMS") !=-1) {
                setCheckSMS(true);
            } if (user.userConvention.mediaContact.indexOf("EMAIL") !=-1) {
                setCheckMail(true);
            } 

            if (user.proprietes !== null && user.proprietes !== undefined) {
                
                // INFO ACTU
                if(user.proprietes.INFO_ACTU !== null && user.proprietes.INFO_ACTU != undefined && user.proprietes.INFO_ACTU.valeur === 'O'){
                    setInfoactuOui(true);
                }else{
                    setInfoactuNon(true);
                }
                
                //TYPE_CEREALIER
                if (user.proprietes.TYPE_CEREALIER !== null && user.proprietes.TYPE_CEREALIER !== undefined && user.proprietes.TYPE_CEREALIER.valeur === 'O') {
                    setCerealier(true);
                    if (user.proprietes.SAU !== null && user.proprietes.SAU !== undefined) {
                        user.proprietes.SAU.valeur !== null ? setSau(parseFloat(user.proprietes.SAU.valeur)) : setSau(null);
                    }
                    if (user.proprietes.SURFACE_HERBE !== null && user.proprietes.SURFACE_HERBE !== undefined) {
                        user.proprietes.SURFACE_HERBE.valeur !== null ? setSurfaceHerbe(parseFloat(user.proprietes.SURFACE_HERBE.valeur)) : setSurfaceHerbe(null);
                    }
                }
                //TYPE_ELEVEUR
                if (user.proprietes.TYPE_ELEVEUR !== null && user.proprietes.TYPE_ELEVEUR !== undefined && user.proprietes.TYPE_ELEVEUR.valeur === 'O') {
                    setEleveur(true);
                    //ELEVEUR_BOVIN
                    if (user.proprietes.ELEVEUR_BOVIN !== null && user.proprietes.ELEVEUR_BOVIN !== undefined && user.proprietes.ELEVEUR_BOVIN.valeur === 'O') {
                        setBovin(true);
                        if (user.proprietes.BOVIN_NB !== null && user.proprietes.BOVIN_NB !== undefined) {
                            setNbBovin(Number(user.proprietes.BOVIN_NB.valeur));
                        }
                    }
                    //ELEVEUR_OVIN
                    if (user.proprietes.ELEVEUR_OVIN !== null && user.proprietes.ELEVEUR_OVIN !== undefined && user.proprietes.ELEVEUR_OVIN.valeur === 'O') {
                        setOvin(true);
                        if (user.proprietes.OVIN_NB !== null && user.proprietes.OVIN_NB !== undefined) {
                            setNbOvin(Number(user.proprietes.OVIN_NB.valeur));
                        }
                    }
                    //ELEVEUR_PORCIN
                    if (user.proprietes.ELEVEUR_PORCIN !== null && user.proprietes.ELEVEUR_PORCIN !== undefined && user.proprietes.ELEVEUR_PORCIN.valeur === 'O') {
                        setPorcin(true);
                        if (user.proprietes.PORCIN_NB !== null && user.proprietes.PORCIN_NB !== undefined) {
                            setNbPorcin(Number(user.proprietes.PORCIN_NB.valeur));
                        }
                    }
                    //ELEVEUR_AUTRE
                    if (user.proprietes.ELEVEUR_AUTRE !== null && user.proprietes.ELEVEUR_AUTRE !== undefined) {
                        user.proprietes.ELEVEUR_AUTRE.valeur !== 'N' ? setAutreEleveur(true) : setAutreEleveur(false);
                        user.proprietes.ELEVEUR_AUTRE.valeur !== 'N' ? setRepAutreEleveur(user.proprietes.ELEVEUR_AUTRE.valeur) : setRepAutreEleveur(null);
                    }
                }
                //TYPE_ARBORICULTEUR
                if (user.proprietes.TYPE_ARBORICULTEUR !== null && user.proprietes.TYPE_ARBORICULTEUR !== undefined && user.proprietes.TYPE_ARBORICULTEUR.valeur === 'O') {
                    setArboriculteur(true);
                    if (user.proprietes.SAU !== null && user.proprietes.SAU !== undefined) {
                        user.proprietes.SAU.valeur !== null ? setSau(parseFloat(user.proprietes.SAU.valeur)) : setSau(null);
                    }
                }
                //TYPE_AUTRE
                if (user.proprietes.TYPE_AUTRE !== null && user.proprietes.TYPE_AUTRE !== undefined) {
                    (user.proprietes.TYPE_AUTRE.valeur !== null && user.proprietes.TYPE_AUTRE.valeur !== undefined) ? setAutre(true) : setAutre(false);
                    (user.proprietes.TYPE_AUTRE.valeur !== null && user.proprietes.TYPE_AUTRE.valeur !== undefined)  ? setRepAutre(user.proprietes.TYPE_AUTRE.valeur) : setRepAutre(null);
                }
            }

            dispatch(fetchInfoUserPaneliste(user.userConvention.idUsersConvention, initInfoCompte));
        }
    }, [user]);

    const messageGCP = (user.proprietes.URL_CGP && !isEmptyVal(user.proprietes.URL_CGP.valeur))?"<a target='_blank' href='"+user.proprietes.URL_CGP.valeur+"' >Conditions Générales de Participation</a>":"";

    return (
        <PageTemplate elements={NAV_ELEMENTS}>
            <Grid container spacing={mobileMode ? 0 : 10}>
                <Grid item xs={12} sm={9}>
                    <Grid container spacing={mobileMode ? 0 : 10}>
                        <Grid item xs={12}><Typography variant="h3">Mon Compte</Typography></Grid>
                    </Grid>
                    <Paper className={paper}>
                        <Grid container>
                            <Grid item xs={12}><Typography variant="h3" gutterBottom align="center">Mes informations</Typography></Grid>
                            <Grid item xs={3} sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Nom"}</Typography>
                            </Grid>
                            <Grid item xs={9} sm={9}>
                                <MyTextField fullWidth margin="normal" label="" value={nom} onChange={handleNomChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }} disabled={true} />
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Email"}</Typography>
                            </Grid>
                            <Grid item xs={9} sm={9}>
                                <MyTextField fullWidth margin="normal" label="" value={email} onChange={handleEmailChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }} disabled={true} />
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Numero Téléphone"}</Typography>
                            </Grid>
                            <Grid item xs={9} sm={9}>
                                <MyTextField fullWidth margin="normal" label="" value={numeroTelephone} onChange={handleNumTelChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }} disabled={true} />
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Date de naissance"}</Typography>
                            </Grid>
                            <Grid item xs={9} sm={9}>
                                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        format="dd/MM/yyyy"
                                        value={dateNaissance}
                                        onChange={setDateNaissance}
                                        disableFuture
                                        animateYearScrolling
                                        variant="inline"
                                        inputVariant="outlined"
                                        inputProps={{ style: { padding: "6px" } }}
                                        disabled={true}
                                        style={{ marginLeft: "5px", marginBottom: "5px" }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Genre : "}</Typography>
                            </Grid>
                            <Hidden mdUp><Grid item xs={3}></Grid></Hidden>
                            <Grid item xs={4} sm={2}>
                                <FormControlLabel control={<Checkbox
                                    color="primary"
                                    checked={genre === 'H'}
                                    disabled={true}
                                    onChange={onToggleCheckHomme}
                                />}
                                    label="Homme" />
                            </Grid>
                            <Grid item xs={5} sm={7}>
                                <FormControlLabel control={<Checkbox
                                    color="primary"
                                    checked={genre === 'F'}
                                    disabled={true}
                                    onChange={onToggleCheckFemme}
                                />}
                                    label="Femme"
                                />
                            </Grid>
                            {showError && (genre == null || genre == undefined && genre.trim() == "") &&
                                (
                                    <>
                                        <Grid item xs={3} sm={3}></Grid>
                                        <Grid item xs={9} sm={9}>
                                            <Typography variant="subtitle2" color="error">{"Vous devez sélectionner une des options"}</Typography>
                                        </Grid>
                                    </>
                                )}
                            {user.proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE" && (
                                <>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Je souhaite être contacté de préférence par "}</Typography>
                                    </Grid>
                                    <Hidden mdUp><Grid item xs={3}></Grid></Hidden>
                                    <Grid item xs={4} sm={2}>
                                        <FormControlLabel control={<Checkbox
                                            color="primary"
                                            checked={checkSMS}
                                            onChange={onToggleCheckSMS}
                                            disabled={true}
                                        />}
                                            label="SMS" />
                                    </Grid>
                                    <Grid item xs={4} sm={6}>
                                        <FormControlLabel control={<Checkbox
                                            color="primary"
                                            checked={checkMail}
                                            onChange={onToggleCheckMail}
                                            disabled={true}
                                        />}
                                            label="Email" />
                                    </Grid>
                                    <Grid item xs={12}  sm={3}>
                                        <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Je souhaite être informé dès qu’il y a une nouvelle actualité : "}</Typography>
                                    </Grid>
                                    <Hidden mdUp><Grid item xs={3}></Grid></Hidden> 
                                    <Grid item xs={4}  sm={2}>
                                        <FormControlLabel control={<Checkbox 
                                                                    color="primary" 
                                                                    checked={infoactuOui} 
                                                                    onChange={onToggleInfoactuOui} 
                                                                    disabled={true}
                                                                    />} 
                                        label="OUI" />
                                    </Grid>
                                    <Grid item xs={5}  sm={7}>
                                        <FormControlLabel control={<Checkbox 
                                                                    color="primary" 
                                                                    checked={infoactuNon} 
                                                                    onChange={onToggleInfoactuNon} 
                                                                    disabled={true}
                                                                    />} 
                                        label="NON" 
                                        />
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h3" color="textPrimary" gutterBottom align="center" >{"Mon exploitation"}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Je suis"}</Typography>
                                        </Grid>
                                        {showError && !(eleveur || cerealier || arboriculteur || autre) &&
                                            (
                                                <>
                                                    <Hidden mdUp><Grid item xs={3}></Grid></Hidden>
                                                    <Grid item xs={9} sm={9}>
                                                        <Typography variant="subtitle2" color="error">{"Merci de sélectionner au moins une réponse"}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3}></Grid>
                                                </>
                                            )}

                                        <Grid item xs={9} sm={9}>
                                            <FormControlLabel control={<Checkbox
                                                color="primary"
                                                checked={cerealier}
                                                disabled={true}
                                                onChange={onToggleCerealier}
                                            />}
                                                label="Céréalier" />
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={9} sm={9}>
                                            <FormControlLabel control={<Checkbox
                                                color="primary"
                                                checked={eleveur}
                                                disabled={true}
                                                onChange={onToggleEleveur}
                                            />}
                                                label="Eleveur" />
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={9} sm={9}>
                                            <FormControlLabel control={<Checkbox
                                                color="primary"
                                                checked={arboriculteur}
                                                disabled={true}
                                                onChange={onToggleArboriculteur}
                                            />}
                                                label="Arboriculteur" />
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={3} sm={2}>
                                            <FormControlLabel control={<Checkbox
                                                color="primary"
                                                checked={autre}
                                                disabled={true}
                                                onChange={onToggleAutre}
                                            />}
                                                label="Autre" />
                                        </Grid>
                                        <Grid item xs={6} sm={7}>
                                            <MyTextField disabled={true} fullWidth margin="normal" label="" value={repAutre} onChange={handleRepAutre} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                                                errorMsg={((autre && (repAutre === "" || repAutre === null || repAutre === undefined)) && showError) ? "Merci de préciser" : ""} />
                                        </Grid>
                                    </Grid>

                                    {(cerealier || arboriculteur) && (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12} sm={3} >
                                                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"La Surface Agricole Utile de mon exploitation est de (en ha) :"}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <MyNumberTextField
                                                        min={0}
                                                        precision={3}
                                                        label=""
                                                        onNumberChange={setSau}
                                                        value={sau}
                                                        inputProps={{ style: { padding: "6px", marginLeft: "5px" } }}
                                                        disabled={true}
                                                    //errorMsg={((sau<=0)&&showError)?"Merci de saisir une valeur":""}
                                                    />
                                                </Grid>
                                                <Hidden xsDown><Grid item xs={6}></Grid></Hidden>
                                            </Grid>
                                        </>
                                    )}

                                    {(cerealier) && (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12} sm={3} >
                                                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"La surface en herbe de mon exploitation est de (en ha) :"}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <MyNumberTextField
                                                        min={0}
                                                        precision={3}
                                                        label=""
                                                        onNumberChange={setSurfaceHerbe}
                                                        value={surfaceHerbe}
                                                        inputProps={{ style: { padding: "6px", marginLeft: "5px" } }}
                                                        disabled={true}
                                                    //errorMsg={((surfaceHerbe<=0)&&showError)?"Merci de saisir une valeur":""}
                                                    />
                                                </Grid>
                                                <Hidden xsDown><Grid item xs={6}></Grid></Hidden>
                                            </Grid>
                                        </>
                                    )}

                                    {(eleveur) && (
                                        <>
                                            <Grid container>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Quels type d'animaux sont présents sur votre exploitation ?"}</Typography>
                                                </Grid>
                                                {showError && !(bovin || ovin || porcin || autreEleveur) &&
                                                    (
                                                        <>
                                                            <Grid item xs={3} sm={3}></Grid>
                                                            <Grid item xs={9} sm={9}>
                                                                <Typography variant="subtitle2" color="error">{"Merci de sélectionner au moins une réponse"}</Typography>
                                                            </Grid>
                                                        </>
                                                    )}
                                                <Grid item xs={3}></Grid>
                                                <Grid item xs={9} sm={9}>
                                                    <FormControlLabel control={<Checkbox
                                                        color="primary"
                                                        checked={bovin}
                                                        disabled={true}
                                                        onChange={onToggleBovin}
                                                    />}
                                                        label="Bovin" />
                                                </Grid>
                                                <Grid item xs={3}></Grid>
                                                <Grid item xs={9} sm={9}>
                                                    <FormControlLabel control={<Checkbox
                                                        color="primary"
                                                        checked={ovin}
                                                        disabled={true}
                                                        onChange={onToggleOvin}
                                                    />}
                                                        label="Ovin" />
                                                </Grid>
                                                <Grid item xs={3}></Grid>
                                                <Grid item xs={9} sm={9}>
                                                    <FormControlLabel control={<Checkbox
                                                        color="primary"
                                                        checked={porcin}
                                                        disabled={true}
                                                        onChange={onTogglePorcin}
                                                    />}
                                                        label="Porcin" />
                                                </Grid>
                                                <Grid item xs={3}></Grid>
                                                <Grid item xs={3} sm={2}>
                                                    <FormControlLabel control={<Checkbox
                                                        color="primary"
                                                        checked={autreEleveur}
                                                        disabled={true}
                                                        onChange={onToggleAutreEleveur}
                                                    />}
                                                        label="Autre" />
                                                </Grid>
                                                <Grid item xs={6} sm={7}>
                                                    <MyTextField
                                                        fullWidth
                                                        margin="normal"
                                                        label=""
                                                        value={repAutreEleveur}
                                                        disabled={true}
                                                        onChange={handleRepAutreEleveur}
                                                        inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                                                        errorMsg={((eleveur && autreEleveur && (repAutreEleveur === "" || repAutreEleveur === null)) && showError) ? "Merci de préciser" : ""}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {(bovin) && (
                                                <>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={3} >
                                                            <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Quel est le nombre de bovins de mon exploitation :"}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <MyNumberTextField
                                                                min={0}
                                                                precision={0}
                                                                label=""
                                                                onNumberChange={setNbBovin}
                                                                value={nbBovin}
                                                                disabled={true}
                                                                inputProps={{ style: { padding: "6px", marginLeft: "5px" } }}
                                                                errorMsg={((eleveur && bovin && nbBovin <= 0) && showError) ? "Merci de saisir un nombre" : ""}
                                                            />
                                                        </Grid>
                                                        <Hidden xsDown><Grid item xs={6}></Grid></Hidden>
                                                    </Grid>
                                                </>
                                            )}
                                            {(ovin) && (
                                                <>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={3} >
                                                            <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Quel est le nombre d' ovins de mon exploitation :"}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <MyNumberTextField
                                                                min={0}
                                                                precision={0}
                                                                label=""
                                                                onNumberChange={setNbOvin}
                                                                value={nbOvin}
                                                                disabled={true}
                                                                inputProps={{ style: { padding: "6px", marginLeft: "5px" } }}
                                                                errorMsg={((eleveur && ovin && nbOvin <= 0) && showError) ? "Merci de saisir un nombre" : ""}
                                                            />
                                                        </Grid>
                                                        <Hidden xsDown><Grid item xs={6}></Grid></Hidden>
                                                    </Grid>
                                                </>
                                            )}
                                            {(porcin) && (
                                                <>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={3} >
                                                            <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Quel est le nombre de porcins de mon exploitation : "}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <MyNumberTextField
                                                                min={0}
                                                                precision={0}
                                                                label=""
                                                                onNumberChange={setNbPorcin}
                                                                value={nbPorcin}
                                                                disabled={true}
                                                                inputProps={{ style: { padding: "6px", marginLeft: "5px" } }}
                                                                errorMsg={((eleveur && porcin && nbPorcin <= 0) && showError) ? "Merci de saisir un nombre" : ""}
                                                            />
                                                        </Grid>
                                                        <Hidden xsDown><Grid item xs={6}></Grid></Hidden>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Paper>
                    <Paper className={paper}>
                        {(user.proprietes.URL_CGP && !isEmptyVal(user.proprietes.URL_CGP.valeur) ) && (
                                <>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} >
                                            <Typography variant="h6" style={{marginBottom:"0px"}} color="textPrimary" gutterBottom align="left" dangerouslySetInnerHTML={{ __html: messageGCP }}></Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={3}>
                    {user.proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE" && (
                        <>
                            <Paper className={paper}>
                                <Grid container>
                                    <Grid item xs={12}><Typography variant="h3" style={{ marginBottom: "5px" }}>{"Mon avatar"}</Typography></Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container alignContent="center" justify="center" alignItems="center" >
                                            <div className={donutParticipation} style={{ background: backgroundDonut }}>
                                                <img className={imgAvatar} src={genre === 'H' ? AvatarPanelHomme : AvatarPanelFemme} alt="Avatar" />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}><Typography variant="h4" align='center' style={{ marginTop: "5px" }}>{"Taux de participation : " + tauxParticipation + "%"}</Typography></Grid>
                                </Grid>
                            </Paper>
                        </>
                    )}

                    {user.proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE" && infoUsersEnquete && (
                        <>
                            <Paper className={paper}>
                                <Grid container>
                                    <Grid item xs={12}><Typography variant="h3" align='center' style={{ marginBottom: "5px" }}>{"Au "+formattedToday +", vous avez acquis "+ infoUsersEnquete.nombreEpis +" épis."}</Typography></Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container alignContent="center" justify="center" alignItems="center" >
                                            <img src={epis} width="150px" height="150px"/>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}><Typography variant="h6" align='center' style={{ marginTop: "5px" }}>{"Chaque enquête validée vous permet de cumuler des Epis. Si vous avez répondu à plus de 50% des enquêtes qui vous sont proposées, vous pourrez transformer vos épis en bons d'achat au 30 juin."}</Typography></Grid>
                                </Grid>
                            </Paper>
                        </>
                    )}
                </Grid>
            </Grid>
        </PageTemplate >
    );
}


export default MonComptePage;

