import { createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import { User } from '../users/usersTypes';

export interface AuthState {
  logged: boolean;
  user: User;
  userAs: User;
  contexte: string;
}

const initialState: AuthState = {
  logged: false,
  user: null,
  userAs: null,
  contexte: null
}

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: state => {
      Object.assign(state, initialState);
    },
    authLogin: (state, action: PayloadAction<User>) => {
      state.logged = true
      state.user = action.payload
      state.userAs = null
      state.contexte = state.user.proprietes.TYPE_UTILISATEUR?.valeur
    },
    authLogout: state => {
      state.logged = false 
      state.user = null
      state.userAs = null
      state.contexte = null
    },
    authLoginAs: (state, action: PayloadAction<User>) => {
      state.userAs = action.payload
    },
    authLogoutAs: state => {
      state.userAs = null
    },
    setContexte: (state, action: PayloadAction<string>) => {
      state.contexte = action.payload
    }
  }
})

export const { authLogin, resetAuthState, authLogout, authLoginAs, authLogoutAs, setContexte } = auth.actions;

export default auth.reducer;