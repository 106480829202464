import { createStyles, InputAdornment, makeStyles, TextField, TextFieldProps, Typography, MenuItem } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';
import NumberFormat from 'react-number-format';
import { KeyPressHandler } from '../utils/types';

const useStyles = makeStyles(theme =>
  createStyles({
    success: {
      color: theme.palette.success.main,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.success.main
      },
      "& .MuiInputLabel-root": {
        color: theme.palette.success.main
      },
      "& .MuiInputAdornment-root": {
        color: theme.palette.success.main
      }
    }
  })
);

export type MyTextFieldProps = TextFieldProps & {
  status?: "error" | "success";
  errorMsg?: string;
  onPressEnter?: () => void;
}

const MyTextField = (props: MyTextFieldProps) => {
  const { status, errorMsg, InputProps, onPressEnter, className, ...textFieldProps } = props;
  const { success } = useStyles();

  const adornments = status ? {
    endAdornment: <InputAdornment position="end">{status === "error" ? <ClearIcon /> : <CheckIcon />}</InputAdornment>,
  } : null;

  const onKeyPress: KeyPressHandler = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      onPressEnter();
    }
  }

  return (
    <>
      <TextField
        {...textFieldProps}
        className={clsx(className, status === "success" ? success : null)}
        variant="outlined"
        color={"primary"}
        fullWidth
        error={status === "error"}
        InputProps={{ ...InputProps, ...adornments }}
        onKeyPress={onKeyPress}
      ></TextField>
      {errorMsg && <Typography variant="subtitle2" style={{paddingBottom:"6px"}} color="error">{errorMsg}</Typography>}
    </>
  );
}

export default MyTextField;

export const MySelectField = (props: MyTextFieldProps & { emptyItem?: boolean }) => {
  const { emptyItem, children, ...otherProps } = props;
  return (
    <MyTextField
      {...otherProps}
      select={true}
      SelectProps={{IconComponent: ExpandMoreIcon}}
    >
      {emptyItem && <MenuItem value=""><em>&nbsp;</em></MenuItem>}
      {children}
    </MyTextField>
  );
}

export type CustomNumberFormatProps = MyTextFieldProps & {
  precision?: number;
  onNumberChange?: (val: number) => void;
  onNumberChangeIndex?: (val: number,index: number) => void;
  min?: number;
  max?: number;
  multiple?: number;
  indexTab?: number;
}

export const isValidNumberValue = (val: number | "", min?: number, max?: number, multiple?: number) => {
  return val == undefined || val === "" || ((min == undefined || val >= min) && (max == undefined || val <= max) && (multiple == undefined || multiple === 0 || val % multiple === 0));
}

export const MyNumberTextField = (props: CustomNumberFormatProps) => {
  const { onNumberChangeIndex,onNumberChange, precision = 0, min, max,indexTab, multiple, errorMsg, inputProps, ...otherProps } = props;
  const { style,value, label, disabled, status = undefined } = otherProps;

  const myTextFieldProps = {
    label,
    disabled,
    status: !isValidNumberValue(value as (number | ""), min, max, multiple) ? "error" : status
  }

  return (
    <>
      <NumberFormat
        value={value as number}
        {...myTextFieldProps}
        onValueChange={(values) => {
          const { floatValue } = values;
          if(indexTab!=null){
            onNumberChangeIndex(floatValue,indexTab);
          }else{
            onNumberChange(floatValue);
          }
        }}
        customInput={MyTextField}
        thousandSeparator=" "
        decimalSeparator=","
        decimalScale={precision}
        fixedDecimalScale={true}
        displayType="input"
        isNumericString
        prefix=""
        allowedDecimalSeparators={[".", ","]}
        inputProps={inputProps}
        style={style}
      />
      {errorMsg && <Typography variant="subtitle2" color="error">{errorMsg}</Typography>}
    </>

  );
}