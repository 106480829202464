import React, { useState,useEffect } from 'react';
import { Box, Grid,  Typography } from '@material-ui/core';
import PageTemplate from '../../page/PageTemplate';
import { NavElement} from '../../utils/types';
import { useMobileMode } from '../../utils/utils';
import colors from "../../style/colors";
import {Enquete} from "./EnqueteType";
import {fetchActusTdbPaneliste} from "./PublicationApi";
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../auth/authApi';
import { useGlobalStyles } from '../../style/globalStyles';
import clsx from 'clsx';
import BlocEnqueteSlideAccueil from "./EnqueteSlideAccueil";
import BlocEnqueteAccueilAucune from '../enquete/EnqueteAccueilAucune';

const NAV_ELEMENTS: NavElement[] = [{ name: "Les Actus", path: "/LesActus" }];


const ActuPanelistePage = () => {

    const mobileMode = useMobileMode();
    const dispatch = useDispatch();
    const user = useCurrentUser();
    const globalClasses = useGlobalStyles();

    const {margeContainer,marginBottom } = globalClasses;

    const [actusPanel, setActusPanel] = useState<Enquete[]>([]);

    useEffect(() => {
        function intiPublication(data: Enquete[]){
          if(data){
            setActusPanel(data);
          }else{
            setActusPanel([]);
          }
        }
        //TODO recupérer les actus
        dispatch(fetchActusTdbPaneliste(user.userConvention.idUsersConvention,intiPublication));
      }, []);

    return (
        <PageTemplate  elements={NAV_ELEMENTS}>
            <Grid container spacing={mobileMode ? 0 : 10}>
                <Grid item xs={12} sm={9}>
                    <Grid container spacing={mobileMode ? 0 : 10}>
                        <Grid item xs={12}><Typography variant="h3">Mes actualités :</Typography></Grid>
                    </Grid>
                    <Grid container spacing={mobileMode ? 0 : 10}>
                        <Grid item xs={12}>
                            <Typography variant="h3" dangerouslySetInnerHTML={{ __html: "<font color='"+colors.vert+"'>Mes actus</font>" }}  align="center" >
                            </Typography>
                            <Box className={clsx(margeContainer, marginBottom)}>
                                {actusPanel&&actusPanel.length>0&&(<BlocEnqueteSlideAccueil  enquetes={actusPanel}  type="ACTUALITE" origine="TDBOUVERTE" />)}
                                {actusPanel === null || actusPanel.length ===  0&&(
                                  <BlocEnqueteAccueilAucune type="ACTU" origine="TDBOUVERTE"/>    
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> 
        </PageTemplate >
      );
    }
    

    export default ActuPanelistePage;
