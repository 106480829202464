/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStyles, makeStyles, Paper } from "@material-ui/core";
import clsx from "clsx";
import { PositionProperty } from "csstype";
import * as React from "react";

const useStyles = makeStyles(theme =>
  createStyles({
    /* Styles applied to the root element. */
    root: {
      display: "flex",
      justifyContent: "center",
      padding: 0
    },
    /* Styles applied to the root element if `position="bottom"`. */
    positionBottom: {
      position: "fixed" as PositionProperty,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.mobileStepper,
    },
    /* Styles applied to the root element if `position="top"`. */
    positionTop: {
      position: "fixed" as PositionProperty,
      top: 0,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.mobileStepper,
    },
    /* Styles applied to the root element if `position="static"`. */
    positionStatic: {},
    /* Styles applied to the dots container if `variant="dots"`. */
    dots: {
      display: "flex",
      margin: "10px 0"
    },
    /* Styles applied to each dot if `variant="dots"`. */
    dot: {
      backgroundColor: theme.palette.action.disabled,
      borderRadius: "50%",
      width: 8,
      height: 8,
      margin: "0 5px",
      cursor: "pointer",
      "&:focus": {
        outline: "none"
      }
    },
    /* Styles applied to a dot if `variant="dots"` and this is the active step. */
    dotActive: {
      backgroundColor: theme.palette.primary.main,
    },
    /* Styles applied to the Linear Progress component if `variant="progress"`. */
    progress: {
      width: "50%",
    },
  }));

interface ClickableDotMobileStepperProps {
  /**
   * Set the active step (zero based index).
   * Defines which dot is highlighted when the variant is "dots".
   */
  activeStep: number;
  /**
   * A back button element. For instance, it can be be a `Button` or a `IconButton`.
   */
  backButton?: React.ReactNode;
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes?: any;
  /**
   * @ignore
   */
  className?: string;
  /**
   * A next button element. For instance, it can be be a `Button` or a `IconButton`.
   */
  nextButton?: React.ReactNode;
  /**
   * Set the function to call when a dot is clicked on.
   */
  onDotClick: (i: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  /**
   * Set the positioning type.
   */
  position: "bottom" | "top" | "static";
  /**
   * The total steps.
   */
  steps: number;
}

interface ClickableDotProps {
  classes: any;
  step: number;
  active: boolean;
  onDotClick: (i: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ClickableDot = (props: ClickableDotProps) => {
  const { classes, step, active, onDotClick } = props;
  const { dot, dotActive } = useStyles();
  const dotClassName = clsx(dot, classes?.dot, {
    [dotActive]: active, [classes?.dotActive]: active
  });
  return (
    <div
      tabIndex={step}
      className={dotClassName}
      onClick={onDotClick(step)}
    />
  );
};

const ClickableDotMobileStepper = (props: ClickableDotMobileStepperProps) => {
  const {
    activeStep,
    backButton,
    className: classNameProp,
    nextButton,
    onDotClick,
    steps,
    classes,
    ...other
  } = props;

  const { dots: dotsClass, root } = useStyles();
  const stepsTab: any[] = steps >= 0 ? [...Array(steps)] : null;

  const dots = stepsTab?.map((_, step) => {
    return <ClickableDot key={step} active={step === activeStep % steps} classes={classes} onDotClick={onDotClick} step={step} />;
  });

  const dotsContainer = (
    <div className={clsx(dotsClass, classes?.dots)}>
      {dots}
    </div>
  );

  return (
    <Paper square elevation={0} className={clsx(root, classNameProp)} {...other}>
      {backButton}
      {dotsContainer}
      {nextButton}
    </Paper>
  );
};

export default ClickableDotMobileStepper;
