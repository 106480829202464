import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Paper, Grid, Button, TextareaAutosize, MenuItem, FormControlLabel, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { EnqueteEdit, PublicationTBD, PanelisteRechFichePublication, SelectionPanelisteFile } from "./EnqueteType";
import MyTextField, { MyNumberTextField, MySelectField } from '../../components/MyTextField';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { fr } from "date-fns/locale";
import { useDispatch } from 'react-redux';
import { fetchEnqueteToEdit, fetchListPanneliste, fetchListPannelisteWithFile, fetchListTag } from './PublicationApi';
import { TablePanelisteToSelect } from "./MaterialTablePanelisteToSelect";
import { ChangeHandler, ChangeHandlerTextArea } from '../../utils/types';
import colors from '../../style/colors';
import { ArrowLeft } from '@material-ui/icons';
import { useCurrentUser } from '../auth/authApi';
import { TableTagToSelect } from './MaterialTableTagToSelect';
import { Tag } from '../users/MonCompteType';
import { dateRemoveDays } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10
  },
  selectField: {
    paddingLeft: "6px"
  }, tablePanel: {
    paddingBottom: "20px"
  }, blockBouton: {
    padding: "25px"
  },
  marginTop: {
    marginTop: "10px"
  }
})
)

export interface EnqueteParamProps {
  // SI ENQUETE RENSEIGNEE FICHE EN MODE MODIFICATION SINON MODE CREATION
  publication: PublicationTBD;
  typePublication: string;
  fctEnregistrer: (enquete: EnqueteEdit, imageFile: File, saveImage: boolean) => void;
  fctAnnuler: () => void;
  enregistrementEncours: boolean;
}

const EnqueteParam = (props: EnqueteParamProps) => {
  const dispatch = useDispatch();

  const user = useCurrentUser();

  const { publication, fctAnnuler, fctEnregistrer, typePublication, enregistrementEncours } = props;
  const { paper, selectField, tablePanel, blockBouton, marginTop } = useStyles();

  {/* VARIABLE POUR AFFECTATION PANELISTE A L'ENQUETE */ }
  const [panelistes, setPanelistes] = useState<PanelisteRechFichePublication[]>([]);
  const [panelistesSelected, setPanelistesSelected] = useState<number[]>([]);
  const [panelistesT, setPanelistesT] = useState<number[]>([]);
  const [alerteDeletePaneliste, setAlerteDeletePaneliste] = useState<boolean>(false);
  const [panelistesToDelete, setPanelistesToDelete] = useState<PanelisteRechFichePublication[]>([]);
  {/* GESTION FICHIER EXCEL LISTE CODE TIERS POUR AUTO SELECTION PANELISTE*/ }
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFilePicked, setIsFilePicked] = useState<boolean>(false);
  const [panelistesRejete, setPanelistesRejete] = useState<string[]>([]);
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);

  const [enqueteEnCours, setEnqueteEnCours] = useState<EnqueteEdit>(() => {

    const enq: EnqueteEdit = {
      id: 0,
      titre: "",
      description: "",
      type: typePublication,
      provider: "",
      urlProvider: "",
      dateDebut: new Date().toLocaleDateString('en-US'),
      dateFin: new Date().toLocaleDateString('en-US'),
      listePaneliste: [],
      affectationAuto: false,
      nombreEpis: 50,
      tags: []
    }
    return enq
  });

  //IMAGE
  const [selectedImageFile, setSelectedImageFile] = useState<File>();
  const [preview, setPreview] = useState<string>();
  const [isImagePicked, setIsImagePicked] = useState<boolean>(false);

  {/* TAGS */ }
  const [tagSelected, setTagSelected] = useState<Tag[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);

  const [showError, setShowError] = useState<boolean>(false);
  const validForm = enqueteEnCours !== null && enqueteEnCours.titre.trim() !== "" && enqueteEnCours.description.trim() !== ""
    && enqueteEnCours.provider.trim() !== "" && enqueteEnCours.urlProvider.trim() !== ""
    && enqueteEnCours.dateDebut.trim() !== "" && enqueteEnCours.dateFin.trim() !== ""
    && (panelistesSelected.length > 0 || (enqueteEnCours.affectationAuto && !(panelistesSelected.length > 0)));

  const handleChange: ChangeHandler = e => {
    const target = e.target
    setEnqueteEnCours((prevState) => ({
      ...prevState, [target.name]: target.value
    }))
  }

  function changeDuree(nb: number) {
    setEnqueteEnCours((prevState) => ({
      ...prevState, ["duree"]: nb
    }))
  }
  function changeNombreEpis(nb: number) {
    setEnqueteEnCours((prevState) => ({
      ...prevState, ["nombreEpis"]: nb
    }))
  }


  const handleChangeTextArea: ChangeHandlerTextArea = e => {
    const target = e.target

    setEnqueteEnCours((prevState) => ({
      ...prevState, [target.name]: target.value
    }))
  }

  const handleChangeCheckBoxActif: ChangeHandler = e => {
    const target = e.target
    let value = 'I'

    if (target.checked) {
      value = '*'
    }

    setEnqueteEnCours((prevState) => ({
      ...prevState, [target.name]: value
    }))
  }

  const handleChangeCheckBoxAffAuto: ChangeHandler = e => {
    const target = e.target
    setEnqueteEnCours((prevState) => ({
      ...prevState, [target.name]: target.checked
    }))
  }

  useEffect(() => {
    const isAdmin = user.isAdmin;
    setUserIsAdmin(isAdmin)
  }, [user]);

  useEffect(() => {

    const tmpPanelSelected = panelistesSelected;
    for (const panel of panelistesT) {
      if (!tmpPanelSelected.includes(panel)) {
        tmpPanelSelected.push(panel)
      }
    }

    setPanelistesSelected(tmpPanelSelected)

  }, [panelistesSelected]);

  const setFile: ChangeHandler = e => {
    setSelectedFile(e.target.files[0]);
    setIsFilePicked(true);
  }

  useEffect(() => {
    {/* FICHE ENQUETE SI ENQUETESEL==NULL FICHE EN MODE CREATION SINON FICHE EN MODIFICATION */ }

    function initPaneliste(data: PanelisteRechFichePublication[]) {
      if (data) {
        setPanelistes(data);
      } else {
        setPanelistes([]);
      }
    }

    function initTag(data: Tag[]) {
      if (data) {
        setTags(data);
      } else {
        setTags([]);
      }
    }

    function initEnquete(enquete: EnqueteEdit) {
      setEnqueteEnCours(enquete);
      setPanelistesSelected(enquete.listePaneliste);
      setPanelistesT(enquete.listePanelisteT);
      setTagSelected(enquete.tags)
    }

    //RECUPERATION LISTE PANELISTE
    dispatch(fetchListPanneliste(publication ? publication.id : 0, initPaneliste));

    //RECUPERATION LISTE TAG
    dispatch(fetchListTag(initTag));

    if (publication) {
      dispatch(fetchEnqueteToEdit(publication.id, initEnquete));
    }

  }, []);

  // GESTION SELECTION PANALISTE DANS TABLEAU
  function selectPaneliste(paneliste: PanelisteRechFichePublication) {
    const tmpPanelSelected = [];
    let find = false;
    for (const idUsersConvention of panelistesSelected) {
      if (paneliste.idUsersConvention == idUsersConvention) {
        if (!panelistesT.includes(paneliste.idUsersConvention)) {
          find = true;
        }
      } else {
        tmpPanelSelected.push(idUsersConvention);
      }
    }
    if (!find) {
      tmpPanelSelected.push(paneliste.idUsersConvention);
    }
    setPanelistesSelected(tmpPanelSelected);
  }

  // GESTION SELECTION TAG DANS TABLEAU
  function selectTag(tagParam: Tag) {
    const tmpTagSelected: Tag[] = [];
    let find = false;
    for (const tag of tagSelected) {
      if (tagParam.code === tag.code) {
        find = true;
      } else {
        tmpTagSelected.push(tag);
      }
    }
    if (!find) {
      tmpTagSelected.push(tagParam);
    }
    setTagSelected(tmpTagSelected);
  }

  function selectAllPaneliste() {
    const tmpPanelSelected = [];
    for (const panel of panelistes) {
      tmpPanelSelected.push(panel.idUsersConvention);

    }
    setPanelistesSelected(tmpPanelSelected);
  }

  function deSelectAllPaneliste() {
    setPanelistesSelected([]);
  }

  function deSelectAllTag() {
    setTagSelected([]);
  }

  function selectAllTag() {
    const tmpTagSelected: Tag[] = [];
    for (const tag of tags) {
      tmpTagSelected.push(tag);
    }
    setTagSelected(tmpTagSelected);
  }
  

  // CHARGER LE FICHIER 
  function uploadedFile() {
    function initFile(data: SelectionPanelisteFile) {
      if (data && data.idConventionsTrouves) {
        setPanelistesSelected(data.idConventionsTrouves);
        setPanelistesRejete(data.codeTiersRejetes)
      } else {
        setPanelistesSelected([]);
      }
    }
    if (selectedFile && isFilePicked) {
      dispatch(fetchListPannelisteWithFile(selectedFile, initFile));
    }
  }

  function clearFile() {
    setIsFilePicked(false);
  }

  function dateIsOk() {
    const dateDebut = new Date(enqueteEnCours.dateDebut)
    const dateFin = new Date(enqueteEnCours.dateFin)
    if (dateFin < dateDebut) {
      return false
    } else {
      return true
    }
  }

  function imageIsOk() {
    if (publication && enqueteEnCours.urlImage && enqueteEnCours.urlImage.trim() !== "") {
      return true;
    } else {
      return isImagePicked
    }
  }

  function enregistrement(forceEnregistrement = false) {
    if (validForm && dateIsOk() && imageIsOk()) {
      if (publication && !forceEnregistrement) {
        const tmpAlerteListe: PanelisteRechFichePublication[] = []
        for (const panel of enqueteEnCours.listePaneliste) {
          if (!panelistesSelected.includes(panel)) {
            const result = panelistes.find(obj => obj.idUsersConvention == panel)
            tmpAlerteListe.push(result);
          }
        }
        if (tmpAlerteListe.length > 0) {
          setPanelistesToDelete(tmpAlerteListe);
          setAlerteDeletePaneliste(true);
          return;
        }
      }
      const enqueteToSave = enqueteEnCours;

      enqueteToSave.listePaneliste = panelistesSelected;
      enqueteToSave.tags = tagSelected

      if (publication && !isImagePicked) {
        fctEnregistrer(enqueteToSave, selectedImageFile, false)
      } else {
        fctEnregistrer(enqueteToSave, selectedImageFile, true)
      }
    } else {
      setShowError(true)
    }

  }

  function confirmEnregistrement() {
    enregistrement(true);
    setAlerteDeletePaneliste(false);
  }

  function AnnulEnregistrement() {
    setAlerteDeletePaneliste(false);
  }

  //IMAGE

  const setImage: ChangeHandler = e => {
    setSelectedImageFile(e.target.files[0]);
    setIsImagePicked(true);
  }

  //PERMET DE FAIRE UNE PREVIEW DE L'IMAGE CHARGER
  useEffect(() => {
    if (selectedImageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedImageFile);
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
    }

  }, [selectedImageFile]);

  return (
    <>
      <Button variant="text" color="primary" disableElevation startIcon={<ArrowLeft htmlColor="#A0A0A0" fontSize="large" />} size="large" onClick={fctAnnuler}>
        {"Retour au tableau de bord"}
      </Button>
      <Paper className={paper}>
        {typePublication == "ENQUETE" && (
          <Typography variant="h3" gutterBottom >{publication ? "Modification de l'enquête n°" + publication.id : "Création enquête"}</Typography>
        )}
        {typePublication == "ACTUALITE" && (
          <Typography variant="h3" gutterBottom >{publication ? "Modification de l'actualité n°" + publication.id : "Création actualité"}</Typography>
        )}
        <Grid container>
          <Grid item xs={3} sm={3}>
            <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Titre"}</Typography>
          </Grid>
          <Grid item xs={9} sm={9}>
            <MyTextField fullWidth margin="normal" label="" name="titre" disabled={!userIsAdmin} value={enqueteEnCours.titre} onChange={handleChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
              errorMsg={((enqueteEnCours.titre.trim() === "" || enqueteEnCours.titre === null || enqueteEnCours.titre === undefined) && showError) ? "Merci de saisir un titre" : ""} />
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography variant="h6" color="textPrimary" gutterBottom align="left">{"Déscription"}</Typography>
          </Grid>
          <Grid item xs={9} sm={9}>
            <TextareaAutosize
              rowsMin={5}
              style={{ width: "100%", margin: "5px" }}
              name="description"
              disabled={!userIsAdmin}
              value={enqueteEnCours.description}
              onChange={handleChangeTextArea}
            />

            {((enqueteEnCours.description.trim() === "" || enqueteEnCours.description === null || enqueteEnCours.description === undefined) && showError) &&
              <Typography variant="h6" color="error" gutterBottom align="left" >{"Merci de saisir une description"}</Typography>
            }
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Provider"}</Typography>
          </Grid>
          <Grid item xs={9} sm={9}>
            <MySelectField
              value={enqueteEnCours.provider}
              disabled={!userIsAdmin}
              errorMsg={((enqueteEnCours.provider.trim() === "" || enqueteEnCours.provider === null || enqueteEnCours.provider === undefined) && showError) ? "Merci de sélectionner un provider" : ""}
              style={{ margin: "5px" }}
              name="provider"
              onChange={handleChange}
              size="small"
              InputProps={{
                classes: {
                  input: selectField
                }
              }}
            >
              <MenuItem key={"SENDUP"} value={"SENDUP"}>{"SENDUP"}</MenuItem>
            </MySelectField>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"URL Provider"}</Typography>
          </Grid>
          <Grid item xs={9} sm={9}>
            <MyTextField fullWidth margin="normal" label="" name="urlProvider" disabled={!userIsAdmin} value={enqueteEnCours.urlProvider} onChange={handleChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
              errorMsg={((enqueteEnCours.urlProvider.trim() === "" || enqueteEnCours.urlProvider === null || enqueteEnCours.urlProvider === undefined) && showError) ? "Merci de saisir l'URL" : ""} />
          </Grid>
          {!dateIsOk() && (
            <>
              <Grid item xs={3} sm={3}></Grid>
              <Grid item xs={9} sm={9}>
                <Typography variant="h6" color="error" gutterBottom align="left" >{"DATE DE FIN AVANT LA DATE DE DEBUT ! "}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={3} sm={3}>
            <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Date de début"}</Typography>
          </Grid>
          <Grid item xs={9} sm={9}>
            <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils} >
              <DatePicker
                autoOk
                format="dd/MM/yyyy"
                value={enqueteEnCours.dateDebut}
                disabled={!userIsAdmin}
                name="dateDebut"
                onChange={(date) => {
                  const dateString = new Date(date).toLocaleDateString('en-US')
                  setEnqueteEnCours((prevState) => ({
                    ...prevState, ["dateDebut"]: dateString
                  }))
                }}
                animateYearScrolling
                variant="inline"
                inputVariant="outlined"
                inputProps={{ style: { padding: "6px" } }}
                style={{ margin: "5px" }}
                invalidDateMessage={"Format invalide, le format doit être JJ/MM/AAAA"}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Date de fin"}</Typography>
          </Grid>
          <Grid item xs={9} sm={9}>
            <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
              <DatePicker
                autoOk
                format="dd/MM/yyyy"
                name="dateFin"
                value={enqueteEnCours.dateFin}
                disabled={!userIsAdmin}
                onChange={(date) => {
                  const dateString = new Date(date).toLocaleDateString('en-US')
                  setEnqueteEnCours((prevState) => ({
                    ...prevState, ["dateFin"]: dateString
                  }))
                }}
                animateYearScrolling
                variant="inline"
                inputVariant="outlined"
                inputProps={{ style: { padding: "6px" } }}
                style={{ margin: "5px" }}
                invalidDateMessage={"Format invalide, le format doit être JJ/MM/AAAA"}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={3} sm={3}>
          </Grid>
          <Grid item xs={9} sm={9}>
          {enqueteEnCours?.dateFin.trim() !== "" && enqueteEnCours.type === "ENQUETE"  && (
            <Typography color="textPrimary" gutterBottom align="left" >{"le panéliste pourra répondre à l'enquête jusqu'au " + dateRemoveDays(new Date(enqueteEnCours.dateFin), 1) + " 23h59"}</Typography>
          )}
            
          </Grid>
          {typePublication === "ENQUETE" &&
            (
              <>
                <Grid item xs={3} sm={3}>
                  <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Durée"}</Typography>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <MyNumberTextField
                    fullWidth
                    margin="normal"
                    label=""
                    name="duree"
                    disabled={!userIsAdmin}
                    value={enqueteEnCours.duree}
                    onNumberChange={changeDuree}
                    inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                    precision={0}
                  />
                </Grid>
                <Grid item xs={4} sm={5}></Grid>
                <Grid item xs={3} sm={3}>
                  <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Nombre d'Epis"}</Typography>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <MyNumberTextField
                    fullWidth
                    margin="normal"
                    label=""
                    name="nombreEpis"
                    disabled={!userIsAdmin}
                    value={enqueteEnCours.nombreEpis}
                    onNumberChange={changeNombreEpis}
                    inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                    precision={0}
                  />
                </Grid>
                <Grid item xs={4} sm={5}></Grid>
              </>
            )}

          <Grid item xs={3} sm={3}>
            <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{" "}</Typography>
          </Grid>
          <Grid item xs={9} sm={9}>
            <FormControlLabel control={
              <Checkbox
                color="primary"
                name="affectationAuto"
                disabled={!userIsAdmin}
                checked={enqueteEnCours.affectationAuto}
                onChange={handleChangeCheckBoxAffAuto} />}
              label={"Affectation automatique aux nouveaux panélistes"}
            />
          </Grid>
          {publication && (
            <>
              <Grid item xs={3} sm={3}>
                <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Statut"}</Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControlLabel control={
                  <Checkbox
                    color="primary"
                    name="statutCode"
                    disabled={!userIsAdmin}
                    checked={enqueteEnCours.statutCode === '*'}
                    onChange={handleChangeCheckBoxActif} />}
                  label={"Actif"}
                />
              </Grid>
            </>)}
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" color="textPrimary" gutterBottom align="left" >{"Sélection de l'image"}</Typography>
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
          <Grid item xs={9} sm={9} >
            <img src={preview ? preview : publication ? enqueteEnCours.urlImage : ""} height="157px" width="280px" />
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
          <Grid item xs={9} sm={9}>
            <input type="file" disabled={!userIsAdmin} id="image" accept="image/png, image/jpeg" onChange={setImage} />
            {!imageIsOk() && showError &&
              <Typography variant="h6" color="error" gutterBottom align="left" >{"Merci de sélectionner une image"}</Typography>
            }
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" color="textPrimary" gutterBottom align="left" >{"Sélection des Panélistes"}</Typography>
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
          <Grid item xs={9} sm={9}>
            <button onClick={selectAllPaneliste} disabled={!userIsAdmin} style={{ marginRight: "5px", backgroundColor: colors.bleu, color: colors.blanc }}>Tout sélectionner</button>
            <button onClick={deSelectAllPaneliste} disabled={!userIsAdmin} style={{ marginRight: "5px", backgroundColor: colors.jaune, color: colors.blanc }}>Tout désélectionner</button>
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
          <Grid item xs={9} sm={9}>
            <Grid container spacing={10} className={tablePanel}>
              <Grid item xs={12} sm={6}>
                {!isFilePicked &&
                  (
                    <>
                      <input type="file" disabled={!userIsAdmin} style={{ marginTop: "15px" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={setFile} />
                    </>
                  )}
                {isFilePicked &&
                  (
                    <>
                      <button onClick={uploadedFile} disabled={!userIsAdmin} style={{ marginTop: "15px", marginRight: "5px", backgroundColor: colors.vert, color: colors.blanc }}>Charger le fichier</button>
                      <button onClick={clearFile} disabled={!userIsAdmin} style={{ backgroundColor: colors.rouge, color: colors.blanc }}>Sélectionner un autre fichier</button>
                    </>
                  )}

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
          <Grid item xs={9} sm={9}>
            <Grid style={{ marginBottom: "15px" }}>
              <Typography variant="h4" component="h2">{"Nombre d'utilisateurs sélectionnés " + panelistesSelected.length + "/" + panelistes.length}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
          <Grid item xs={9} sm={9}>
            <Grid container spacing={10}>
              <TablePanelisteToSelect panelisteSelected={panelistesSelected} panelisteT={panelistesT} onSelect={selectPaneliste} values={panelistes} userIsAdmin={userIsAdmin}></TablePanelisteToSelect>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
          <Grid item xs={9} sm={9} className={marginTop}>
            {panelistesRejete.length > 0 && (
              <>
                <Grid>
                  <Typography color="error">{panelistesRejete.length > 1 ? "Code rejetés :" : "Code rejeté :"}</Typography>
                </Grid>
                {panelistesRejete.map(code =>
                  <>
                    <Grid>
                      <Typography color="error">- {code}</Typography>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        
        <Grid item xs={3} sm={3}></Grid>
        <Grid item xs={9} sm={9} className={marginTop}>
          {!(panelistesSelected.length > 0) && !enqueteEnCours.affectationAuto && showError &&
            <Typography variant="h6" color="error" gutterBottom align="left" >{"Merci de sélectionner des panelistes"}</Typography>
          }
        </Grid>
        {/* Selection des tags */}
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" color="textPrimary" gutterBottom align="left" >{"Selection des tags"}</Typography>
        </Grid>
        <Grid item xs={3} sm={3}></Grid>
        <Grid item xs={9} sm={9}>
          <button onClick={selectAllTag} disabled={!userIsAdmin} style={{ marginRight: "5px", backgroundColor: colors.bleu, color: colors.blanc }}>Tout sélectionner</button>
          <button onClick={deSelectAllTag} disabled={!userIsAdmin} style={{ marginRight: "5px", backgroundColor: colors.jaune, color: colors.blanc }}>Tout désélectionner</button>
        </Grid>
        <Grid item xs={3} sm={3}></Grid>
        <Grid item xs={9} sm={9} className={marginTop}>
          <Grid container spacing={10} className={marginTop}>
            <TableTagToSelect tagSelected={tagSelected} onSelect={selectTag} values={tags} userIsAdmin={userIsAdmin}></TableTagToSelect>
          </Grid>
        </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center" spacing={10} className={blockBouton}>
          <Grid item xs={12} sm={5} >
            <Button fullWidth size="small" variant="outlined" color="primary" onClick={fctAnnuler}>ANNULER</Button>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Button fullWidth size="small" disabled={(!validForm || !dateIsOk() || !imageIsOk()) && showError || !userIsAdmin || enregistrementEncours} variant="contained" color="primary" onClick={() => enregistrement()}>ENREGISTRER</Button>
          </Grid>
        </Grid>
      </Paper>
      {/*DIALOG*/}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={alerteDeletePaneliste}
      >
        <DialogContent>
          <DialogContentText color="initial">Attention vous allez supprimer les panelistes suivant de la publication :
            {panelistesToDelete.map(panel =>
              <Typography key={panel.codeTiers}>-{panel.codeTiers} : {panel.nom}</Typography>
            )}
            Confirmez-vous ce choix ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button key={"NON"} onClick={AnnulEnregistrement} color="secondary">NON</Button>
          <Button key={"OUI"} onClick={confirmEnregistrement} color="secondary">OUI</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EnqueteParam;
