import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { SizeContainer } from '../components/MyContainer';
import { useGlobalStyles } from '../style/globalStyles';
import { PATH } from '../utils/constants';
import Footer from './Footer';
import Header from './Header';
import AccueilPage from "../features/accueil/AccueilPage";
import Menu from '../features/menu/Menu';
import EnqueteFichePage from "../features/enquete/EnqueteFichePage";
import TagsPage from '../features/tags/TagsPage';
import EnqueteAdministrationPage from '../features/enquete/EnqueteAdministrationPage';
import EnquetePanelistePage from "../features/enquete/EnquetePanelistePage";
import MonComptePage from '../features/users/MonCompte';
import ActuAdministrationPage from '../features/enquete/ActuAdministrationPage';
import UsersAdminPage from '../features/usersAdmin/UsersAdminPage';
import ActuPanelistePage from '../features/enquete/ActuPanelistePage';
import { useContexte, useCurrentUser } from '../features/auth/authApi';
import MessageriePage from '../features/messagerie/MessageriePage';
import SujetsPage from '../features/sujets/SujetsPage';

const useStyles = makeStyles(theme => ({
  main: {
    margin: theme.spacing(10, 0),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(5, 0),
    }
  }
}));

const Layout = () => {
  const { fullHeight } = useGlobalStyles();
  const { main } = useStyles();
  const contexte = useContexte();
  const { isAdmin } = useCurrentUser();

  return (
    <>
      <Grid container direction="column" className={fullHeight} wrap="nowrap">
        <Grid item>
          <Header />
        </Grid>
        <Grid item xs>
          <main className={main}>
            <SizeContainer>
              <Switch>
                {/* POUR TOUT LE MONDE */}
                <Route path={PATH + "/Accueil"}><AccueilPage /></Route>
                <Route path={PATH + "/MonCompte"}><MonComptePage /></Route>
                <Route path={PATH + "/Messagerie"}><MessageriePage /></Route>

                {/* POUR LES UTILISATEURS */}
                {contexte !== "PANELISTE" && (
                  <>
                    <Route path={PATH + "/Tags"}><TagsPage /></Route>
                    <Route path={PATH + "/Enquetes"}><EnqueteAdministrationPage /></Route>
                    <Route path={PATH + "/Actualites"}><ActuAdministrationPage /></Route>
                    <Route path={PATH + "/Users"}><UsersAdminPage /></Route>
                    {/* POUR LES UTILISATEURS ADMINISTRATEURS */}
                    {isAdmin && (
                      <Route path={PATH + "/Sujets"}><SujetsPage /></Route>
                    )}
                  </>
                )}

                {/* POUR LES UTILISATEURS */}
                {contexte === "PANELISTE" && (
                  <>
                    <Route path={PATH + "/MesEnquetes"}><EnquetePanelistePage /></Route>
                    <Route path={PATH + "/LesActus"}><ActuPanelistePage /></Route>
                    <Route path={PATH + "/Enquetefiche"}><EnqueteFichePage /></Route>
                  </>
                )}
              </Switch>
            </SizeContainer>
          </main>
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
      <Menu />
    </ >
  );
}

export default Layout;