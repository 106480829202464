import { AppBar, Badge, Box, createStyles, Grid, IconButton, makeStyles, MenuItem, Toolbar, Typography } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { RootState } from '../app/rootReducer';
import { SizeContainer } from '../components/MyContainer';
import { SwitchIconButton } from '../components/SwitchImageButton';
import { fetchLogout, fetchLogoutAs, useContexte } from '../features/auth/authApi';
import images from '../img/images';
import colors from '../style/colors';
import { useGlobalStyles } from '../style/globalStyles';
import { PATH } from '../utils/constants';
import { useMobileMode } from '../utils/utils';
import { UserInfoCompteDto } from '../features/users/MonCompteType';
import { fetchInfoUserPaneliste } from '../features/users/usersApi';
import AvatarAdminHomme from "../img/avatar/avatar_admin_homme.png";
import AvatarAdminFemme from "../img/avatar/avatar_admin_femme.png";
import AvatarPanelHomme from "../img/avatar/avatar_paneliste_homme.jpg";
import AvatarPanelFemme from "../img/avatar/avatar_paneliste_femme.png";
import { MySelectField } from '../components/MyTextField';
import { UserType } from '../features/usersAdmin/UsersAdminType';
import { fetchUserType } from '../features/usersAdmin/UsersAdminApi';
import { ChangeHandler } from '../utils/types';
import { setContexte } from "../features/auth/authSlice";
import { Mail } from '@material-ui/icons';
import { getNotificationHeader } from '../features/messagerie/MessagerieApi';

const colorsDonut = {
  //[active, fade]
  greenColor: ["#11cd5c", "#c3f0b8"],
  redColor: ["#990000", "#FF4C4C"]
}

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      backgroundColor: colors.blanc,
      borderBottom: "1px solid " + colors.gris30
    },
    container: {
      padding: "5px 0"
    },
    link: {
      height: 60,
      display: "block"
    },
    title: {
      fontWeight: theme.typography.fontWeightRegular,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14
      }
    },
    subTitle: {
      fontWeight: theme.typography.fontWeightRegular,
      color: colors.gris20
    },
    fluxAgri: {
      backgroundColor: colors.gris30,
    },
    slider: {
      padding: "5px 0"
    },
    sliderBox: {
      height: 30
    },
    agriText: {
      fontWeight: theme.typography.fontWeightRegular,
      marginLeft: 10,
      marginRight: 100
    },
    logo: {
      [theme.breakpoints.down("xs")]: {
        maxWidth: 100,
        height: 60,
        objectFit: "contain"
      },
      [theme.breakpoints.up("sm")]: {
        height: "100%"
      }
    },
    logoAgri: {
      height: 24
    },
    info: {
      color: theme.palette.info.main,
      fontStyle: "italic"
    }, avatarClass: {
      height: "180px",
      width: "180px",
      alignSelf: 'center'
    }, donutParticipation: {
      width: '60px',
      height: '60px',
      display: 'inline-block',
      borderRadius: '50%',
      textAlign: 'center',
      marginLeft: '5px',
    }, imgAvatar: {
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      marginTop: '5px'
    }, clickableElement: {
      "&:hover": {
        boxShadow: "0 0 3px #888"
      },
      cursor: 'pointer'
    }
  }))

const Header: React.FC = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const mobileMode = useMobileMode();
  const dispatch = useDispatch();

  const { margeContainer } = globalClasses;
  const { appBar, container, link, title, subTitle, logo, info, donutParticipation, imgAvatar, clickableElement } = classes;

  const { user: userPrincipal, userAs } = useSelector((state: RootState) => state.auth);
  const { logos: { emc2 } } = images;

  const { userConvention, proprietes } = userAs ?? userPrincipal;

  //const userCurrent = useCurrentUser();
  const contexte = useContexte();

  // const currentPage = pathname.substring(pathname.lastIndexOf("/") + 1)

  const logosSociete = emc2;

  const [infoUsersEnquete, setInfoUsersEnquete] = useState<UserInfoCompteDto>();
  const [tauxParticipation, setTauxParticipation] = useState<number>(0);
  const [backgroundDonut, setBackgroundDonut] = useState<string>("#FF4C4C");
  const [listType, setListType] = useState<UserType[]>(null);
  const [notificationMessage, setNotificationMessage] = useState<boolean>(false);

  const goToPage = (targetPage: string) => () => {
    dispatch(push(PATH + targetPage));
  }

  const messagerie = () => {
    setNotificationMessage(false)
    dispatch(push(PATH + "/Messagerie"));
  }


  const logout = () => {
    dispatch(userAs ? fetchLogoutAs() : fetchLogout(proprietes.URL_CONNEXION?.valeur));
  }

  const iconeHeight = mobileMode ? 24 : 34;

  function initInfoCompte(data: UserInfoCompteDto) {
    setInfoUsersEnquete(data);
  }

  function initType(data: UserType[]) {
    setListType(data);
  }

  const handleChangeContexte: ChangeHandler = e => {
    const target = e.target;
    dispatch(setContexte(target.value))
    dispatch(push(PATH + "/Accueil"));
  }

  useEffect(() => {
    if (infoUsersEnquete !== null && infoUsersEnquete !== undefined) {
      if (infoUsersEnquete.nombreEnquetesValidee != null && infoUsersEnquete.nombreEnquetesSoumise !== null) {
        const moyenne: number = Math.round(((infoUsersEnquete.nombreEnquetesValidee * 100) / infoUsersEnquete.nombreEnquetesSoumise));
        if (Number.isNaN(moyenne)) {
          setTauxParticipation(0)
        } else {
          setTauxParticipation(moyenne);
        }
      }
    }
  }, [infoUsersEnquete]);

  useEffect(() => {
    let couleurs: string[] = [];
    if (tauxParticipation < 50) {
      couleurs = colorsDonut.redColor;
    } else if (tauxParticipation > 50) {
      couleurs = colorsDonut.greenColor;
    }
    const couleurActive = couleurs[0];
    const couleurFade = couleurs[1];
    const background = "radial-gradient(white 10%, transparent 0%), conic-gradient( " + couleurActive + " 0% " + tauxParticipation + "%, " + couleurFade + " " + tauxParticipation + "% 100%)";
    setBackgroundDonut(background)
  }, [tauxParticipation]);

  function fctRefresh() {
    dispatch(fetchInfoUserPaneliste(userConvention.idUsersConvention, initInfoCompte));
    dispatch(fetchUserType(initType))
  }

  useEffect(() => {

    function notifMessageSuccess(data: boolean){
      setNotificationMessage(data)
    }
    dispatch(getNotificationHeader(userConvention.idUsersConvention,notifMessageSuccess));
  }, [])

  useEffect(() => {
    fctRefresh()
  }, [])

  // GESTION DES EVENEMENTS
  const handleMessage = useCallback(event => {
    // Update filtre
    if (event.data.type === "validenquete") {
      fctRefresh()
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  return (
    <>
      <AppBar position="relative" elevation={0}>
        <Box className={appBar}>
          <SizeContainer>
            <Toolbar className={clsx(container, margeContainer)}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Link
                    to={PATH + "/Accueil"}
                    className={link}>
                    <img
                      src={mobileMode ? (proprietes.BASE?.valeur === "TEST" ? logosSociete[3] : logosSociete[1]) : (proprietes.BASE?.valeur === "TEST" ? logosSociete[2] : logosSociete[0])}
                      alt="logo"
                      className={logo}
                      style={mobileMode ? { margin: "5px" } : {}} />
                  </Link>
                </Grid>
                <Grid item>
                  <Grid container justify="flex-end" alignItems="center">
                    {/*
                    <Grid item style={{ marginRight: 4 }}>
                      <IconButton onClick={() => messagerie()}>
                        <Badge variant='dot' color='secondary' invisible={!notificationMessage}>
                          <Mail htmlColor={notificationMessage ? "#5B9ABD" : "#0075b5"} fontSize='large' />
                        </Badge>
                      </IconButton>
                    </Grid>
                    */}
                    <Grid item>
                      <Typography component="div" className={title} align="right" variant="h4">
                        {userAs && <Typography component="span" className={info}>Connecté en tant que </Typography>}
                        {userConvention.nomContact}
                      </Typography>
                      {proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE" && (
                        <Typography component="div" className={subTitle} align="right" variant="subtitle2">
                          {proprietes.RAISON_SOCIALE.valeur}
                        </Typography>
                      )}
                      {proprietes.TYPE_UTILISATEUR?.valeur !== "PANELISTE" && (
                        <MySelectField
                          value={contexte}
                          style={{ padding: "5px" }}
                          name="typeUser"
                          onChange={handleChangeContexte}
                          size="small"
                        >
                          {listType?.map((type: UserType) => (
                            <MenuItem key={type.libelle.toUpperCase()} value={type.libelle.toUpperCase()}>{type.libelle}</MenuItem>
                          ))}
                        </MySelectField>
                      )}
                    </Grid>
                    <Grid item>
                      {proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE" && (
                        <>
                          <Grid container alignContent="center" justify="center" alignItems="center" onClick={goToPage("/MonCompte")} >
                            <div className={clsx(donutParticipation, clickableElement)} style={{ background: backgroundDonut }}>
                              <img className={imgAvatar} src={proprietes.GENRE?.valeur === 'H' ? AvatarPanelHomme : AvatarPanelFemme} alt="Avatar" />
                            </div>
                          </Grid>
                        </>
                      )}
                      {proprietes.TYPE_UTILISATEUR?.valeur !== "PANELISTE" && (
                        <>
                          <Grid container alignContent="center" justify="center" alignItems="center" style={{ marginLeft: "5px" }} onClick={goToPage("/MonCompte")} >
                            <img className={clsx(imgAvatar, clickableElement)} src={proprietes.GENRE?.valeur === 'H' ? AvatarAdminHomme : AvatarAdminFemme} alt="Avatar" />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid item>
                      <SwitchIconButton onClick={logout} height={iconeHeight} size={mobileMode ? "small" : "medium"}><PowerSettingsNewIcon /></SwitchIconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </SizeContainer>
        </Box>
      </AppBar >
    </>
  );
}

export default Header;
