/* eslint-disable react/display-name */
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { Column } from "material-table";
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import { Tiers } from "./UsersAdminType";

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    },cadreTiers:{
      border: "1px solid #32a1ce",
      borderRadius: "2px",
      paddingBottom:"20px",
      height:"200px",
      overflow:"auto",
      margin: "5px"
    }
  })
)


export interface TableTiersProps<T extends object> extends MyMaterialTableProps<T> {
  selectedData?: Tiers;
  selectionChange?: (rows: T[]) => void;
  onRowClick?: (row: T) => void;
}

export const TableTiers = (props: TableTiersProps<Tiers>) => {

  const { cadreTiers } = useStyles();

  const {onRowClick, selectedData } = props;

  const columns: Column<Tiers>[] = [
    { title: "CodeTiers", render: sc => <Typography>{sc.codeTiers}</Typography>, align: "left" },
    { title: "Nom", render: sc => <Typography>{sc.nom}</Typography>, align: "left" },
    { title: "Prénom", render: sc => <Typography>{sc.prenom}</Typography>, align: "left" },
    { title: "N° Téléphone", render: sc => <Typography>{sc.telephone}</Typography>, align: "left" },
    { title: "Email", render: sc => <Typography>{sc.mail}</Typography>, align: "left" },
  ];

  const isRowSelected = (rowData: Tiers) => selectedData.codeTiers == rowData.codeTiers;

  return <div className={cadreTiers}>
    <MyMaterialTable<Tiers> columns={columns} {...props} onRowClick={onRowClick} isRowSelected={isRowSelected}  />
  </div>;
};