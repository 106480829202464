import { useEffect } from "react"
import { useLocation } from "react-router";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ScrollToTop(): any {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}