/* eslint-disable no-async-promise-executor */
import firebase from 'firebase/app';
import 'firebase/messaging';


let messaging: firebase.messaging.Messaging = null;
let firebaseInitialization: Promise<void> | null = null;
let firebaseVapidKey: string | null = null;


function initializeFirebase() {
  if (!firebaseInitialization) {
    firebaseInitialization = new Promise(async (resolve, reject) => {
      try {
        const response = await fetch('../e-agriskop/apirestagriskop/v1/users/firebaseconfig');

        if (!response.ok) {

          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseText = await response.text();
        const data = await JSON.parse(responseText).data;

        firebase.initializeApp(data.fireBaseConf);
        firebaseVapidKey = data.vapidKey

        if (firebase.messaging.isSupported()) {
          messaging = firebase.messaging();
        }
        resolve();
      } catch (error) {
        console.error('Error initializing Firebase:', error);
        reject(error);
      }
    });
  }

  return firebaseInitialization;
}

export const onMessageListener = () =>
  initializeFirebase().then(() => {
    return new Promise((resolve) => {
      messaging?.onMessage((payload) => {
        resolve(payload);
      });
    });
  });



export async function getToken(): Promise<string | null> {
  await initializeFirebase();
  await Notification.requestPermission();
  if (messaging && Notification.permission === 'granted') {
    try {
      const registration = await navigator.serviceWorker.ready;
      const token = await messaging.getToken({
        serviceWorkerRegistration: registration,
        vapidKey: firebaseVapidKey
      });

      return token;
    } catch (err) {
      return null;
    }
  } else {
    return null;
  }
}

