import React from 'react'

export interface ContextState{
    pwaInstall: Event;
}

export const initState: ContextState = {
    pwaInstall: null,
  } 

const Context = React.createContext(null)

export default Context