import { showError, ErrorOptions } from "../features/error/errorSlice";
import { AppDispatch } from "../app/store";
import { Response } from "../utils/types";
import restClient from "./RestClient";
import { hideLoader, showLoader } from "../features/loader/loaderSlice";

export const simpleSuccess = <T>(dispatch: AppDispatch, funcSuccess: (data: T) => void, options?: ErrorOptions) => (data: Response<T>) => {
  if (data.error !== "") {
    dispatch(showError({ response: data, options }));
  } else if (funcSuccess) {
    funcSuccess(data.data);
  }
}

export const simpleError = (dispatch: AppDispatch, options?: ErrorOptions) => (error: Error) => {
  dispatch(showError({ errorLabel: "Erreur", detail: error.message, options}));
}

export const simpleLoaderSuccess = <T>(dispatch: AppDispatch, funcSuccess: (data: T) => void, options?: ErrorOptions) => (data: Response<T>) => {
  dispatch(hideLoader());
  if (funcSuccess) simpleSuccess(dispatch, funcSuccess, options)(data);
}

export const simpleLoaderError = (dispatch: AppDispatch, options?: ErrorOptions) => (error: Error) => {
  dispatch(hideLoader());
  simpleError(dispatch, options)(error);
}

export const simpleGet = <T>(dispatch: AppDispatch, service: string, method: string, parameters: string, funcSuccess?: (data: T) => void, options?: ErrorOptions) => {
  try {
    restClient.get<Response<T>>(service, method, parameters, simpleSuccess(dispatch, funcSuccess, options), simpleError(dispatch, options));
  } finally {
    /* continue regardless of error */
  }
}

export const simplePost = <U, V>(dispatch: AppDispatch, service: string, method: string, parameters: string, data: U, funcSuccess?: (data: V) => void, options?: ErrorOptions) => {
  try {
    restClient.post<U, Response<V>>(service, method, parameters, data, simpleSuccess(dispatch, funcSuccess, options), simpleError(dispatch, options));
  } finally {
    /* continue regardless of error */
  }
}

export const simpleLoaderGet = <T>(dispatch: AppDispatch, service: string, method: string, parameters: string, funcSuccess?: (data: T) => void, options?: ErrorOptions) => {
  try {
    dispatch(showLoader());
    restClient.get<Response<T>>(service, method, parameters, simpleLoaderSuccess(dispatch, funcSuccess, options), simpleLoaderError(dispatch, options));
  } finally {
    /* continue regardless of error */
  }
}

export const simpleLoaderPost = <U, V>(dispatch: AppDispatch, service: string, method: string, parameters: string, data: U, funcSuccess?: (data: V) => void, options?: ErrorOptions) => {
  try {
    dispatch(showLoader());
    restClient.post<U, Response<V>>(service, method, parameters, data, simpleLoaderSuccess(dispatch, funcSuccess, options), simpleLoaderError(dispatch, options));
  } finally {
    /* continue regardless of error */
  }
}