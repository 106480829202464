import * as React from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { resetErrorState } from "./errorSlice";
import { push } from "connected-react-router";
import { RootState } from "../../app/rootReducer";
import { resetAuthState } from "../auth/authSlice";
import { useCurrentUser } from "../auth/authApi";
import { PATH } from "../../utils/constants";

const ErrorDialog = () => {
  const dispatch = useDispatch()

  const user = useCurrentUser();
  const { open, errorLabel, error, detail: errorDetail, response, options } = useSelector((state: RootState) => state.error)
  const { redirectionPath, reload, params } = options || {};

  const handleClose = () => {
    dispatch(resetErrorState());
    if (error === 440) {
      const urlConnexion = user.proprietes.URL_CONNEXION?.valeur;
      batch(() => {
        dispatch(resetAuthState());
        if (urlConnexion != null && urlConnexion !== "") {
          dispatch(push(PATH + "/ExternalUrlRedirection/" + user.proprietes.URL_CONNEXION?.valeur));
        }else{
          dispatch(push(PATH + "/Login"));
        }
      });
      return;
    }
    if (redirectionPath)
      dispatch(push(redirectionPath));

    if (reload)
      window.location.reload();
  }

  if (!open) return null;

  const actions = [];
  let title = "";
  let detail = "";
  if (response) {
    detail = response.error;

    if (response.status === -2999) {
      const action = () => {
        dispatch(resetErrorState());
      }
      actions.push(<Button key={"NON"} onClick={handleClose} color="secondary">NON</Button>);
      actions.push(<Button key={"OUI"} onClick={action} color="secondary">OUI</Button>);
    } else {
      actions.push(<Button key={"OK"} onClick={handleClose} color="secondary">OK</Button>);
    }
  } else {
    title = errorLabel;
    detail = errorDetail
    actions.push(<Button key={"FERMER"} onClick={handleClose} color="secondary">Fermer</Button>);
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText color="initial" dangerouslySetInnerHTML={{ __html: detail }} />
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog;