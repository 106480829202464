import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useCurrentUser } from '../features/auth/authApi';
import LoginExtranetPage from '../features/auth/LoginExtranetPage';
import LoginPage from '../features/auth/LoginPage';
import ErrorDialog from '../features/error/ErrorDialog';
import Loader from '../features/loader/Loader';
import Layout from '../page/Layout';
import theme from '../style/theme';
import themeEmc2 from '../style/themeEmc2';
import { PATH } from '../utils/constants';
import InscriptionPage from '../features/auth/Inscription';
import ValidationInscriptionPage from '../features/auth/ValidationInscription';
import Context, { ContextState, initState } from './Context';
import FirebaseMessagingListener from '../page/FirebaseMessagingListener';

const App = () => {
  const user = useCurrentUser();
  const favicon = document.getElementById("favicon") as HTMLLinkElement;
  const faviconPath = favicon.href.substr(0, favicon.href.lastIndexOf("/") + 1);
  const [context, setContext] = useState<ContextState>(initState)

  useEffect(() => {
    favicon.href = faviconPath + "AgriSKop_Responsive.png";
  }, [user]);

  useEffect(() => {
    const handler = (event: Event) => {
      event.preventDefault()
      setContext((prevState) => ({
        ...prevState, ["pwaInstall"]: event
      }))
    }

    window.addEventListener('beforeinstallprompt', handler)

    return () => {
      window.removeEventListener('beforeinstallprompt', handler)
    }
  }, []);

  const themeSociete = themeEmc2;

  return (
    <ThemeProvider theme={themeSociete(theme)}>
      <Context.Provider value={{ context, setContext }}>
        <CssBaseline />
        <ErrorDialog />
        <Loader />
        <Switch>
          {user ? (
            <Switch >
              <Route exact path={PATH + "/Inscription"}>
                <InscriptionPage />
              </Route>
              <Route exact path={PATH + "/ValidationInscription"}>
                <ValidationInscriptionPage init={true} />
              </Route>
              <Route exact path={PATH + "/ModificationMotDePasse"}>
                <ValidationInscriptionPage init={false} />
              </Route>
              <Route exact path={[PATH, "/", PATH + "/", PATH + "/Login", PATH + "/PageLoginExtranet"]}>
                <Redirect to={PATH + "/Accueil"} />
              </Route>
              <Route path={PATH + "/*"}>
                <Layout />
              </Route>
            </Switch>
          ) : (
            <>
              <Switch>
                <Route exact path={PATH + "/Inscription"}>
                  <InscriptionPage />
                </Route>
                <Route exact path={PATH + "/ValidationInscription"}>
                  <ValidationInscriptionPage init={true} />
                </Route>
                <Route exact path={PATH + "/ModificationMotDePasse"}>
                  <ValidationInscriptionPage init={false} />
                </Route>
                <Route exact path={PATH + "/Login"}>
                  <LoginPage />
                </Route>
                <Route exact path={PATH + "/PageLoginExtranet"}>
                  <LoginExtranetPage />
                </Route>
                <Route path={PATH + "/ExternalUrlRedirection/:urlRedirect"} render={props => window.location.href = props.location.pathname.replace(PATH + "/ExternalUrlRedirection/", "")} />
                <Redirect from="/*" to={PATH + "/Login"} />
              </Switch>
            </>
          )
          }
        </Switch>
        <FirebaseMessagingListener />
      </Context.Provider>
    </ThemeProvider >
  );
}

export default App;
