import { Drawer, makeStyles, IconButton, createStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import colors from '../../style/colors';
import { HEADER_HEIGHT } from '../../style/theme';
import { PATH } from '../../utils/constants';
import { NavElement } from '../../utils/types';
import { setMenuItem, setNav, toggleOpenedSousMenus } from './menuSlice';
import { MenuItem, MenuItemComplement } from './menuTypes';
import CloseIcon from '@material-ui/icons/Close';
import { useGlobalStyles } from '../../style/globalStyles';
import { useContexte } from '../auth/authApi';

const useStyles = makeStyles(theme => createStyles({
  treeView: {
    marginTop: HEADER_HEIGHT
  },
  treeItem: {
    paddingTop: 10,
    paddingBottom: 10,
    color: colors.blanc
  },
  treeItemLabel: {
    color: colors.blanc,
    fontSize: (level: number) => level === 0 ? 18 : level === 1 ? 16 : 14
  },
  paper: {
    padding: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }

  },
  closeButton: {
    color: colors.blanc,
    fontSize: 20
  }
}))

export interface SousMenuProps {
  menu: MenuItem;
  open: boolean;
  onClose: () => void;
  openedSousMenu: string[];
}

const SousMenu = (props: SousMenuProps) => {
  const { menu, open, onClose, openedSousMenu } = props;
  const { alignRight } = useGlobalStyles();

  const { paper, treeView, closeButton } = useStyles(0);
  const dispatch = useDispatch();
  const contexte = useContexte();

  const sousMenu = (
    <TreeView
      className={treeView}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={openedSousMenu}
      onNodeToggle={(e, nodeIds) => dispatch(toggleOpenedSousMenus(nodeIds))}
    > 
      {menu?.sousmenu.filter(sm => sm.contexte === contexte).map(sm => <SousMenuItem key={sm.code} menu={sm} nav={[]} level={0} onClose={onClose} />)}
    </TreeView>
  );

  return (
    <Drawer
      anchor="left"
      open={open}
      classes={{ paper: paper }}
      PaperProps={{ elevation: 12 }}
      onClose={onClose}>
      <div className={alignRight}>
        <IconButton className={closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      {sousMenu}
    </Drawer>
  );
}

export default SousMenu

interface SousMenuItemProps {
  menu: MenuItem;
  nav: NavElement[];
  level: number;
  onClose: () => void;
}

const sousMenuItemComplements: MenuItemComplement = {
  ALERTE: { pages: ["MesAlertes"] },
}

const SousMenuItem = (props: SousMenuItemProps) => {
  const { menu, nav, level, onClose } = props;
  const { code, libelle, sousmenu, type, target, url } = menu;
  const dispatch = useDispatch();
  const contexte = useContexte();
  const { treeItem, treeItemLabel } = useStyles(level);

  const onClick = () => {
    if (type === "EXTERNE") {
      if (target === "BLANK") {
        window.open(url, "_blank")
      } else {
        onClose();
        dispatch(setMenuItem(menu));
        dispatch(setNav(nav));
        dispatch(push(PATH + "/Menu"));
      }
    } else if (type === "INTERNE") {
      const page = sousMenuItemComplements[code].pages[0];
      dispatch(push(PATH + "/" + page));
      onClose();
    }
  };

  return <TreeItem nodeId={code} className={treeItem} classes={{ label: treeItemLabel }} label={libelle} onClick={onClick}>
    {
      sousmenu?.filter(sm => sm.contexte === contexte).map(sm => <SousMenuItem key={sm.code} menu={sm} nav={[...nav, { name: libelle }]} level={level + 1} onClose={onClose} />)
    }
  </TreeItem>;
}