import React from 'react';
import { Enquete } from "./EnqueteType";
import { Card, CardActionArea, createStyles, Grid, makeStyles,Typography } from "@material-ui/core";
import clsx from 'clsx';
import { useGlobalStyles } from '../../style/globalStyles';
import { PATH } from '../../utils/constants';
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {setEnquete} from "./EnqueteSlice";
import { useCurrentUser } from '../auth/authApi';
import {fetchLogOuverturePublication} from "./PublicationApi";
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import colors from "../../style/colors";

interface StyleProps {
    small?: boolean;
    enquete?: Enquete;
    type?: string;
    origine?: string;
  }

const useStyles = makeStyles(theme =>
    createStyles({
      paper: {
        height: (props: StyleProps) =>  ((props.origine==="TDBOUVERTE" && props.type==="ENQUETE")? 220:((props.origine==="TDBFERMEE" && props.type==="ENQUETE")? 270: 200)) ,
        [theme.breakpoints.down("sm")]: {
          height: (props: StyleProps) =>((props.origine==="TDBOUVERTE" && props.type==="ENQUETE")? 220:((props.origine==="TDBFERMEE" && props.type==="ENQUETE")? 270: 200)) 
        },
        backgroundColor: "#E7E6E6"
      },
      container: {
        padding: theme.spacing(5)
      },
      cardActionArea: {
        height: 200,
        [theme.breakpoints.down("sm")]: {
          height: 200
        }
      },imgEnquete: {
        maxWidth:"100%",
        maxHeight:"90%"
      },divImg: {
        justifyContent:"center"
      },tempsRestant: {
        width: "100%",
        marginBottom: theme.spacing(5),
        padding: 0,
        textAlign: "right",
        justifyContent:"right",
        //backgroundColor: (props: StyleProps) => props.enquete.colorStatut,
        backgroundImage: (props: StyleProps) =>"linear-gradient(to left, "+props.enquete.colorStatut+" "+props.enquete.prctJauge +"%, "+props.enquete.colorStatutLost+" 0%)",
        paddingRight: "10px",
        borderradius: "5px",
        maxHeight:"20%"
      },divInfo: {
        justifyContent:"center"
      }
    }));

export interface EnqueteBlocAccueilProps{
    enquete: Enquete;
    small?: boolean;
    origine: string;
    type: string;
}
    
const BlocEnqueteAccueil = (props: EnqueteBlocAccueilProps) => {

    const {enquete,small,origine,type} = props;
    const dispatch = useDispatch();
    const classes = useStyles({ small: small,enquete: enquete,type:type,origine:origine});
    const { paper,container,cardActionArea,imgEnquete,divImg,tempsRestant,divInfo} = classes;
    const globalClasses = useGlobalStyles();
    const { fullHeight } = globalClasses;
    const user = useCurrentUser();

  
    function onClick(){
      // LOG OUVERTURE 
      function resLog(data: boolean){
          //
      }

      if(enquete.statut==="*" || enquete.type === "ACTUALITE"){
        dispatch(fetchLogOuverturePublication(user,enquete.id,resLog));
        dispatch(setEnquete(enquete));
        dispatch(push(PATH + "/Enquetefiche"));
      }
    }

    return (
        <Card elevation={small ? 4 : 0} className={paper}>
            <CardActionArea onClick={onClick} className={clsx(cardActionArea, container)} disabled={onClick == null} >
                {type==="ENQUETE" && origine==="TDBFERMEE" &&
                (
                  <>
                    <Grid container className={divInfo}>
                            {enquete.statutReponsePanel && 
                            (
                              <>
                                <Grid item xs={2} sm={2}>
                                  <CheckCircle htmlColor={colors.vert} fontSize="small"></CheckCircle>
                                </Grid>
                                <Grid item xs={10} sm={10}>
                                  <Typography variant="h6" align="left" dangerouslySetInnerHTML={{ __html: enquete.statutReponsePanel}} />
                                </Grid>
                              </>
                            )}
                            {!enquete.statutReponsePanel && 
                            (
                              <>
                                 <Grid item xs={2} sm={2}>
                                  <ErrorOutline htmlColor={colors.rouge} fontSize="small"></ErrorOutline>
                                </Grid>
                                <Grid item xs={10} sm={10}>
                                  <Typography variant="h6" align="left" dangerouslySetInnerHTML={{ __html: "Vous n'avez pas répondu à cette enquête"}} />
                                </Grid>
                              </>
                            )}
                    </Grid> 
                  </>
                )} 
                <Grid container className={clsx(divImg, fullHeight)} >
                    <img src={enquete.urlImg} className={imgEnquete}/> 
                    {enquete.type=="ENQUETE" && (
                      <Grid container className={tempsRestant} >
                        <Typography variant="body1" align="right" dangerouslySetInnerHTML={{ __html: enquete.tempsRestant ?? "&nbsp;" }} />
                      </Grid>
                    )}
                </Grid> 
                {type==="ENQUETE" && origine.substring(0,3)==="TDB" &&
                (
                  <>
                    <Grid container className={divInfo}>
                      <Typography variant="h6" align="right" dangerouslySetInnerHTML={{ __html: enquete.nombreRepondant===0 ? "Personne n'a répondu à l'enquête":(enquete.nombreRepondant  + (enquete.nombreRepondant>1 ? " utilisateurs ont  ": " utilisateur a ") + " répondu à l'enquête" ) }} />
                  </Grid> 
                  </>
                )} 
            </CardActionArea>
        </Card>
      )
}

export default BlocEnqueteAccueil;