import imgCotation from "./illustrations/cotation-notification.svg";
import imgContrat from "./illustrations/contrat-notification.svg";
import imgInfo from "./illustrations/information-notification.svg";
import logoEmc2 from "./logo/AgriSKop.png";
import logoEmc2Mobile from "./logo/AgriSKop_Responsive.png";
import user from "./neutre/USER ACTIF.svg";
import userInactif from "./neutre/USER INACTIF.svg";
import contact from "./neutre/CONTACT ACTIF.svg";
import contactInactif from "./neutre/CONTACT INACTIF.svg";
import groupe from "./neutre/GROUPE UTILISATEUR ACTIF.svg";
import groupeInactif from "./neutre/GROUPE UTILISATEUR INACTIF.svg";
import home from "./menu/HOME.svg";
import assolement from "./menu/ASSOLEMENT.svg";
import engagement from "./menu/ENGAGEMENT.svg";
import contrat from "./menu/CONTACT.svg";
import infos from "./menu/INFORMATIONSMENU.svg";
import menu from "./menu/MENU.svg";
import prisePos from "./menu/PRENDRE POSITION.svg";
import homeDark from "./menu/HOME_dark.svg";
import contratDark from "./menu/CONTACT_dark.svg";
import infosDark from "./menu/INFORMATIONSMENU_dark.svg";
import menuDark from "./menu/MENU_dark.svg";
import prisePosDark from "./menu/PRENDRE POSITION_dark.svg";

import logoTest from "./logo/AgriSKopTEST.png";
import logoTESTMobile from "./logo/AgriSKop_ResponsiveTEST.png";

const images = {
  logos: {
    emc2: [logoEmc2, logoEmc2Mobile,logoTest,logoTESTMobile]
  },
  illustration: {
    imgContrat,
    imgCotation,
    imgInfo
  },
  neutre: {
    user,
    userInactif,
    contact,
    contactInactif,
    groupe,
    groupeInactif
  },
  menu: {
    normal: {
      home,
      contrat,
      infos,
      menu,
      prisePos,
      assolement,
      engagement
    },
    dark: {
      home: homeDark,
      contrat: contratDark,
      infos: infosDark,
      menu: menuDark,
      prisePos: prisePosDark
    }
  }
};

export default images;