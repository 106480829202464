import React, { useState,useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import PageTemplate from '../../page/PageTemplate';
import { NavElement} from '../../utils/types';
import { useMobileMode } from '../../utils/utils';
import colors from "../../style/colors";
import {Enquete,EnquetesTdbPaneliste} from "./EnqueteType";
import {fetchRecupEnqueteTdbPaneliste} from "../enquete/PublicationApi";
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../auth/authApi';
import { useGlobalStyles } from '../../style/globalStyles';
import clsx from 'clsx';
import BlocEnqueteSlideAccueil from "./EnqueteSlideAccueil";
import BlocEnqueteAccueilAucune from '../enquete/EnqueteAccueilAucune';

const NAV_ELEMENTS: NavElement[] = [{ name: "Mes Enquetes", path: "/MesEnquetes" }];


const EnquetePanelistePage = () => {

    const mobileMode = useMobileMode();
    const dispatch = useDispatch();
    const user = useCurrentUser();
    const globalClasses = useGlobalStyles();

    const {margeContainer,marginBottom } = globalClasses;

    const [enquetesOuvertePanel, setEnquetesOuvertePanel] = useState<Enquete[]>([]);
    const [enquetesTerminePanel, setEnquetesTerminePanel] = useState<Enquete[]>([]);
 
    useEffect(() => {
        function intiPublication(data: EnquetesTdbPaneliste){
          if(data){
            setEnquetesOuvertePanel(data.enqueteOuvertes);
            setEnquetesTerminePanel(data.enqueteFermees)
          }else{
            setEnquetesOuvertePanel([]);
            setEnquetesTerminePanel([]);
          }
        }
        dispatch(fetchRecupEnqueteTdbPaneliste(user.userConvention.idUsersConvention,intiPublication));
      }, []);

    return (
        <PageTemplate  elements={NAV_ELEMENTS}>
            <Grid container spacing={mobileMode ? 0 : 10}>
                <Grid item xs={12} sm={9}>
                    <Grid container spacing={mobileMode ? 0 : 10}>
                        <Grid item xs={12}><Typography variant="h3">Mes Enquêtes</Typography></Grid>
                    </Grid>
                    <Grid container spacing={mobileMode ? 0 : 10}>
                        <Grid item xs={12}>
                            <Typography variant="h3" dangerouslySetInnerHTML={{ __html: "<font color='"+colors.vert+"'>En cours</font>" }}  align="center" >
                            </Typography>
                            <Box className={clsx(margeContainer, marginBottom)}>
                                {enquetesOuvertePanel&&enquetesOuvertePanel.length>0&&(<BlocEnqueteSlideAccueil  enquetes={enquetesOuvertePanel}  type="ENQUETE" origine="TDBOUVERTE" />)}
                                {enquetesOuvertePanel === null || enquetesOuvertePanel.length ===  0&&(
                                    <BlocEnqueteAccueilAucune type="ENQUETE" origine="TDBOUVERTE"/>    
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    {enquetesTerminePanel && enquetesTerminePanel.length>0 &&
                    (<>
                    <Grid container spacing={mobileMode ? 0 : 10}>
                        <Grid item xs={12}>
                            <Typography variant="h3" dangerouslySetInnerHTML={{ __html: "<font color='"+colors.rouge+"'>Terminées</font>" }}  align="center" >
                            </Typography>
                            <Box className={clsx(margeContainer, marginBottom)}>
                                <BlocEnqueteSlideAccueil  enquetes={enquetesTerminePanel}  type="ENQUETE" origine="TDBFERMEE" />
                            </Box>
                        </Grid>
                    </Grid>
                    </>
                    )
                    }
                </Grid>
            </Grid> 
        </PageTemplate >
      );
    }
    

    export default EnquetePanelistePage;
