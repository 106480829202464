import { createStyles, makeStyles, Paper, Typography } from "@material-ui/core";
import * as React from "react";


const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(5),
      padding: theme.spacing(10),
    }
  })
);

const InfoEnquetePaper = (props: {message: string; titre: string }) => {
  const { titre, message } = props;
  const classes = useStyles();

  const { paper } = classes;

  return (
    <Paper className={paper} elevation={0}>
      {titre && <Typography color="secondary" variant="h3" component="span" dangerouslySetInnerHTML={{ __html: titre }} />}
      <Typography color="secondary" variant="body1" dangerouslySetInnerHTML={{ __html: message }} />
    </Paper>
  );
}

export default InfoEnquetePaper;