import React, { useState, useEffect, useContext } from 'react'

import favicon from '../../img/logo/AgriSKop_Responsive_192.png'
import Context from '../../app/Context'
import DialogNotification, { DialogNotificationObject } from '../../page/DialogNotification'


const AddToHomeScreenButton = () => {
  const [eventPwa, setEventPwa] = useState(null)
  const { context } = useContext(Context)
  const { pwaInstall } = context
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [notification, setNotification] = useState<DialogNotificationObject>(null)

  function initNotification(){
    const notif: DialogNotificationObject = {
      title: "Ajouter Agriscoop a votre écran d'accueil",
      image: favicon,
      body: "Accéder simplement et rapidement à agriscoop depuis votre écran d'accueil"
    }
    setNotification(notif)
  }

  useEffect(() => {

    const alreadySee = localStorage.getItem("seenPopUpPwa");
    initNotification()
    if (alreadySee == null && !window.matchMedia('(display-mode: standalone)').matches) {
      setOpenPopup(true)
    } else {
      setOpenPopup(false)
    }

    if (pwaInstall) {
      setEventPwa(pwaInstall)
    }

  }, [pwaInstall])

  const handleAddToHomeScreenClick = () => {
    setOpenPopup(false)
    if (eventPwa) {
      eventPwa.prompt()
    }
  }

  const handleCancelClick = () => {
    setOpenPopup(false)
    localStorage.setItem("seenPopUpPwa", "true");
  }

  return (
    <>
      {(eventPwa !== null) && (
        <>
        {openPopup && (
          <DialogNotification onClose={handleCancelClick} onConfirm={handleAddToHomeScreenClick} confirmText={"Ajouter à l'écran d'accueil"} closeText={"Annuler"} notification={notification} />
        )}
        </>
      )}
    </>
  )
}


export default AddToHomeScreenButton