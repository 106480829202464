
import { AppThunk } from "../../app/store";
import { simpleGet, simpleLoaderGet, simpleLoaderPost} from "../../services/restUtils";
import {AdminUser, Tiers, UserModification, UserProfil, UserType} from "./UsersAdminType";

export const fetchUsersAdmin = (funcSuccess: (data: AdminUser[]) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "users", "listusersadmin", "", funcSuccess);
}

export const fetchUserType = (funcSuccess: (data: UserType[]) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "users", "usertype", "", funcSuccess);
}

export const fetchTiers = (recherche: string,funcSuccess: (data: Tiers[]) => void): AppThunk => async dispatch => {
  simpleGet(dispatch, "users", "searchtiers", recherche+"", funcSuccess);
}

export const fetchSaveUserFct = (user: UserModification,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
  simpleLoaderPost(dispatch, "users", "modificationuser","", user,funcSuccess);
}

export const fetchSendMail = (idUserconvention: number,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
  simpleGet(dispatch, "users", "sendmail", idUserconvention+"", funcSuccess);
}

export const fetchUserProfil = (funcSuccess: (data: UserProfil[]) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "users", "userprofil", "", funcSuccess);
}
