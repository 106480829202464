import { Box } from "@material-ui/core";
import React from 'react';
//import BackButton from "../components/BackButton";
import BreadCrumb from "../components/BreadCrumb";
import { NavElement } from "../utils/types";
import { useGlobalStyles } from "../style/globalStyles";

export interface PageTemplateProps {
  elements: NavElement[];
  children: React.ReactNode;
}

const PageTemplate = (props: PageTemplateProps) => {
  const { elements, children } = props;
  const { margeContainer } = useGlobalStyles();
  return (
    <>
      <Box className={margeContainer}>
        <BreadCrumb elements={elements} />
        {/*<BackButton />*/}
      </Box>
      {children}
    </ >
  )
}

export default PageTemplate;