import { createMuiTheme, MuiThemeProvider, Theme, Typography, TableCell } from "@material-ui/core";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ChevronRight from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';  
import ArrowCircleDownIcon from '@material-ui/icons/ArrowDropDownCircle';
 
import MaterialTable, { Column, Icons, Action, Options } from 'material-table';
import React, { forwardRef, PropsWithChildren } from "react";
import colors from "../style/colors";

const useThemeTable = (theme: Theme) => createMuiTheme({
  ...theme,
  palette: {
    primary: {
      main: colors.active
    }, secondary: {
      main: colors.active
    }
  },
  overrides: {
    ...theme.overrides,
    MuiPaper: {
      root: {
        backgroundColor: "transparent"
      },
      elevation2: {
        boxShadow: "none"
      },
      rounded: {
        borderRadius: 0
      }
    },
    MuiTable: {
      root: {
        whiteSpace: "nowrap"
      }
    },
    MuiTableSortLabel: {
      active: {
        "& svg": {
          color: colors.active + " !important"
        }
      }
    },
    MuiTableRow: {
      root: {
        ...theme.overrides.MuiTableCell.root,
        "&:last-of-type td": {
          borderBottom: 0
        }
      },
      footer: {
        borderTop: "1px solid " + colors.gris30,
        backgroundColor: colors.blanc
      }
    },
    MuiTableCell: {
      root: {
        "&:last-child": {
          textAlign: "right"
        },
      },
      head: {
        ...theme.overrides.MuiTableCell.head,
        color: colors.gris10,
        textAlign: "left"
      },
      footer: {
        ...theme.overrides.MuiTableCell.footer,
        "& h5": {
          color: colors.bleu
        }
      },
      sizeSmall: {
        padding: "12px 6px",
        "&$head": {
          padding: "6px 6px",
          fontSize: 11,
          "& svg": {
            margin: 0
          }
        },
        "&:first-child:not($paddingNone)": {
          paddingLeft: 10
        },
        "&:last-child": {
          paddingRight: 10
        },
        "& button": {
          fontSize: 13,
          padding: 3,
          lineHeight: "normal"
        }
      }
    }
  }
})

export interface MyMaterialTableProps<T extends object> {
  values?: Array<T> | { [key: string]: T };
  columns?: Column<T>[];
  onSelectionChange?: (data: T[], rowData?: T) => void;
  onRowClick?: (rowData: T) => void;
  isRowSelected?: (rowData: T) => boolean;
  select?: boolean;
  selectAll?: boolean;
  sort?: boolean;
  header?: boolean;
  fullWidth?: boolean;
  detailPanel?: (rowData?: T) => React.ReactElement;
  size?: "default" | "small" | "xs";
  actions?: (Action<T> | ((rowData: T) => Action<T>))[];
  disabled?: boolean;
  footerMessage?: string;
  libelleColumn?: string;
  exportAff?: boolean;
  toolBarAff?: boolean;
  searchAff?: boolean;
  exportExcel? : (columns: Column<T>[], renderData: T[]) => void;
  smallLign?: boolean;
  filter?: boolean;
  customBodyHeight?: string;
  customHeaderBckGrdColor?: string;
}

const MyMaterialTable = <T extends object,>(props: PropsWithChildren<MyMaterialTableProps<T>>) => {
  const { exportExcel,searchAff,toolBarAff,exportAff,values, columns, onSelectionChange, select, sort, header, fullWidth, detailPanel, selectAll, size, actions, disabled, onRowClick, isRowSelected, footerMessage,smallLign,filter,customBodyHeight,customHeaderBckGrdColor } = props;

  if (!values) return null;

  let data: T[] = [];
  if (Array.isArray(values)) {
    data = values;
  } else {
    data = Object.keys(values).map((key: string) => {
      const obj: T = { ...values[key] };
      return obj;
    });
  }

  const icons: Icons = {
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Export : forwardRef((props, ref) => <ArrowCircleDownIcon {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />), 
    ResetSearch: forwardRef((props, ref) => <ClearIcon  {...props} ref={ref} />), 
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rowClick = onRowClick ? (event: any, rowData: T) => { onRowClick(rowData) } : null;

  const components = footerMessage !== null ? {
    components: {
      Pagination: () => (
        <TableCell>
          <Typography variant="h5">{footerMessage}</Typography>
        </TableCell>)
    }
  } : null;


 

  const options: Options<T> = {
    selection: select ?? false,
    showSelectAllCheckbox: (selectAll ?? true) && !disabled,
    selectionProps: {
      color: 'primary',
      disabled: disabled
    },
    search: searchAff ?? false,
    sorting: sort ?? false,
    header: header ?? true,
    toolbar: toolBarAff ?? false,
    paging: footerMessage != null ?? false,
    pageSize: 1000,
    emptyRowsWhenPaging: false,
    draggable: false,
    headerStyle: {
      backgroundColor: customHeaderBckGrdColor || "transparent",
      fontSize: size === "xs" ? 10 : null
    },
    padding: size === "small" || size === "xs" ? "dense" : "default",
    actionsColumnIndex: -1,
    actionsCellStyle: {
      justifyContent: "flex-end",
      paddingRight: 10
    },
    exportButton: exportAff?{
      csv: exportAff ?? false,
      pdf: false
    }:false,
    exportCsv:  exportExcel,
    exportFileName: "export",
    exportDelimiter: ";",
    filtering: filter ?? false,
    maxBodyHeight: customBodyHeight,
    
   };

  if (isRowSelected) {
    options.rowStyle = (rowData: T) => ({ backgroundColor: isRowSelected(rowData) ? colors.active : colors.blanc });
  }

  if (size === "xs") {
    columns.forEach((c, index) => {
      columns[index] = { ...c, cellStyle: { paddingLeft: 3, paddingRight: 3 } };
    });
  }

  if(smallLign){
    columns.forEach((c, index) => {
      columns[index] = { ...c, cellStyle: { padding: "5px" } };
    });
  }

  return (
    <MuiThemeProvider theme={useThemeTable}>
      <MaterialTable
        title=""
        columns={columns}
        detailPanel={detailPanel}
        onRowClick={rowClick}
        data={data}
        icons={icons}
        actions={actions}
        options={options}
        localization={{
          header: {
            actions: ""
          },
          toolbar: {
            exportCSVName: "   Export Excel",
            exportPDFName: "   Export PDF",
            searchPlaceholder: "Rechercher"
          }
        }}
        onSelectionChange={onSelectionChange}
        style={{ width: fullWidth ? "100%" : "auto" }}
        {...components}
      />
    </MuiThemeProvider>
  );
}

export default MyMaterialTable;