import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './style/react-slick.css';
import App from './app/App';
import { Provider } from 'react-redux'
import store, { history, persistor } from './app/store';
import { ConnectedRouter } from 'connected-react-router';
import ScrollToTop from './components/ScrollToTop';
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
  (
    <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history} >
          <ScrollToTop />
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
    </>
  ), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();

