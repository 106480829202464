
import { AppThunk } from "../../app/store";
import { simpleLoaderGet,simpleLoaderPost} from "../../services/restUtils";
import {InscriptionInfoTiers,UserCreation,InscriptionCtrlCodeCrypte,InscriptionInfoCompte,UserChangeMdp,UserRechercheIdentifiant} from "./InscriptionType";
import { Dispatch } from "redux";
import { hideLoader, showLoader } from "../loader/loaderSlice";
import { showError } from "../error/errorSlice";
import { Response } from "../../utils/types";
import restClient from "../../services/RestClient";

export const fetchInfoTiers = (codetiers: string,funcSuccess: (data: InscriptionInfoTiers) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "inscription", "getinfotiers", codetiers+"",funcSuccess);
  }

  export const fetchInfoTiersWithIdc = (idc: string,funcSuccess: (data: InscriptionInfoTiers) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "inscription", "getinfotiersidc", idc+"",funcSuccess);
  }

  const fetchCreateUser = <T extends UserCreation>(userCreat: T, method: string,  dispatch: Dispatch,funcSuccess: (data: string) => void) => {
    try {
      dispatch(showLoader());

      restClient.post<T, Response<string>>("users", method, "", userCreat, (data: Response<string>) => {
        dispatch(hideLoader());
        if (data.status < 0) {
          dispatch(showError({ response: data }));
        } else {
          funcSuccess(data.data);
        }
      }, (error: Error) => {
        dispatch(hideLoader());
        dispatch(showError({ errorLabel: "Erreur", detail: error.message}));
      });
    } finally {
      /* continue regardless of error */
    }
  }
  
  export const fetchCreateUserFct= (userCreat: UserCreation,funcSuccess: (data: string) => void): AppThunk => async dispatch => {
    fetchCreateUser<UserCreation>(userCreat, "createuserinscription", dispatch,funcSuccess);
  }


  
export const fetchControleCodeCrypte = (codeCrypte: string,init: boolean,funcSuccess: (data: InscriptionInfoCompte) => void): AppThunk => async dispatch => {
  const params: InscriptionCtrlCodeCrypte = {
    codeCrypte: codeCrypte,
    init: init
  }
  simpleLoaderPost(dispatch, "users", "ctrlCodeCrypte ", "", params,funcSuccess);
}

export const fetchChangeMDP = (codeCrypte: string,mdp: string,init: boolean,funcSuccess: (data: string) => void): AppThunk => async dispatch => {
  const params: UserChangeMdp = {
    codeCrypte: codeCrypte,
    init: init,
    mdp: mdp
  }
  simpleLoaderPost(dispatch, "users", "changemdp ", "", params,funcSuccess);
}


export const fetchDemandeChangeMDP = (login: string,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
  const params: UserChangeMdp = {
    login: login
  }
  simpleLoaderPost(dispatch, "users", "ddchangemdp ", "", params,funcSuccess);
}

export const fetchRechercheIdentifiant = (recherche: string,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
  const params: UserRechercheIdentifiant = {
    recherche: recherche
  }
  simpleLoaderPost(dispatch, "users", "rechercheIdentifiant ", "", params,funcSuccess);
}