/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { onMessageListener } from '../firebase';
import DialogNotification, { DialogNotificationObject } from './DialogNotification';
import { useCurrentUser } from '../features/auth/authApi';


const FirebaseMessagingListener = () => {
    const [notification, setNotification] = useState<DialogNotificationObject>(null);
    const user = useCurrentUser();

    onMessageListener().then((payload: any) => {
        if (payload?.data?.idUserConvention == user.userConvention.idUsersConvention) {
            setNotification(payload.data)
          } else {
            const notificationTitle = payload.data.title;
            const notificationOptions = { 
                body: payload.data.body,
                image: payload.data?.image,
                data: payload.data
            };
    
            if ('serviceWorker' in navigator && navigator.serviceWorker instanceof ServiceWorkerContainer) {
                navigator.serviceWorker.ready.then((registration: ServiceWorkerRegistration) => {
                    registration.showNotification(notificationTitle, notificationOptions);
                });
            }
          }
    
    }).catch((err) => console.log('failed', err))


    return (
        <>
            {notification && (
                <>
                    <DialogNotification onClose={null} onConfirm={() => setNotification(null)} confirmText={"OK"} closeText={null} notification={notification} />
                </>
            )}
        </>
    );
};

export default FirebaseMessagingListener;
