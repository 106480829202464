/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Paper, makeStyles, Grid, Typography, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import PageTemplate from '../../page/PageTemplate';
import { NavElement } from '../../utils/types';
import { useMobileMode } from '../../utils/utils';
import { useGlobalStyles } from '../../style/globalStyles';
import MenuPage from './MenuPage';
import ConversationPage from './ConversationPage';
import { Conversation, MenuConversation, Sujet } from './MessagerieType';
import { useCurrentUser } from '../auth/authApi';
import { useDispatch } from 'react-redux';
import { fetchConversations } from './MessagerieApi';
import { Error, Forum } from '@material-ui/icons';

const NAV_ELEMENTS: NavElement[] = [{ name: "Messagerie", path: "/Messagerie" }];

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10
  },
  menuStyle: {
    borderRight: "2px solid #cdcdcd"
  },
})
)

const MessageriePage = () => {
  const dispatch = useDispatch();
  const { paper, menuStyle } = useStyles();
  const globalClasses = useGlobalStyles();
  const { mobileSpacing } = globalClasses;
  const mobileMode = useMobileMode();
  const { userConvention, proprietes } = useCurrentUser();

  const [conversationActive, setConversationActive] = useState<Conversation[]>([]);
  const [conversationArchive, setConversationArchive] = useState<Conversation[]>([]);
  const [currentConversation, setCurrentConversation] = useState<Conversation>(null);
  const [displayMobileState, setDisplayMobileState] = useState<number>(0);
  const [idNewConv, setIdNewConv] = useState<number>(-2000);
  const [maxArchive, setMaxArchive] = useState<string>("50");
  const [popUpLimiteConv, setPopUpLimiteConv] = useState<boolean>(false);

  function updateLocaleConversation(conversation: Conversation) {
    const convActive: Conversation[] = conversationActive.map(conv => {
      if (conv.idConversation === conversation.idConversation) {
        conv = conversation
      }
      return conv
    })
    setConversationActive(convActive)
  }

  function fctSelectConversation(conversation: Conversation) {
    if (conversation === null || (currentConversation !== null && conversation?.idConversation === currentConversation.idConversation)) {
      setDisplayMobileState(0);
      setCurrentConversation(null);
    } else {
      setDisplayMobileState(1);
      setCurrentConversation(conversation)
      if (conversation.messagesNonLus > 0) {
        conversation.messagesNonLus = 0;
        updateLocaleConversation(conversation)
      }
    }
  }

  function fctCreateConversation(sujet: Sujet) {
    //deleteEmptyConv()
    if (conversationActive.length >= 2) {
      setPopUpLimiteConv(true)
      return
    }
    const convActive = [...conversationActive];
    const newConv: Conversation = {
      idConversation: idNewConv,
      idUsersconvention: userConvention.idUsersConvention,
      sujet: sujet,
      dateDernierEchange: new Date().toDateString(),
      statut: "ACTIF",
      messagesNonLus: 0,
      canClose: false,
      dateCloture: new Date().toLocaleDateString(),
      new: true,
      titre: sujet.libelle
    }

    convActive.unshift(newConv)
    setConversationActive(convActive)
    fctSelectConversation(newConv);

    setIdNewConv(idNewConv - 1);
  }

  function initConversations(data: MenuConversation) {
    if (data) {
      setConversationActive(data.conversationsActive.reverse())
      setConversationArchive(data.conversationsArchive.reverse())
    }
  }

  function refreshConversation() {
    fctSelectConversation(null)
    //SI PANELISTE PAS DE LIMITE SUR LES MESSAGES ARCHIVES
    if(proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE"){
      dispatch(fetchConversations(userConvention.idUsersConvention, null, initConversations))
    }else{
      dispatch(fetchConversations(userConvention.idUsersConvention, maxArchive, initConversations))
    }
    
  }

  

  useEffect(() => {
    refreshConversation()
  }, [maxArchive]);

  return (
    <PageTemplate elements={NAV_ELEMENTS}>
      <Grid container spacing={mobileMode ? 0 : 10}>
        <Grid item xs={12} sm={12} className={mobileSpacing}>
          {/* HEADER */}
          <Grid container alignItems="center" justify="flex-start">
            <Grid item style={{ padding: 4 }}>
              <Forum fontSize='large' />
            </Grid>
            <Grid item style={{ padding: 2 }}>
              <Typography variant="h2" gutterBottom >Ma Messagerie</Typography>
            </Grid>
          </Grid>
          {/* MESSAGERIE */}
          <Paper className={paper} style={{ height: "625px" }}>
            <Grid container spacing={mobileMode ? 0 : 10}>
              {mobileMode && (
                <>
                  {displayMobileState === 0 && (
                    <Grid item xs={12}>
                      <MenuPage
                        conversationActive={conversationActive}
                        conversationArchive={conversationArchive}
                        fctSelectConversation={fctSelectConversation}
                        currentConversation={currentConversation}
                        fctCreateConversation={fctCreateConversation}
                        maxArchive={maxArchive}
                        setMaxArchive={setMaxArchive}
                      />
                    </Grid>
                  )}
                  {displayMobileState === 1 && (
                    <Grid item xs={12}>
                      <ConversationPage
                        currentConversation={currentConversation}
                        fctSelectConversation={fctSelectConversation}
                        refreshConversation={refreshConversation}
                        updateLocaleConversation={updateLocaleConversation}
                      />
                    </Grid>
                  )}
                </>
              )}
              {!mobileMode && (
                <>
                  <Grid item xs={3} sm={4} className={menuStyle}>
                    <MenuPage
                      conversationActive={conversationActive}
                      conversationArchive={conversationArchive}
                      fctSelectConversation={fctSelectConversation}
                      currentConversation={currentConversation}
                      fctCreateConversation={fctCreateConversation}
                      maxArchive={maxArchive}
                      setMaxArchive={setMaxArchive} />
                  </Grid>
                  <Grid item xs={9} sm={8}>
                    <ConversationPage
                      currentConversation={currentConversation}
                      fctSelectConversation={fctSelectConversation}
                      refreshConversation={refreshConversation}
                      updateLocaleConversation={updateLocaleConversation} />
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
          {/*DIALOG ENVOIE MESSAGE*/}
          <Dialog
            open={popUpLimiteConv}
            onClose={() => setPopUpLimiteConv(false)}
          >
            <DialogContent>
              <DialogTitle color='initial'>
                <Grid container>
                  <Grid item xs={4}>
                    <Error htmlColor='red' />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant='h4'>Limite conversations</Typography>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContentText color="initial">
                <Typography variant='h5'>{"Vous avez déjà " + conversationActive.length + " conversations d'ouvertes"}</Typography>
                <Typography variant='h5'>{"Merci de les archiver avant d'en créer une nouvelle"}</Typography>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </PageTemplate>
  )
}

export default MessageriePage;