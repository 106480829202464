import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Enquete } from "./EnqueteType";

export interface EnqueteState {
  enquete: Enquete;
}

const initialState: EnqueteState = {
  enquete: null
}

const EnqueteSpe = createSlice({
  name: "enquete",
  initialState,
  reducers: {
    resetEnqueteState: state => {
      Object.assign(state, initialState);
    },
    setEnquete(state, action: PayloadAction<Enquete>) {
      state.enquete = action.payload
    }
  }
})

export const { setEnquete: setEnquete } = EnqueteSpe.actions;
export const { resetEnqueteState: resetEnqueteState} = EnqueteSpe.actions;
export default EnqueteSpe.reducer;