/* eslint-disable react/display-name */
import {createStyles,  makeStyles, Typography} from "@material-ui/core";
import {Column} from "material-table";
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import { PanelisteRechFichePublication } from "./EnqueteType";
import {Done} from "@material-ui/icons";

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    },cadrePaneliste:{
      border: "1px solid #32a1ce",
      borderRadius: "10px",
      paddingBottom:"20px",
      height:"200px",
      overflow:"auto"
    }
  })
)

export interface TableDataPanelistesProps<T extends object> extends MyMaterialTableProps<T> {
  panelisteSelected: number[];
  panelisteT: number[];
  onSelect?: (paneliste: PanelisteRechFichePublication) => void;
  userIsAdmin: boolean;
}

export const TablePanelisteToSelect = (props: TableDataPanelistesProps<PanelisteRechFichePublication>) => {
  const { libelleTableau ,cadrePaneliste,} = useStyles();

  const {panelisteSelected,onSelect,panelisteT,userIsAdmin} = props;
 
  function publicationValide(idCurrentUser: number){
    for (const idUsersConvention of panelisteT) {
      if(idCurrentUser==idUsersConvention){
        return true;
      }
    }
    return false;
  }

  const columns: Column<PanelisteRechFichePublication>[] = [
    { title: "Code Tiers", render: sc => <Typography className={libelleTableau}>{sc.codeTiers}</Typography> ,align: "left" },
    { title: "Nom", render: sc => <Typography className={libelleTableau}>{sc.nom}</Typography> ,align: "left" },
    { title: "", render: sc => <Typography className={libelleTableau}></Typography> ,align: "left" },
    { title: "", render: sc => <Typography className={libelleTableau}>{publicationValide(sc.idUsersConvention) ? <Done fontSize="small"></Done>:""}</Typography> ,align: "left" },
  ];

 
  function isRowSelected(rowData: PanelisteRechFichePublication){
    for (const idUsersConvention of panelisteSelected) {
      if(rowData.idUsersConvention==idUsersConvention){
        return true;
      }
    }
    return false;
  }
  return <div className={cadrePaneliste}>
            <MyMaterialTable<PanelisteRechFichePublication> columns={columns} {...props} isRowSelected={isRowSelected} onRowClick={userIsAdmin ? onSelect : null} fullWidth smallLign={true}  />
         </div>;
};
