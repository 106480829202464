/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import Chart from "react-apexcharts";
import {createStyles, Grid, makeStyles, Typography} from "@material-ui/core";
import {Comment,DataUsageRounded, Edit,Accessibility } from "@material-ui/icons";
import { ApexOptions } from "apexcharts";
import { Action, Column} from "material-table";
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import colors from "../../style/colors";
import { ColorsTBD, PublicationTBD } from "./EnqueteType";

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    }
  })
)

const ProgressBar = (props: {
  couleurs: string[];
  value1: number;
  value2: number;
}) => {
const {couleurs,value1,value2 } = props;

const options: ApexOptions = {
  chart: {
    selection: {
      enabled: false
    },
    height: 75,
    type: 'bar',
    stacked: true,
    stackType: '100%',
    toolbar:{show:false},
  },
  states: {
    hover: {
        filter: {
            type: 'none',
        }
    },
    active: {
      filter: {
        type: 'none',
      }
    } 
  },
  dataLabels:{
    enabled:true,
    formatter: function(val: string | number | number[], opts?: any){
      return  opts.w.globals.series[opts.seriesIndex]
    }
  },
  tooltip:{enabled:false},
  grid:{show:false},
  legend:{show:false},
  colors: couleurs,
  plotOptions: {
    bar: {
      horizontal: true
    }
  },
    xaxis: {
      axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        },
  },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      }
};

return (
<>
<Grid item>
  <Chart
      options={options}
      type= 'bar'
      height="75"
      series={[{
                  name: 'Terminé',
                  data: [value1]
              }, {
                  name: 'Affecté',
                  data: [value2]
               }]}
     
  />
</Grid>
</>
);
}

const colorsTBD: ColorsTBD = {
  active: ["#f8b413", "#fbd375"],
  close: ["#990000", "#FF4C4C"]
}


export interface TableEnquetesProps<T extends object> extends MyMaterialTableProps<T> {
  fctEdit: (enquete: PublicationTBD) => void;
  fctCommetaires: (enquete: PublicationTBD) => void;
  fctDatas: (enquete: PublicationTBD) => void;
  fctDetailPaneliste: (enquete: PublicationTBD) => void;
}

export const TableEnquetes = (props: TableEnquetesProps<PublicationTBD>) => {
  const { libelleTableau } = useStyles();

  const {fctCommetaires,fctDatas,fctEdit,fctDetailPaneliste} = props;
  
  const columns: Column<PublicationTBD>[] = [
    { title: "Titre",field:"titre", render: sc => <Typography className={libelleTableau}>{sc.titre}</Typography> ,align: "left" },
    { title: "Statut",field:"statutLibelle", render: sc => <Typography className={libelleTableau}>{sc.statutLibelle}</Typography> ,align: "left" },
    { title: "Progression", render: sc => <Typography className={libelleTableau}>{sc.statutCode !== 'F' && 
    (<ProgressBar value1={sc.nombreUserValide>0?sc.nombreUserValide:0} value2={(sc.nombreUserTotal-(sc.nombreUserValide>0?sc.nombreUserValide:0))} couleurs={sc.statutCode ==='*' ? colorsTBD.active : colorsTBD.close}/>)}</Typography> ,align: "left" }
    
  ];

  const actions: (Action<PublicationTBD> | ((sc: PublicationTBD) => Action<PublicationTBD>))[] = [
    () => (
      {icon: () => ( <Edit htmlColor={colors.jaune} fontSize="large"></Edit>),onClick: (event, sc) => fctEdit(sc as PublicationTBD)}),
      ({icon: () => ( <Comment htmlColor={colors.jaune} fontSize="large"></Comment>),onClick: (event, sc) => fctCommetaires(sc as PublicationTBD)}),
      ({icon: () => ( <DataUsageRounded htmlColor={colors.jaune} fontSize="large"></DataUsageRounded>),onClick: (event, sc) => fctDatas(sc as PublicationTBD)}),
      ({icon: () => ( <Accessibility htmlColor={colors.jaune} fontSize="large"></Accessibility>),onClick: (event, sc) => fctDetailPaneliste(sc as PublicationTBD)})
    ]

  return <div>
            <MyMaterialTable<PublicationTBD> columns={columns} {...props}  actions={actions} toolBarAff={true} searchAff={true} exportAff={false} customBodyHeight={"500px"} customHeaderBckGrdColor={"#F2F2F2"}/>
         </div>;
};
