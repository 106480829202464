/* eslint-disable react/display-name */
import {createStyles, makeStyles, Typography} from "@material-ui/core";
import { Action, Column} from "material-table";
import { Delete} from '@material-ui/icons';
import React from "react";
import MyMaterialTable, { MyMaterialTableProps } from "../../components/MyMaterialTable";
import colors from "../../style/colors";
import { UserSujetDto } from "../messagerie/MessagerieType";

const useStyles = makeStyles(theme =>
  createStyles({
    cleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    libelleTableau: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      "& a": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightLight,
        "& a": {
          fontWeight: theme.typography.fontWeightRegular
        }
      }
    },
    sousTxt: {
      fontSize: 12
    }
  })
)


export interface TableUsersSujetsProps<T extends object> extends MyMaterialTableProps<T> {
  userSelected: number[];
  onSelect?: (user: UserSujetDto) => void;
}

export const TableUsersSujets = (props: TableUsersSujetsProps<UserSujetDto>) => {
  const { libelleTableau } = useStyles();

  const { userSelected,onSelect } = props;
  
  const columns: Column<UserSujetDto>[] = [
    { title: "Code Tiers",field:"codeTiers", render: sc => <Typography className={libelleTableau}>{sc.idUsersConvention}</Typography> ,align: "left" },
    { title: "Nom",field:"nomPrenom", render: sc => <Typography className={libelleTableau}>{sc.nomPrenom}</Typography> ,align: "left" },
  ];


  function isRowSelected(rowData: UserSujetDto){
    for (const idUsersConvention of userSelected) {
      if(rowData.idUsersConvention==idUsersConvention){
        return true;
      }
    }
    return false;
  }

  return <div>
            <MyMaterialTable<UserSujetDto> columns={columns} {...props} searchAff={true} toolBarAff={true} isRowSelected={isRowSelected} onRowClick={onSelect}  sort={true} customBodyHeight={"500px"} customHeaderBckGrdColor={"#F2F2F2"}/>
         </div>;
};
