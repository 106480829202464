import { push } from "connected-react-router";
import { AppThunk } from "../../app/store";
import { simpleGet, simplePost } from "../../services/restUtils";
import { PATH } from "../../utils/constants";
import { User } from "../users/usersTypes";
import { authLogin, authLoginAs, authLogout, authLogoutAs } from "./authSlice";
import { batch, useSelector } from "react-redux";
import { RootState } from "../../app/rootReducer";

export interface SecureLogin {
  login: string;
  password?: string;
  versionClient?: string;
  token?: string;
  ssltoken?: string;
  adresseIpUsers?: string;
}

export const useCurrentUser = () => {
  const { user, userAs } = useSelector((state: RootState) => state.auth);
  return userAs ?? user;
};

export const useContexte = () => {
  const { contexte} = useSelector((state: RootState) => state.auth);
  return contexte;
};

export const fetchLogin = (login: string, password: string): AppThunk => async (
  dispatch
) => {
  const secureLogin: SecureLogin = {
    login: login,
    password: password,
    versionClient: "1.15.0",
  };

  simplePost(dispatch, "auth", "login", "", secureLogin, (u: User) => {
    dispatch(authLogin(u));
  });
};

export const fetchLogout = (urlConnexion: string): AppThunk => async (
  dispatch
) => {
  simpleGet(dispatch, "auth", "logout", "", () => {
    batch(() => {
      dispatch(authLogout());
      if (urlConnexion != null && urlConnexion != "") {
        dispatch(push(PATH + "/ExternalUrlRedirection/" + urlConnexion));
      } else {
        dispatch(push(PATH + "/Login"));
      }
    });
  });
};

export const fetchLoginAs = (login: string): AppThunk => async (dispatch) => {
  const secureLogin: SecureLogin = {
    login: login,
  };
  simplePost(dispatch, "auth", "loginAs", "", secureLogin, (u: User) => {
    batch(() => {
      dispatch(authLoginAs(u));
      dispatch(push(PATH + "/Accueil"));
    });
  });
};

export const fetchLogoutAs = (): AppThunk => async (dispatch) => {
  simpleGet(dispatch, "auth", "logoutAs", "", () => {
    batch(() => {
      dispatch(authLogoutAs());
      dispatch(push(PATH + "/Accueil"));
    });
  });
};

export const fetchLoginExtranet = (
  loginTiers: string,
  jeton: string,
  adresseIpSlc: string
): AppThunk => async (dispatch) => {
  const secureLogin: SecureLogin = {
    login: loginTiers,
    token: jeton,
    adresseIpUsers: adresseIpSlc,
  };

  simplePost(dispatch, "auth", "loginExtranet", "", secureLogin, (u: User) => {
    dispatch(authLogin(u));
    dispatch(push(PATH + "/Accueil"));
  });
};
