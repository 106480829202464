import { resetErrorState } from "../features/error/errorSlice";
import { hideLoader } from "../features/loader/loaderSlice";
import { resetMenuState } from "../features/menu/menuSlice";
import {resetEnqueteState} from "../features/enquete/EnqueteSlice";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cleanMiddleware = (store: any) => (next: any) => (action: any) => {
  const result = next(action);

  if (action.type === "@@router/LOCATION_CHANGE") {
      const pathname = action.payload.location.pathname;
      if(pathname.indexOf("Accueil") >= 0){
        store.dispatch(resetMenuState());
      }
      if(pathname.indexOf("Enquete") < 0){
        store.dispatch(resetEnqueteState());
      }
      store.dispatch(resetErrorState());
      store.dispatch(hideLoader());
    }
   
    
  return result;
};

export default cleanMiddleware;
