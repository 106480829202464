import 'reactjs-popup/dist/index.css';
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { fr } from "date-fns/locale";
import { ConnexionsKpi, ConnexionsKpiObj, PublicationDate } from './AccueilType';
import { fetchKpiConnexions, fetchPublicationDate } from './AccueilApi';
import DetailsConnexionTable from './DetailsConnexionTable';

const BarChartKpi = () => {

  const dispatch = useDispatch();
  const [dateDebut, setDateDebut] = useState<Date>(() => {
    const date: Date = new Date()
    date.setMonth(new Date().getMonth() - 1)
    return date;
  });
  const [dateFin, setDateFin] = useState<Date>(new Date());
  const [dataConnexion, setDataConnexion] = useState<ConnexionsKpi[]>([]);
  const [publicationDate, setPublicationDate] = useState<PublicationDate[]>([]);
  const [dateDebutIsValid, setDateDebutIsValid] = useState<boolean>(true);
  const [dateFinIsValid, setDateFinIsValid] = useState<boolean>(true);
  const [afficheDetail, setAfficheDetail] = useState<boolean>(false);

  const handleInputDateDebutError = (error: any) => {
    if (error) {
      setDateDebutIsValid(false);
    } else {
      setDateDebutIsValid(true);
    }
  };

  const handleInputDateFinError = (error: any) => {
    if (error) {
      setDateFinIsValid(false);
    } else {
      setDateFinIsValid(true);
    }
  };

  function initConnexionKpi(data: ConnexionsKpi[]) {
    if (data) {
      setDataConnexion(data);
    }
  }

  function initPublicationDate(data: PublicationDate[]) {
    if (data) {
      setPublicationDate(data);
    }
  }

  function callApi() {
    if (dateDebutIsValid && dateFinIsValid) {
      const dateDebutStr = dateDebut.toLocaleDateString("fr-FR");
      const dateFinStr = dateFin.toLocaleDateString("fr-FR");

      const connexionKpiObj: ConnexionsKpiObj =
      {
        dateDebut: dateDebutStr,
        dateFin: dateFinStr
      };

      dispatch(fetchKpiConnexions(connexionKpiObj, initConnexionKpi));
      dispatch(fetchPublicationDate(connexionKpiObj, initPublicationDate));
    }
  }

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    const call = setTimeout(() => {
      callApi();
    }, 1000)

    return () => clearTimeout(call)

  }, [dateDebut, dateFin]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
        <Typography variant="h3" color="textPrimary" gutterBottom align="center" >{"Dernières connexions panéliste"}</Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Typography variant="h5" color="textPrimary" gutterBottom align="center" >{"Date de Début"}</Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            format="dd/MM/yyyy"
            value={dateDebut}
            onChange={(e) => setDateDebut(e)}
            disableFuture
            animateYearScrolling
            variant="inline"
            inputVariant="outlined"
            minDate={new Date('09/01/2022')}
            maxDate={dateFin}
            inputProps={{ style: { padding: "5px" } }}
            style={{ marginLeft: "5px", marginRight: "10px", marginBottom: "5px" }}
            onError={handleInputDateDebutError}
            invalidDateMessage={"Format invalide, le format doit être JJ/MM/AAAA !"}
            maxDateMessage={"La date de début ne peut pas être supérieure à la date de fin !"}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Typography variant="h5" color="textPrimary" gutterBottom align="center" >{"Date de fin"}</Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            format="dd/MM/yyyy"
            value={dateFin}
            onChange={(e) => setDateFin(e)}
            disableFuture
            animateYearScrolling
            variant="inline"
            inputVariant="outlined"
            inputProps={{ style: { padding: "5px" } }}
            style={{ marginLeft: "5px", marginRight: "10px", marginBottom: "5px" }}
            invalidDateMessage={"Format invalide, le format doit être JJ/MM/AAAA"}
            onError={handleInputDateFinError}
            minDate={dateDebut}
            minDateMessage={"La date de fin ne peut pas être inférieur à la date de début !"}
            maxDateMessage={"La date de fin ne peut pas être dans le futur !"}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={12}>
        <BarGraph date={dataConnexion.map((obj: ConnexionsKpi) => obj.date)} connexion={dataConnexion.map((obj: ConnexionsKpi) => obj.nombreConnexion)} publicationArray={publicationDate} />
      </Grid>
      <Grid item xs={12} sm={12}>
        {!afficheDetail && (
          <Button variant='contained' color='primary' onClick={() => { setAfficheDetail(true) }}>
            Afficher le détail
          </Button>
        )}
        {afficheDetail && (
          <>
            <Grid item xs={12} sm={12}>
              <DetailsConnexionTable dateDebut={dateDebut} dateFin={dateFin} dateOk={dateDebutIsValid && dateFinIsValid} closeFct={() => { setAfficheDetail(false) }} />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

const BarGraph = (props: {
  date: string[];
  connexion: number[];
  publicationArray: PublicationDate[];
}) => {
  const { date, connexion, publicationArray } = props;

  const options: ApexOptions = {
    chart: {
      height: 120,
      id: "columnChart"
    },
    xaxis: {
      categories: date
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '40%',
        barHeight: '50%',
      }
    },
    annotations: {
      xaxis: publicationArray.map((publication) => {
        return {
          x: publication.dateDebut,
          borderColor: '#775DD0',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: publication.type === 'ENQUETE' ? '#FEB019' : '#00E396'
            },
            text: publication.titre
          }
        }

      })
    }
  };

  return (
    <>
      <Grid item>
        <Chart
          id='columnChart'
          series={[
            {
              name: "Connexion(s)",
              data: connexion
            }
          ]}
          options={options}
          type='bar'
        />
      </Grid>
    </>
  );
}

export default BarChartKpi;