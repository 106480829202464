
import { AppThunk } from "../../app/store";
import { simpleLoaderGet,simpleLoaderPost} from "../../services/restUtils";
import { EnquetePanelisteDetailDto,Commentaire, Enquete, PublicationTBD ,PublicationRecherche,EnquetesTdbPaneliste,EnqueteDataReponse,PanelisteRechFichePublication,SelectionPanelisteFile, EnqueteEdit, NotificationDto} from "../enquete/EnqueteType";
import { User } from "../users/usersTypes";
import restClient from '../../services/RestClient';
import { Response } from "../../utils/types";
import { showError } from "../../features/error/errorSlice";
import { Tag } from "../users/MonCompteType";

export const fetchListEnquetes = (idUserConvention: number,funcSuccess: (data: Enquete[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "enquetes", "listenqueteuser", idUserConvention+"",funcSuccess);
  }

export const fetchListActualite = (idUserConvention: number,funcSuccess: (data: Enquete[]) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "enquetes", "listeactualiteuser", idUserConvention+"",funcSuccess);
}

export const fetchListEnquetesTBD = (funcSuccess: (data: PublicationTBD[]) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "enquetes", "listeenquetetdb", "", funcSuccess);
}


export const fetchListCommentaires = (idPublication: number,funcSuccess: (data: Commentaire[]) => void): AppThunk => async dispatch => {
  simpleLoaderGet(dispatch, "enquetes", "listecommetaires", idPublication+"",funcSuccess);
}


  export const fetchLogOuverturePublication = (user: User,idPublication: number,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    const rechObj: PublicationRecherche = {
      idPublication:idPublication,
      idUsersConvention:user.userConvention.idUsersConvention
    };
    simpleLoaderPost(dispatch, "enquetes", "logouverturepublication","", rechObj,funcSuccess);
  }

  export const fetchValidationPublication = (user: User,idPublication: number,idSheetSendUp: string,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    const rechObj: PublicationRecherche = {
      idPublication:idPublication,
      idUsersConvention:user.userConvention.idUsersConvention,
      idSheetSendUp:idSheetSendUp
    };
    simpleLoaderPost(dispatch, "enquetes", "validationpublication","", rechObj,funcSuccess);
  }

  export const fetchSaveCommentairePublication = (user: User,idPublication: number,commentaire: string,note: number,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    const rechObj: PublicationRecherche = {
      idPublication:idPublication,
      idUsersConvention:user.userConvention.idUsersConvention,
      commentaire:commentaire,
      note:note
    };
    simpleLoaderPost(dispatch, "enquetes", "savecommentairepublication","", rechObj,funcSuccess);
  }


  export const fetchRecupEnqueteTdbPaneliste = (idUserConvention: number,funcSuccess: (data: EnquetesTdbPaneliste) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "enquetes", "recupenquetetdbpaneliste", idUserConvention+"",funcSuccess);
  }


  export const getdataoperation = (idUserConvention: number,funcSuccess: (data: EnqueteDataReponse) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "enquetes", "getdataoperation", idUserConvention+"",funcSuccess);
  }

  export const getPanelisteEnqueteDetail = (idUserConvention: number,funcSuccess: (data: EnquetePanelisteDetailDto[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "enquetes", "getpanelisteenquetedetail", idUserConvention+"",funcSuccess);
  }


  export const fetchListPanneliste = (idPublication: number,funcSuccess: (data: PanelisteRechFichePublication[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "enquetes", "listpaneliste", idPublication+"",funcSuccess);
  }

  export const fetchListPannelisteWithFile = (selectedFile: File,funcSuccess: (data: SelectionPanelisteFile) => void): AppThunk => async dispatch => {
    // ENVOIE DE L'IMAGE SUR LE WS
    const formData = new FormData();
    formData.append('File', selectedFile,selectedFile.name);

    fetch(
        restClient.baseUrl+"/uploadfile/uploadfilepanel",
        {
            method: 'POST',
            body: formData,
        }
    )
      .then((response) => response.json())
      .then((result: Response<SelectionPanelisteFile>) => {
          funcSuccess(result.data);
      })
      .catch((error) => {
        dispatch(showError({ errorLabel: "Erreur", detail: error, options: null})); 
      });
  }



  export const enregistreImage = (selectedFile: File,type: string,idPublication: number,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    // ENVOIE DE L'IMAGE SUR LE WS
    const formData = new FormData();
    formData.append('File', selectedFile,selectedFile.name);
    formData.append('type', type); // METTRE LE TYPE DE LA PUBLICATION 
    formData.append('id', ""+idPublication); // METTRE l'ID DE LA PUBLICATION 

    fetch(
        restClient.baseUrl+"/uploadfile/uploadimgpub",
        {
            method: 'POST',
            body: formData,
        }
    )
      .then((response) => response.json())
      .then((result: Response<boolean>) => {
          funcSuccess(result.data);
      })
      .catch((error) => {
        dispatch(showError({ errorLabel: "Erreur", detail: error, options: null})); 
      });
  }

  export const fetchSaveEnquete = (enquete: EnqueteEdit,funcSuccess: (data: number) => void): AppThunk => async dispatch => {
    simpleLoaderPost(dispatch, "enquetes", "savepublicationnew","", enquete,funcSuccess);
  }

  export const fetchEnqueteToEdit = (idPublication: number,funcSuccess: (data: EnqueteEdit) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "enquetes", "getpublicationedit", idPublication+"",funcSuccess);
  }

  export const fetchListActusTBD = (funcSuccess: (data: PublicationTBD[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "enquetes", "listeactustdb", "", funcSuccess);
  }

  export const fetchActusTdbPaneliste = (idUserConvention: number,funcSuccess: (data: Enquete[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "enquetes", "listactuspaneliste", idUserConvention+"",funcSuccess);
  }

  export const fetchNotification = (idUsersConvention: number,idPublication: number,funcSuccess: (data: NotificationDto[]) => void): AppThunk => async dispatch => {
    const rechnotificationObj = {
      idUsersConvention: idUsersConvention,
      idPublication: idPublication
    }
    simpleLoaderPost(dispatch, "enquetes", "getNotification","", rechnotificationObj,funcSuccess);
  }

  export const fetchListTag = (funcSuccess: (data: Tag[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "administration", "getalltag", "",funcSuccess);
  }

  export const fetchSendnotification = (idPublication: number,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "enquetes", "trySendNotif", idPublication+"",funcSuccess);
  }