import { createStyles, makeStyles, Paper, Typography, Button, MenuItem, Grid } from "@material-ui/core";
import * as React from "react";
import { PublicationTBD } from './EnqueteType';
import { ArrowLeft } from '@material-ui/icons';
import { MySelectField } from "../../components/MyTextField";
import { ChangeHandler } from "../../utils/types";

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(5),
      padding: theme.spacing(10),
      alignItems: 'left',
    }
  })
);

export interface EnteteDetailEnqueteProps {
  publication: PublicationTBD;
  fctAnnuler: () => void;
  titre: string;
  handleChangeFiltreUtilisateur: ChangeHandler;
  filtreUtilisateurs: string;
  typePubTitle: string;
  hiddeFiltre?: boolean;
}


const EnteteDetailEnquete = (props: EnteteDetailEnqueteProps) => {
  const { publication, fctAnnuler, titre, handleChangeFiltreUtilisateur, filtreUtilisateurs, typePubTitle, hiddeFiltre } = props;
  const classes = useStyles();

  const { paper } = classes;

  return (
    <>
      <Button variant="text" color="primary" disableElevation startIcon={<ArrowLeft htmlColor="#A0A0A0" fontSize="large" />} size="large" onClick={fctAnnuler}>
        {"Retour au tableau de bord"}
      </Button>
      <Typography variant="h3" gutterBottom >{titre}</Typography>
      <Paper className={paper} elevation={0}>
        <Typography variant="h4" gutterBottom color="secondary">{typePubTitle + " n° " + publication.id}</Typography>
        <Typography variant="h4" gutterBottom color="secondary" >{"Titre : " + publication.titre}</Typography>
        {!hiddeFiltre && (
          <Grid container>
            <Grid item xs={12} sm={2}>
              <Typography variant="h4" gutterBottom >{"Filtrer par utilisateurs : "}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MySelectField
                value={filtreUtilisateurs}
                style={{ padding: "5px" }}
                name="filtreCommentaire"
                onChange={handleChangeFiltreUtilisateur}
                size="small"
              >
                <MenuItem key={"TOUS"} value={"TOUS"}>{"Tous les utilisateurs"}</MenuItem>
                <MenuItem key={"PANELISTE"} value={"PANELISTE"}>{"Panéliste uniquement"}</MenuItem>
                <MenuItem key={"UTILISATEUR"} value={"UTILISATEUR"}>{"Salarié uniquement"}</MenuItem>
              </MySelectField>
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
}

export default EnteteDetailEnquete;