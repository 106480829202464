import React,{useState} from 'react';
import { Enquete } from "./EnqueteType";
import { Paper, createStyles, Grid, makeStyles, Typography,IconButton,Button,Hidden } from "@material-ui/core";
import clsx from 'clsx';
import { useGlobalStyles } from '../../style/globalStyles';
import colors from "../../style/colors";
import { useMobileMode } from '../../utils/utils';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import SwipeableViews from 'react-swipeable-views';
import ClickableDotMobileStepper from "../../components/ClickableDotMobileStepper";
import BlocEnqueteAccueil from "./EnqueteBlocAccueil";
import { PATH } from "../../utils/constants";
import { push } from "connected-react-router";
import { useDispatch } from 'react-redux';
import {autoPlay} from "react-swipeable-views-utils";



interface StyleProps {
    small?: boolean;
  }

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      position: "relative",
      minWidth: 350,
      minHeight: 115,
      backgroundColor: colors.blanc,
      padding: theme.spacing(5)
    },
    title: {
      padding: theme.spacing(5),
      fontSize: 20,
      color:colors.bleu
    },
    slide: {
      padding: theme.spacing(5)
    },
    mobileStepperButton: {
      width: 32
    }
  }));

export interface EnqueteSlideAccueilProps{
    enquetes: Enquete[];
    titre?: string;
    origine: string;
    type: string;
}

    
const BlocEnqueteSlideAccueil = (props: EnqueteSlideAccueilProps) => {

    const {enquetes,titre,origine,type} = props;
    const dispatch = useDispatch(); 
    const classes = useStyles();
    const { paper,title,mobileStepperButton,slide} = classes;
    const globalClasses = useGlobalStyles();
    const { marginBottom } = globalClasses;
    const mobileMode = useMobileMode();
    const [activeStep, setActiveStep] = useState<number>(0);

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    const nbSteps = enquetes.length;

    if (nbSteps <= 0) return null;
  
    const handleNext = () => {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    };
  
    const handleClickOnDot = (dot: number) => () => {
      setActiveStep(dot);
    };
  
    const handleStepChange = (newStep: number) => {
      setActiveStep(newStep);
    }

    function openFiche(){
      let menu: string=null;
      if(type==="ENQUETE"){
        menu ="MesEnquetes";
      }
      if(type==="ACTUALITE"){
        menu ="LesActus";
      }
      if(menu!==null){
        dispatch(push(PATH + "/"+menu))
      }
    }

    
    const backButton = (
      <IconButton onClick={handleBack} className={mobileStepperButton}>
        <ArrowBackIos />
      </IconButton>
    );
    const nextButton = (
      <IconButton onClick={handleNext} className={mobileStepperButton}>
        <ArrowForwardIos />
      </IconButton>
    );
    const width = "calc(100% - " + (mobileMode ? 0 : activeStep > 0 && activeStep < (nbSteps - 2) ? 2 : 1) * 34 + "px)";
    const blocsProduits = enquetes.map((enquete: Enquete) => <BlocEnqueteAccueil key={enquete.id} enquete={enquete} small type={type} origine={origine} />)

    const defilAuto = origine==="ACCUEIL" && ((mobileMode && nbSteps>1) || (!mobileMode && nbSteps>3));

    return (
      <Paper className={clsx(paper, marginBottom)} elevation={0} >
        {titre && (
          <Typography variant="h2" className={title} align="center">{titre}</Typography>
        )}
      
      <Grid container direction="row" spacing={1} justify="space-between" alignItems="center">
        {!mobileMode && activeStep > 0 && <Grid item>{backButton}</Grid>}
        <Grid item style={{ width: width }}>
        {defilAuto &&
          (
            <>
            <AutoPlaySwipeableViews  
                index={activeStep} 
                onChangeIndex={handleStepChange} 
                enableMouseEvents 
                style={{ paddingRight: mobileMode ? "10%" : "50%" }} 
                containerStyle={{width: "320px" }} 
                slideClassName={slide}>{blocsProduits}
              </AutoPlaySwipeableViews>
            </>
          )}
           {!defilAuto &&
          (
            <>
            <SwipeableViews  
                index={activeStep} 
                onChangeIndex={handleStepChange} 
                enableMouseEvents 
                style={{ paddingRight: mobileMode ? "10%" : "50%" }} 
                containerStyle={{width: "320px" }} 
                slideClassName={slide}>{blocsProduits}
              </SwipeableViews>
            </>
          )}
            
        </Grid>
        {!mobileMode && (activeStep < nbSteps - 2) && <Grid item>{nextButton}</Grid>}
      </Grid>
      <ClickableDotMobileStepper
        steps={mobileMode ? nbSteps : nbSteps - 1}
        position="static"
        activeStep={activeStep}
        backButton={null}
        nextButton={null}
        onDotClick={handleClickOnDot}
      />
      {origine==="ACCUEIL" && (
        <>
          <Grid container>
            <Hidden xsDown><Grid item xs={4}></Grid></Hidden>
            <Grid item xs={12} sm={4}>
              <Button fullWidth variant="contained" color="primary" onClick={openFiche} >{type==="ENQUETE"?"Toutes mes enquêtes":type==="ACTUALITE"?"Tous les actualités":""}</Button>
            </Grid>
            <Hidden xsDown><Grid item xs={4}></Grid></Hidden>
          </Grid>
        </>
      )}
      
    </Paper>
      )
}

export default BlocEnqueteSlideAccueil;