import { Typography,BottomNavigation, BottomNavigationAction, createStyles, Drawer, List, ListItem, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RootState } from '../../app/rootReducer';
import images from '../../img/images';
import colors from '../../style/colors';
import { useGlobalStyles } from '../../style/globalStyles';
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../style/theme';
import { CODE_SOCIETE_HOUPIEZ, PATH } from '../../utils/constants';
import { useMobileMode } from '../../utils/utils';
import { useContexte, useCurrentUser } from '../auth/authApi';
import { User, UserCore } from '../users/usersTypes';
import { MenuItemComplement } from './menuTypes';
import SousMenu from './SousMenu';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      border: 0,
      width: DRAWER_WIDTH
    },
    list: {
      marginTop: HEADER_HEIGHT
    },
    listItem: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 2,
      paddingRight: 2,
      "&.Mui-selected:not($transaction),&:hover:not($transaction),&.Mui-selected:hover:not($transaction)": {
        borderRight: (user: UserCore) => "3px solid " + (user.societe === CODE_SOCIETE_HOUPIEZ ? colors.activeTextHoupiez : colors.jaune),
        backgroundColor: "inherit"
      }
    },
    listItemIcon: {
      color: colors.blanc,
      minWidth: 0
    },
    bottomNavigation: {
      width: "100%",
      position: "fixed",
      bottom: 0,
      boxShadow: theme.shadows[12],
      alignItems: "center",
      zIndex: 2,
      "& $bottomNavigationAction": {
        padding: 0
      }
    },
    bottomNavigationAction: {
      padding: 0,
      minWidth: 0,
      color: colors.blanc,
      height: "100%",
      "&.Mui-selected": {
        borderTop: (user: UserCore) => "3px solid " + (user.societe === CODE_SOCIETE_HOUPIEZ ? colors.activeTextHoupiez : colors.jaune),
        backgroundColor: "inherit"
      }
    },
    transaction: {
      [theme.breakpoints.down("xs")]: {
        "&$bottomNavigationAction": {
          height: 62
        }
      },
      [theme.breakpoints.up("sm")]: {
        width: 65,
        position: "fixed",
        paddingLeft: 16
      },
      backgroundColor: (user: UserCore) => user.societe === CODE_SOCIETE_HOUPIEZ ? colors.activeTextHoupiez : colors.jaune,
      "&$listItem, &$listItem.Mui-selected, &$listItem:hover, &$listItem.Mui-selected:hover,$bottomNavigationAction, &$bottomNavigationAction.Mui-selected, &$bottomNavigationAction:hover, &$bottomNavigationAction.Mui-selected:hover  ": {
        backgroundColor: (user: UserCore) => user.societe === CODE_SOCIETE_HOUPIEZ ? colors.activeTextHoupiez : colors.jaune,
      }
    },
    transactionListItem: {
      height: 62,
      padding: 0
    }
  }))

const Menu: React.FC = () => {
  const user: User = useCurrentUser();
  const contexte = useContexte();

  const { margeContainer } = useGlobalStyles();
  const { bottomNavigation, bottomNavigationAction, list, paper, listItem } = useStyles(user.user);

  const { openedSousMenus } = useSelector((state: RootState) => state.menu);

  const mobileMode = useMobileMode();
  const { home } = user.user.societe === CODE_SOCIETE_HOUPIEZ ? images.menu.normal : images.menu.normal;

  const menuItemComplements: MenuItemComplement = {
    ACCUEIL: { img: home, pages: ["Accueil"] },
    TAGS: {img: home,pages:["Tags"]},
    ENQUETES: {img: home,pages:["Enquetes"]},
    MESENQUETES: {img: home,pages:["MesEnquetes"]},
    ACTUS: {img: home,pages:["Actualites"]},
    UTILISATEURS: {img: home,pages:["Users"]},
    LESACTUS: {img: home,pages:["LesActus"]},
    MONCOMPTE: {img: home,pages:["MonCompte"]},
    MESSAGERIE: {img: home,pages:["Messagerie"]},
    SUJET: {img: home,pages:["Sujets"]}
  }

  const { menus } = user;
  const [actif, setActif] = useState("");
  const [sousMenuOpen, setSousMenuOpen] = useState(false);
  const initPage = useLocation().pathname;
  const dispatch = useDispatch()

  useEffect(() => {
    const initMenu = Object.keys(menuItemComplements).find(key => menuItemComplements[key].pages.includes(initPage.substr(initPage.lastIndexOf("/") + 1)));
    setActif(initMenu ?? "");
  }, [initPage, menuItemComplements]);

  const changePage = (code: string) => {
    setActif(code);
    const { type, target, url } = menus.find(m => m.code === code);
    switch (type) {
      case "INTERNE": {
        const page = menuItemComplements[code].pages[0];
        dispatch(push(PATH + "/" + page));
        break;
      }
      case "MENU": {
        setSousMenuOpen(true);
        break;
      }
      case "EXTERNE": {
        if (target === "BLANK") {
          window.open(url, "_blank");
        } else {
          const page = menuItemComplements[code].pages[0];
          dispatch(push(PATH + "/" + page));
        }
        break;
      }
    }
  }

  let menuPrincipal;
  if (mobileMode) {
    const bottomNavigationActions = menus.filter(menu => menu.contexte === contexte).map(menu => {
      const { code, libelle } = menu;
      return <BottomNavigationAction 
                key={code} 
                selected={actif === code} 
                className={bottomNavigationAction}  
                label={libelle} 
                showLabel={true} 
                value={code} 
                onClick={() => changePage(code)}
                />;
    });

    menuPrincipal = (
      <BottomNavigation value={actif}  showLabels={true} className={clsx(bottomNavigation, margeContainer)} >
        {bottomNavigationActions}
      </BottomNavigation>
    );
  } else {
    const drawerListItems = menus.filter(menu => menu.contexte === contexte).map(menu => {
      const { code, libelle } = menu;

      return <ListItem selected={actif === code} button key={code} className={listItem} onClick={() => changePage(code)}>
          <ListItem style={{paddingLeft:1,paddingRight:1,alignItems:"center"}}><Typography variant="h6"  style={{ color: colors.blanc,alignContent:"center" }} dangerouslySetInnerHTML={{ __html:libelle}}></Typography></ListItem>
        </ListItem>;
    });

    menuPrincipal = (
      <Drawer
        variant="permanent"
        anchor="left"
        open={true}
        classes={{ paper: paper }}
        PaperProps={{ elevation: 12 }}
      >
        <List className={list}>
          {drawerListItems}
        </List>
      </Drawer>
    );
  }

  const menuItemMenu = menus.find(mi => mi.code === "MENUS");

  return (
    <>
      {menuPrincipal}
      <SousMenu
        menu={menuItemMenu}
        open={sousMenuOpen}
        onClose={() => setSousMenuOpen(false)}
        openedSousMenu={openedSousMenus}
      />
    </>
  );
}

export default Menu