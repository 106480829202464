import 'reactjs-popup/dist/index.css';
import { Box, Button, Card, CardContent, Grid, makeStyles, Paper, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGlobalStyles } from '../../style/globalStyles';
import { isEmpty, isEmptyVal, useMobileMode } from '../../utils/utils';
import { TableEnquetes } from './MaterialTableEnquetes';
import { TableDataEnquete } from './MaterialTableEnquetesData';
import colors from '../../style/colors';
import { Commentaire, PublicationTBD, EnqueteDataReponse, EnqueteEdit, EnquetePanelisteDetailDto } from './EnqueteType';
import { enregistreImage, fetchListCommentaires, fetchListEnquetesTBD, fetchSaveEnquete, fetchSendnotification, getdataoperation, getPanelisteEnqueteDetail } from './PublicationApi';
import PageTemplate from '../../page/PageTemplate';
import { NavElement, ChangeHandler } from '../../utils/types';
import EnqueteParam from './EnqueteParam';
import EnteteDetailEnquete from "./EnteteDetailEnquete";
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { TableDetailPanelisteEnquete } from "./MaterialTableEnquetesDetailPaneliste";
import EnqueteSendUpFrame from './EnqueteSendupFrame';
import { useCurrentUser } from '../auth/authApi';
import DetailNotification from './MaterialTableNotification';

const NAV_ELEMENTS: NavElement[] = [{ name: "Enquetes", path: "/Enquetes" }];


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10
  },
  card: {
    minWidth: "300px"
  },
  divider: {
    margin: "20px 0",
    backgroundColor: colors.noir + "! important"
  }
}))

const EnqueteAdministrationPage = () => {
  const user = useCurrentUser();
  const { paper, card } = useStyles();
  const dispatch = useDispatch();
  const [stepParamEnquete, setStepParamEnquete] = useState<number>(0);
  const globalClasses = useGlobalStyles();
  const { mobileSpacing } = globalClasses;
  const mobileMode = useMobileMode();
  const [listeEnquete, setListeEnquete] = useState<PublicationTBD[]>(null);
  const [listeCommentaire, setListeCommentaire] = useState<Commentaire[]>(null);
  const [datasEnquete, setdatasEnquete] = useState<EnqueteDataReponse>(null);
  const [datasEnqueteFiltered, setdatasEnqueteFiltered] = useState<EnqueteDataReponse>(null);
  const [listDetailPaneliste, setListDetailPaneliste] = useState<EnquetePanelisteDetailDto[]>(null);
  const [listDetailPanelisteFiltered, setListDetailPanelisteFiltered] = useState<EnquetePanelisteDetailDto[]>(null);
  const [enqueteSel, setEnqueteSel] = useState<PublicationTBD>(null);
  const [panelisteDetailSelected, setPanelisteDetailSelected] = useState<EnquetePanelisteDetailDto>(null);
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);
  const [filtreUtilisateurs, setFiltreUtilisateurs] = useState<string>("PANELISTE");
  const [listeCommentaireFiltre, setListeCommentaireFiltre] = useState<Commentaire[]>(null);
  const [detailNotificationUser, setDetailNotificationUser] = useState<number>(null);

  const [enregistrementEncours, setEnregistrementEncours] = useState<boolean>(false);

  const [afficheLibData, setAfficheLibData] = useState<boolean>(false);
  const onToggleCheckSMS: ChangeHandler = () => { setAfficheLibData(!afficheLibData); }

  const handleChangeFiltreUtilisateur: ChangeHandler = e => {
    const target = e.target
    setFiltreUtilisateurs(target.value);
  }

  useEffect(() => {
    if(listeCommentaire!== null && filtreUtilisateurs !== "TOUS"){
      const filterList: Commentaire[] = listeCommentaire.filter( commentaire => commentaire.typeUser === filtreUtilisateurs);
      setListeCommentaireFiltre(filterList)
    }else{
      setListeCommentaireFiltre(listeCommentaire)
    }
  }, [filtreUtilisateurs,listeCommentaire]);

  useEffect(() => {
    if(listDetailPaneliste!== null && filtreUtilisateurs !== "TOUS"){
      const filterList: EnquetePanelisteDetailDto[] = listDetailPaneliste.filter( detailPaneliste => detailPaneliste.typeUser === filtreUtilisateurs);
      setListDetailPanelisteFiltered(filterList)
    }else{
      setListDetailPanelisteFiltered(listDetailPaneliste)
    }
  }, [filtreUtilisateurs,listDetailPaneliste]);

  useEffect(() => {
    if(datasEnquete!== null && datasEnquete.datas !== null && filtreUtilisateurs !== "TOUS"){
      const filterList: EnqueteDataReponse = {...datasEnquete};
      filterList.datas = datasEnquete.datas.filter(enqueteResult => enqueteResult.datas.some(enqueteData => enqueteData.code === "TYPE_USER" && enqueteData.valeur === filtreUtilisateurs))
      setdatasEnqueteFiltered(filterList)
    }else{
      setdatasEnqueteFiltered(datasEnquete)
    }
  }, [filtreUtilisateurs,datasEnquete]);

  function initEnquetes(data: PublicationTBD[]) {
    if (data) {
      setListeEnquete(data)
    }
  }

  function loadCommentaire(data: Commentaire[]) {
    if (data) {
      setListeCommentaire(data)
    }
  }

  useEffect(() => {
    const isAdmin = user.isAdmin;
    setUserIsAdmin(isAdmin)
  }, [user]);


  useEffect(() => {
    dispatch(fetchListEnquetesTBD(initEnquetes))
  }, []);

  function fctCommentaires(enquete: PublicationTBD) {
    setEnqueteSel(enquete);
    dispatch(fetchListCommentaires(enquete.id, loadCommentaire))
    setStepParamEnquete(1)
  }

  function fctCreerEnquete() {
    setStepParamEnquete(2)
  }

  function fctAnnuler() {
    setFiltreUtilisateurs("PANELISTE");
    setStepParamEnquete(0);
    setEnqueteSel(null);
  }

  function fctRetourDetailPaneliste() {
    setStepParamEnquete(6);
    setDetailNotificationUser(null)
  }

  function fctDetailNotification(enquetePaneliste: EnquetePanelisteDetailDto) {
    if(enquetePaneliste.lastNotification !== null && enquetePaneliste.lastNotification !== undefined){
      setStepParamEnquete(61);
      setDetailNotificationUser(enquetePaneliste.idUsersConvention);
    }
  }

  function fctDatas(enquete: PublicationTBD) {
    function initData(data: EnqueteDataReponse) {
      setdatasEnquete(data);
      setStepParamEnquete(4);
    }
    setEnqueteSel(enquete);
    setAfficheLibData(false);
    dispatch(getdataoperation(enquete.id, initData))
  }

  function fctDetailPaneliste(enquete: PublicationTBD) {
    function initData(data: EnquetePanelisteDetailDto[]) {
      setListDetailPaneliste(data);
      setStepParamEnquete(6);
    }
    setEnqueteSel(enquete);
    dispatch(getPanelisteEnqueteDetail(enquete.id, initData))
  }

  function fctEnregistrementEnqueteSuccess(data: boolean) {
    if (data) {
      dispatch(fetchListEnquetesTBD(initEnquetes))
      setStepParamEnquete(0);
      setEnqueteSel(null);
    }
    setEnregistrementEncours(false);
  }

  function fctSendNotification(enqueteId: number){
    
    dispatch(fetchSendnotification(enqueteId,fctEnregistrementEnqueteSuccess));
  }

  function fctEnregisterEnquete(enquete: EnqueteEdit, imageFile: File, saveImage: boolean) {
    function fctUploadImage(enqueteId: number) {
      if (enqueteId !== null && saveImage) {
        dispatch(enregistreImage(imageFile, enquete.type, enqueteId, () => {fctSendNotification(enqueteId)}));
      } else if (enqueteId !== null) {
        fctSendNotification(enqueteId)
      }
    }
    setEnregistrementEncours(true);
    dispatch(fetchSaveEnquete(enquete, fctUploadImage))
  }

  function fctEdit(enquete: PublicationTBD) {
    setEnqueteSel(enquete);
    setStepParamEnquete(2);
  }

  useEffect(() => {
    {/* LORSQUE L'ON REVIENT SUR LA TABLEAU PRINCIPAL ON REINIT LES VARIABLES ANNEXE*/ }
    if (stepParamEnquete === 0) {
      setEnqueteSel(null);
      setListeCommentaire([]);
      setListDetailPaneliste([]);
      setPanelisteDetailSelected(null);
    }

  }, [stepParamEnquete]);

  function showSheet(data: EnquetePanelisteDetailDto) {
    if (data.propSendup && data.propSendup.url) {
      setPanelisteDetailSelected(data);
      setStepParamEnquete(7);
    }
  }

  function fctRetourPaneliste() {
    if (enqueteSel) {
      fctDetailPaneliste(enqueteSel);
    }
  }

  return (
    <PageTemplate elements={NAV_ELEMENTS}>
      <Grid container spacing={mobileMode ? 0 : 10}>
        <Grid item xs={12} sm={9} className={mobileSpacing}>
          {stepParamEnquete == 0 && (
            <>
              <Grid item xs={12} sm={12}>
                <Typography variant="h2" gutterBottom >Tableau de bord Enquêtes</Typography>
              </Grid>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12} sm={8}>
                  <Button fullWidth variant="contained" color="primary" disabled={!userIsAdmin} onClick={fctCreerEnquete}>Publier une nouvelle enquête</Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} className={mobileSpacing}>
                <TableEnquetes fctEdit={fctEdit} fctCommetaires={fctCommentaires} fctDatas={fctDatas} fctDetailPaneliste={fctDetailPaneliste} values={listeEnquete}></TableEnquetes>
              </Grid>
            </>
          )}
          {stepParamEnquete == 1 && enqueteSel && (
            <>
              <EnteteDetailEnquete typePubTitle={"Enquête"} publication={enqueteSel} titre={"Commentaires de l'enquête"} handleChangeFiltreUtilisateur={handleChangeFiltreUtilisateur} filtreUtilisateurs={filtreUtilisateurs} fctAnnuler={fctAnnuler}></EnteteDetailEnquete>
              <Paper className={paper}>
                <Grid container>
                  <Grid item xs={12} sm={12} className={mobileSpacing}>
                    {listeCommentaireFiltre?.map((commentaire: Commentaire) => (
                      <Card className={card} style={{ margin: "5px" }}>
                        <CardContent>
                          <Grid container>
                            {commentaire.note >= 0 && mobileMode &&
                              (
                                <>
                                  <Grid item xs={12}>
                                    <Box style={{ textAlign: "right" }}>
                                      <Rating
                                        name="customized-icons"
                                        defaultValue={commentaire.note}
                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                        size={mobileMode ? "small" : "medium"}
                                        readOnly={true}
                                      />
                                    </Box>
                                  </Grid>
                                </>
                              )}
                            <Grid item xs={12} sm={10}>
                              <Typography variant="h3" component="h2">
                                {commentaire.userName}
                              </Typography>
                            </Grid>
                            {commentaire.note >= 0 && !mobileMode &&
                              (
                                <>
                                  <Grid item xs={12} sm={2}>
                                    <Box style={{ textAlign: "right" }}>
                                      <Rating
                                        name="customized-icons"
                                        defaultValue={commentaire.note}
                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                        size={mobileMode ? "small" : "medium"}
                                        readOnly={true}
                                      />
                                    </Box>
                                  </Grid>
                                </>
                              )}
                          </Grid>
                          <Typography variant="h5" component="h2">
                            {commentaire.userRaisonSociale}
                          </Typography>
                          <Typography variant="body2" component="p" dangerouslySetInnerHTML={{ __html: (!isEmptyVal( commentaire.commentaire)&&!isEmpty(commentaire))?commentaire.commentaire.replace("\n", "<BR>"):"" }} style={{ paddingTop: "15px" }}>
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {stepParamEnquete == 2 && (
            <EnqueteParam publication={enqueteSel} fctAnnuler={fctAnnuler} fctEnregistrer={fctEnregisterEnquete} enregistrementEncours={enregistrementEncours} typePublication="ENQUETE" />
          )}

          {/* AFFICHAGE DES DATAS DE L'ENQUETE*/}
          {stepParamEnquete == 4 && enqueteSel && (
            <>
              <EnteteDetailEnquete typePubTitle={"Enquête"} publication={enqueteSel} titre={"Données de l'enquête"} handleChangeFiltreUtilisateur={handleChangeFiltreUtilisateur} filtreUtilisateurs={filtreUtilisateurs} fctAnnuler={fctAnnuler}></EnteteDetailEnquete>
              <Grid item xs={12} sm={12} >
                <FormControlLabel control={<Checkbox
                  color="primary"
                  checked={afficheLibData}
                  onChange={onToggleCheckSMS}
                />}
                  label="Afficher les libellés des questions" />
              </Grid>
              <Grid item xs={12} sm={12} className={mobileSpacing}>
                {datasEnqueteFiltered && datasEnqueteFiltered.datas && (
                  <>
                    <TableDataEnquete values={datasEnqueteFiltered.datas} entetes={datasEnqueteFiltered.entetes} afficheLibData={afficheLibData} enquete={enqueteSel}></TableDataEnquete>
                  </>
                )}

              </Grid>
            </>
          )}
          {/* AFFICHAGE DU DETAIL PAR PANELISTE*/}
          {stepParamEnquete == 6 && enqueteSel && (
            <>
              <EnteteDetailEnquete typePubTitle={"Enquête"} publication={enqueteSel} titre={"Détail Panéliste"} handleChangeFiltreUtilisateur={handleChangeFiltreUtilisateur} filtreUtilisateurs={filtreUtilisateurs} fctAnnuler={fctAnnuler}></EnteteDetailEnquete>
              <Grid item xs={12} sm={12} className={mobileSpacing}>
                {listDetailPanelisteFiltered && (
                  <>
                    <TableDetailPanelisteEnquete values={listDetailPanelisteFiltered} type={"ENQUETE"} showSheet={showSheet} fctDetailNotification={fctDetailNotification}></TableDetailPanelisteEnquete>
                  </>
                )}
              </Grid>
            </>
          )}
          {/* AFFICHAGE DU DETAIL PAR PANELISTE*/}
          {stepParamEnquete == 61 && enqueteSel && (
            <>
              <DetailNotification typePubTitle={"Enquête"} publication={enqueteSel} idUsersConvention={detailNotificationUser} fctAnnuler={fctRetourDetailPaneliste} />
            </>
          )}
          {/* AFFICHAGE DU DETAIL PAR PANELISTE*/}
          {stepParamEnquete == 7 && enqueteSel && (
            <>
              <EnteteDetailEnquete typePubTitle={"Enquête"} handleChangeFiltreUtilisateur={handleChangeFiltreUtilisateur} filtreUtilisateurs={filtreUtilisateurs} hiddeFiltre={true} publication={enqueteSel} titre={panelisteDetailSelected.codeTiers + " / " + panelisteDetailSelected.nom + " / " + panelisteDetailSelected.raisonSociale} fctAnnuler={fctRetourPaneliste}></EnteteDetailEnquete>
              <Grid item xs={12} sm={12} className={mobileSpacing}>
                <EnqueteSendUpFrame enquete={panelisteDetailSelected.propSendup} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid >
    </PageTemplate>

  );
}

export default EnqueteAdministrationPage;


