import React, { useEffect, useState } from 'react';
import DialogNotification, { DialogNotificationObject } from '../../page/DialogNotification';
import favicon from '../../img/logo/AgriSKop_Responsive_192.png'
import installIos from '../../img/logo/install_ios.png'
import { isIOSafari } from '../../utils/utils';


const IosNotification = () => {
    const [notification, setNotification] = useState<DialogNotificationObject>(null);
    const [openPopup, setOpenPopup] = useState<boolean>(false);


    function initNotification() {
        const notif: DialogNotificationObject = {
            title: "Ajoutez Agriscoop à votre écran d'accueil",
            image: favicon,
            body: "Accéder simplement à agriscoop depuis votre écran d'accueil ouvrir dans Safari le menu partage puis sélectionnez 'Sur l'écran d'accueil'",
            imageBody: installIos,
        }
        setNotification(notif)
    }
    
    function canShow() {
        if (sessionStorage.getItem("seenPopUpPwa") || localStorage.getItem("seenPopUpPwa") || window.matchMedia('(display-mode: standalone)').matches) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        if (isIOSafari() && canShow()) {
            setOpenPopup(true)
            initNotification()
        }
    }, []);

    const handleClose = () => {
        setOpenPopup(false)
        sessionStorage.setItem("seenPopUpPwa", "true");
    }

    const handleCloseDefinitive = () => {
        setOpenPopup(false)
        localStorage.setItem("seenPopUpPwa", "true");
    }

    return (
        <>
            {(notification && openPopup) && (
                <>
                    <DialogNotification onClose={handleCloseDefinitive} onConfirm={handleClose} confirmText={"OK"} closeText={"ne plus demander"} notification={notification} />
                </>
            )}
        </>
    );
};

export default IosNotification;
