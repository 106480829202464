import { createSlice } from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit';
import { PATH } from '../../utils/constants';
import { Response } from '../../utils/types';


export interface ErrorOptions {
  redirectionPath?: string;
  reload?: boolean;
  params?: unknown[];
}

export interface Error {
  errorLabel?: string;
  detail?: string;
  error?: number;
  response?: Response<unknown>;
  options?: ErrorOptions;
}

export interface ErrorState {
  open: boolean;
  errorLabel: string;
  detail: string;
  error?: number;
  response?: Response<unknown>;
  options?: ErrorOptions;
}

const initialState: ErrorState = {
  open: false,
  errorLabel: null,
  detail: null,
  error: null,
  response: null,
  options: null
}

const error = createSlice(
  {
  name: "error",
  initialState,
  reducers: {
    resetErrorState: state => {
      Object.assign(state, initialState);
    },
    showError: {
      reducer: (state, action: PayloadAction<Error>) => {
        state.open = true;
        Object.assign(state, action.payload);
      },
      prepare: (err: Error) => {
        if (err.detail === "Expired") {
          err.errorLabel = "Session expirée";
          err.error = 440;
          err.detail = "Veuillez vous reconnecter";
          err.options = { redirectionPath: PATH + "/Login" };
        }
        return { payload: err };
      }
    },
    hideError(state) {
      state.open = false
    }
  }
})

export const { showError, hideError, resetErrorState } = error.actions;

export default error.reducer;