import 'reactjs-popup/dist/index.css';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {  isEmptyVal, useMobileMode } from '../../utils/utils';
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts';
import { PublicationKpi, PublicationSelect } from './AccueilType';
import { fetchKpiPublication, fetchLastPublication, fetchListActus, fetchListEnquetes, fetchPublicationLibelle } from './AccueilApi';
import { MySelectField } from '../../components/MyTextField';
import { ChangeHandler } from '../../utils/types';


export interface RoundedChartProps {
  typePublication: string;
}

const RoundedChart = (props: RoundedChartProps) => {
  const { typePublication } = props;

  const dispatch = useDispatch();
  const mobileMode = useMobileMode();
  const [publicationKpi, setPublicationKpi] = useState<PublicationKpi>(null);
  const [tauxParticipation, setTauxParticipation] = useState<number>(0);
  const [listePublication, setListePublication] = useState<PublicationSelect[]>([]);
  const [libellePublication, setLibellePublication] = useState<string>("");

  function initPublication(data: PublicationKpi) {
    if (data) {
      setPublicationKpi(data);
    }
  }

  const handleChangeSelect: ChangeHandler = e => {
    const target = e.target
    const idPub = Number(target.value);
    dispatch(fetchKpiPublication(idPub, initPublication));
  }

 

  function initSelect(data: PublicationSelect[]) {
    if (data) {
      setListePublication(data);
    }
  }

  function initLibelle(data: string){
    if(data){
      setLibellePublication(data);
    }else{
      setLibellePublication(typePublication.toLowerCase());
    }
  }

  useEffect(() => {
    if (publicationKpi !== null) {

      if (publicationKpi.nombreAction != null && publicationKpi.nombreAffecte !== null) {
        const moyenne: number = Math.round(((publicationKpi.nombreAction * 100) / publicationKpi.nombreAffecte));
        if(isEmptyVal(moyenne)){
          setTauxParticipation(0)
        }else{
          setTauxParticipation(moyenne);
        }
        
      }
    }
  }, [publicationKpi]);

  useEffect(() => {
    dispatch(fetchLastPublication(typePublication, initPublication));
    if (typePublication === "ENQUETE") {
      dispatch(fetchListEnquetes(initSelect));
    }
    if (typePublication === "ACTUALITE") {
      dispatch(fetchListActus(initSelect));
    }

    dispatch(fetchPublicationLibelle(typePublication,initLibelle));
  }, []);


  return (
    <Grid container>
      {publicationKpi && (
        <>
          <Grid item xs={12}>
            <Typography variant="h3" align="center" gutterBottom>{"Récapitulatif " + libellePublication}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" gutterBottom>{publicationKpi.titre}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <DonutChart mobile={mobileMode} value1={publicationKpi.nombreAction} value2={publicationKpi.nombreAffecte - publicationKpi.nombreAction} couleurs={tauxParticipation >= 50 ? ["#11CD5C", "#D2F9D7"] : ["#ff9f31", "#ffbf8b"]} />
          </Grid>
          {listePublication !== null && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5" align="left" style={{ margin: "5px" }}>{"Sélectionner une " + typePublication.toLowerCase() +" : "}</Typography>
              </Grid>
              <Grid item xs={10}>
                <MySelectField
                  value={publicationKpi.id}
                  style={{ margin: "5px" }}
                  name="selectPublication"
                  onChange={handleChangeSelect}
                  size="small"
                >
                  {listePublication?.map((publication: PublicationSelect) => (
                    <MenuItem key={publication.id} value={publication.id}>{publication.titre}</MenuItem>
                  ))}
                </MySelectField>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="h5" align="left" style={{ margin: "5px" }}>{"Publiée le : " + publicationKpi.dateDebut}</Typography>
          </Grid>
          {publicationKpi.type === "ENQUETE" && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5" align="left" style={{ margin: "5px" }}>{"Statut : " + publicationKpi.statutLibelle}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="left" style={{ margin: "5px" }}>{"Taux de participation : " + tauxParticipation + "%"}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="left" style={{ margin: "5px" }}>{"Taux de statisfaction : " + publicationKpi.note}</Typography>
              </Grid>
            </>
          )}
          {publicationKpi.type === "ACTUALITE" && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5" align="left" style={{ margin: "5px" }}>{"Statut : " + publicationKpi.statutLibelle}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="left" style={{ margin: "5px" }}>{"Taux d'ouverture : " + tauxParticipation + "%"}</Typography>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}

const DonutChart = (props: {
  couleurs: string[];
  value1: number;
  value2: number;
  mobile: boolean;
}) => {
  const { couleurs, value1, value2 } = props;

  const options: ApexOptions = {
    chart: {
      height: 80,
      type: 'donut',
      id: "donutChart"
    },
    dataLabels: { enabled: false },
    tooltip: { enabled: false },
    legend: { show: false },
    colors: couleurs,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
            },
            total: {
              show: true
              , fontSize: "12px"
              , color: '#373d3f'
              , fontFamily: 'Helvetica, Arial, sans-serif'
              , showAlways: true
              , label: "test"
              , formatter: function (w: any) {
                return w.globals.series[0] + "/" + (w.globals.series[1] + w.globals.series[0]);
              }
            }
          }
        }
      }
    }
  };

  return (
    <>
      <Grid item>
        <Chart
          id='donutChart'
          series={[value1 ? value1 : 0, value2 ? value2 : 0]}
          options={options}
          type='donut'
        />
      </Grid>
    </>
  );
}

export default RoundedChart;