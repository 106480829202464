import 'reactjs-popup/dist/index.css';
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGlobalStyles } from '../../style/globalStyles';
import { useMobileMode } from '../../utils/utils';
import { PublicationTBD, EnqueteEdit, EnquetePanelisteDetailDto } from './EnqueteType';
import { enregistreImage, fetchListActusTBD, fetchSaveEnquete, fetchSendnotification, getPanelisteEnqueteDetail } from './PublicationApi';
import PageTemplate from '../../page/PageTemplate';
import { ChangeHandler, NavElement } from '../../utils/types';
import EnqueteParam from './EnqueteParam';
import { TableActus } from './MaterialTableActu';
import { useCurrentUser } from '../auth/authApi';
import { TableDetailPanelisteEnquete } from './MaterialTableEnquetesDetailPaneliste';
import DetailNotification from './MaterialTableNotification';
import EnteteDetailEnquete from './EnteteDetailEnquete';

const NAV_ELEMENTS: NavElement[] = [{ name: "Actualites", path: "/Actualites" }];

const ActuAdministrationPage = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const [stepParamActu, setStepParamActu] = useState<number>(0);
  const globalClasses = useGlobalStyles();
  const { mobileSpacing } = globalClasses;
  const mobileMode = useMobileMode();
  const [listeActus, setListeActus] = useState<PublicationTBD[]>(null);
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);
  const [detailNotificationUser, setDetailNotificationUser] = useState<number>(null);
  const [listDetailPaneliste, setListDetailPaneliste] = useState<EnquetePanelisteDetailDto[]>(null);
  const [listDetailPanelisteFiltered, setListDetailPanelisteFiltered] = useState<EnquetePanelisteDetailDto[]>(null);
  const [filtreUtilisateurs, setFiltreUtilisateurs] = useState<string>("PANELISTE");

  const [actusSel, setActusSel] = useState<PublicationTBD>(null);

  const [enregistrementEncours, setEnregistrementEncours] = useState<boolean>(false);

  const handleChangeFiltreUtilisateur: ChangeHandler = e => {
    const target = e.target
    setFiltreUtilisateurs(target.value);
  }

  useEffect(() => {
    if(listDetailPaneliste!== null && filtreUtilisateurs !== "TOUS"){
      const filterList: EnquetePanelisteDetailDto[] = listDetailPaneliste.filter( detailPaneliste => detailPaneliste.typeUser === filtreUtilisateurs);
      setListDetailPanelisteFiltered(filterList)
    }else{
      setListDetailPanelisteFiltered(listDetailPaneliste)
    }
  }, [filtreUtilisateurs,listDetailPaneliste]);


  function initActus(data: PublicationTBD[]) {
    if (data) {
      setListeActus(data)
    }
  }

  function fctDetailNotification(enquetePaneliste: EnquetePanelisteDetailDto) {
    if(enquetePaneliste.lastNotification !== null && enquetePaneliste.lastNotification !== undefined){
      setStepParamActu(4);
      setDetailNotificationUser(enquetePaneliste.idUsersConvention);
    }
  }

  function fctRetourDetailPaneliste() {
    setStepParamActu(3);
    setDetailNotificationUser(null)
  }


  useEffect(() => {
    const isAdmin = user.isAdmin;
    setUserIsAdmin(isAdmin)
  }, [user]);

  useEffect(() => {
    dispatch(fetchListActusTBD(initActus))
  }, []);

  function fctCreerActus() {
    setStepParamActu(2)
  }

  function fctAnnuler() {
    setStepParamActu(0);
    setActusSel(null);
  }

  function fctEnregistrementActuSuccess(data: boolean) {
    if (data) {
      dispatch(fetchListActusTBD(initActus))
      setStepParamActu(0);
      setActusSel(null);
    }
    setEnregistrementEncours(false);
  }

  function fctSendNotification(actusId: number){ 
    dispatch(fetchSendnotification(actusId,fctEnregistrementActuSuccess));
  }

  function fctEnregisterActu(actus: EnqueteEdit, imageFile: File, saveImage: boolean) {
    function fctUploadImage(actusId: number) {
      if (actusId !== null && saveImage) {
        dispatch(enregistreImage(imageFile, actus.type, actusId, () => {fctSendNotification(actusId)}));
      } else if (actusId !== null) {
        fctSendNotification(actusId)
      }
    }
    setEnregistrementEncours(true);
    dispatch(fetchSaveEnquete(actus, fctUploadImage))
  }

  function fctDetailPaneliste(enquete: PublicationTBD) {
    function initData(data: EnquetePanelisteDetailDto[]) {
      setListDetailPaneliste(data);
      setStepParamActu(3);
    }
    setActusSel(enquete);
    dispatch(getPanelisteEnqueteDetail(enquete.id, initData))
  }

  function fctEdit(actus: PublicationTBD) {
    setActusSel(actus);
    setStepParamActu(2);
  }

  useEffect(() => {
    {/* LORSQUE L'ON REVIENT SUR LA TABLEAU PRINCIPAL ON REINIT LES VARIABLES ANNEXE*/ }
    if (stepParamActu === 0) {
      setActusSel(null);
    }

  }, [stepParamActu]);

  return (
    <PageTemplate elements={NAV_ELEMENTS}>
      <Grid container spacing={mobileMode ? 0 : 10}>
        <Grid item xs={12} sm={9} className={mobileSpacing}>
          {stepParamActu == 0 && (
            <>
              <Grid item xs={12} sm={12}>
                <Typography variant="h2" gutterBottom >Tableau de bord Actualités</Typography>
              </Grid>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12} sm={8}>
                  <Button fullWidth variant="contained" color="primary" disabled={!userIsAdmin} onClick={fctCreerActus}>Publier une nouvelle Actualités</Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} className={mobileSpacing} >
                <TableActus fctEdit={fctEdit} values={listeActus} fctDetailPaneliste={fctDetailPaneliste}></TableActus>
              </Grid>
            </>
          )}
          {stepParamActu == 2 && (
            <EnqueteParam publication={actusSel} fctAnnuler={fctAnnuler} fctEnregistrer={fctEnregisterActu} enregistrementEncours={enregistrementEncours} typePublication="ACTUALITE" />
          )}
          {stepParamActu == 3 && actusSel && (
            <>
              <EnteteDetailEnquete publication={actusSel} typePubTitle={"Actualité"} titre={"Détail Panéliste"} handleChangeFiltreUtilisateur={handleChangeFiltreUtilisateur} filtreUtilisateurs={filtreUtilisateurs} fctAnnuler={fctAnnuler}></EnteteDetailEnquete>
              <TableDetailPanelisteEnquete values={listDetailPanelisteFiltered} type={"ACTUALITE"} fctDetailNotification={fctDetailNotification} showSheet={null}></TableDetailPanelisteEnquete>
            </>
          )}
          {stepParamActu == 4 && (
            <DetailNotification typePubTitle={"Actualité"} publication={actusSel} idUsersConvention={detailNotificationUser} fctAnnuler={fctRetourDetailPaneliste} />
          )}
        </Grid>
      </Grid >
    </PageTemplate>

  );
}

export default ActuAdministrationPage;


