
import { AppThunk } from "../../app/store";
import { simpleGet, simpleLoaderGet, simpleLoaderPost, simplePost} from "../../services/restUtils";
import { Conversation, ConversationCreate, CreateMessage, MenuConversation, Message, Sujet, UserSujetDto } from "./MessagerieType";

export const fetchSujets = (funcSuccess: (data: Sujet[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "conversation", "listsujet", "", funcSuccess);
}

export const createConv = (conv: ConversationCreate,funcSuccess: (data: Conversation) => void): AppThunk => async dispatch => {
    simpleLoaderPost(dispatch, "conversation", "createconversation","", conv,funcSuccess);
}

export const fetchConversations = (idUserConvention: number,maxArchive: string,funcSuccess: (data: MenuConversation) => void): AppThunk => async dispatch => {

    const obj = {
        idUserConvention: idUserConvention,
        maxArchive: maxArchive === "TOUS" ? null : maxArchive
    }
    
    simpleLoaderPost(dispatch, "conversation", "getconversations","",obj,funcSuccess);
}

export const createMessage = (message: CreateMessage,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    simpleLoaderPost(dispatch, "conversation", "createmessage","", message,funcSuccess);
}

export const fetchConversation = (idUserConvention: number, idConversation: number,funcSuccess: (data: Conversation) => void): AppThunk => async dispatch => {
    const GetConversationObj = {
        idUsersConvention: idUserConvention,
        idConversation: idConversation
    }
    simpleLoaderPost(dispatch, "conversation", "getconversation","",GetConversationObj,funcSuccess);
}

export const fetchMessages = (idUserConvention: number, idConversation: number, iucConversation: number,funcSuccess: (data: Message[]) => void): AppThunk => async dispatch => {
    const GetMessagesObj = {
        idUsersConvention: idUserConvention,
        idConversation: idConversation,
        iucConversation: iucConversation
    }
    simpleLoaderPost(dispatch, "conversation", "getmessages","",GetMessagesObj,funcSuccess);
}

export const archiveConv = (idConversation: number,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    simpleGet(dispatch, "conversation", "archiveconv",idConversation+"",funcSuccess);
}

export const getNotificationHeader = (idUserConvention: number,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    simpleGet(dispatch, "conversation", "getnotificationheader",idUserConvention+"",funcSuccess);
}

export const fetchSujetsAdmin = (funcSuccess: (data: Sujet[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "conversation", "listsujetadmin", "", funcSuccess);
}

export const saveSujet = (sujet: Sujet,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    simpleLoaderPost(dispatch, "conversation", "savesujet","", sujet,funcSuccess);
}

export const fetchListAllUsers = (funcSuccess: (data: UserSujetDto[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "conversation", "listusers", "", funcSuccess);
}

export const fetchListSujetUsers = (idSujet: number,funcSuccess: (data: number[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "conversation", "listsujetusers", idSujet+"", funcSuccess);
}

export const saveUsersSujet = (listUsers: number[], idSujet: number,funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    const UserSaveSujetObj = {
        idSujet: idSujet,
        listUsers: listUsers
    }
    simpleLoaderPost(dispatch, "conversation", "saveuserssujet","", UserSaveSujetObj,funcSuccess);
}
