
import { AppThunk } from "../../app/store";
import restClient from "../../services/RestClient";
import { simpleLoaderGet, simpleLoaderPost } from "../../services/restUtils";
import { showError } from "../error/errorSlice";
import { Tag, ListeTag, ListeTagType, UserTagAutoDto } from "./TagType";
import { Response } from "../../utils/types";



export const fetchTags = (funcSuccess: (data: ListeTag) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "administration", "tags", "", funcSuccess);
}

export const fetchTagType = (funcSuccess: (data: ListeTagType) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "administration", "tagtype", "", funcSuccess);
}

export const fetchSaveTag = (tag: Tag, funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    const tagObj: Tag = {
        code: tag.code,
        libelle: tag.libelle,
        type: tag.type,
        question: tag.question,
        statut: tag.statut,
        typeLibelle: "",
        statutLibelle: ""
    };
    simpleLoaderPost(dispatch, "administration", "enregistrementtag", "", tagObj, funcSuccess);
}

export const fetchTagUserAuto = (codeTag: string, funcSuccess: (data: UserTagAutoDto[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "administration", "getUserTagAuto", codeTag + "", funcSuccess);
}

export const codeTierExiste = (codeTiers: string, funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "users", "getCodeTiersExiste", codeTiers + "", funcSuccess);
}

export const addUserTagAuto = (codeTag: string, codeTiers: string, funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "administration", "addUserTagAuto", codeTag + "/" + codeTiers + "", funcSuccess);
}

export const disableUserTagAuto = (codeTag: string, codeTiers: string, funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "administration", "disableUserTagAuto", codeTag + "/" + codeTiers + "", funcSuccess);
}

export const addListCodeTiersWithFile = (selectedFile: File, codeTag: string, funcSuccess: (data: string[]) => void): AppThunk => async dispatch => {

    const formData = new FormData();
    formData.append('File', selectedFile, selectedFile.name);

    fetch(
        restClient.baseUrl + "/uploadfile/addCodeTiers/" + codeTag,
        {
            method: 'POST',
            body: formData,
        }
    )
        .then((response) => response.json())
        .then((result: Response<string[]>) => {
            funcSuccess(result.data);
        })
        .catch((error) => {
            dispatch(showError({ errorLabel: "Erreur", detail: error, options: null }));
        });
}

export const mergeListCodeTiersWithFile = (selectedFile: File, codeTag: string, funcSuccess: (data: string[]) => void): AppThunk => async dispatch => {

    const formData = new FormData();
    formData.append('File', selectedFile, selectedFile.name);

    fetch(
        restClient.baseUrl + "/uploadfile/mergeCodeTiers/" + codeTag,
        {
            method: 'POST',
            body: formData,
        }
    )
        .then((response) => response.json())
        .then((result: Response<string[]>) => {
            funcSuccess(result.data);
        })
        .catch((error) => {
            dispatch(showError({ errorLabel: "Erreur", detail: error, options: null }));
        });
}

export const addUserListTags = (listCodesTags: string[], codeTiers: string, funcSuccess: (data: boolean) => void): AppThunk => async dispatch => {
    const obj = {
        listCodesTags: listCodesTags,
        codeTiers: codeTiers
    };
    simpleLoaderPost(dispatch, "administration", "addUserTagAuto","",obj, funcSuccess);
}

export const listTagUser = (codeTiers: string, funcSuccess: (data: Tag[]) => void): AppThunk => async dispatch => {
    simpleLoaderGet(dispatch, "administration", "listTagUser", codeTiers + "", funcSuccess);
}