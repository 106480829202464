import { useMediaQuery, Theme } from "@material-ui/core";
import { useLocation } from "react-router-dom";

export function formatNumber(val: number, precision?: number) {
  return val.toFixed(precision ?? 2).replace(".", ",");
}

export function formatDate(val: Date) {
  return new Date(val).toLocaleDateString();
}

export function formatDateStr(val: string) {
  return new Date(val).toLocaleDateString();
}

export function useMobileMode() {
  return useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
}

export function isEmpty(obj: unknown) {
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }

  return !obj || Object.keys(obj).length === 0;
}

export function isEmptyVal(obj: unknown){
  if(!obj || obj===null || obj===undefined || obj===""){
    return true;
  }
  return false;
}

export function upperFirstLetter(str: string){
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function hasProperty(obj: unknown, key: string | number) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function formatDateStringFrToUs(stringDate: string) {
  const arrayDate: string[] = stringDate.split("/");
  const usDate = arrayDate[1] + '/' + arrayDate[0] + '/' + arrayDate[2];
  return usDate;
}

export function dateRemoveDays(val: Date, days: number) {
  const date = new Date(val)
  date.setDate(date.getDate() - days);
  return date.toLocaleDateString('fr-FR');
}

export function isIOSafari() {
  const userAgent = window.navigator.userAgent;
  const isiOS = /iPad|iPhone|iPod/.test(userAgent);
  const isSafari = /Safari/i.test(userAgent) && /AppleWebKit/i.test(userAgent);
  return isiOS && isSafari;
}

export function stringToDateHour(dateStr: string) {
  const parts = dateStr.split(" ");
  const datePart = parts[0];
  const timePart = parts[1];

  const dateParts = datePart.split("/");
  const jours = parseInt(dateParts[0], 10);
  const mois = parseInt(dateParts[1], 10) - 1;
  const annees = parseInt(dateParts[2], 10);
  let date;

  if(timePart){
    const timeParts = timePart.split(":");
    const heures = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    date = new Date(annees, mois, jours, heures, minutes);
  }else{
    date = new Date(annees, mois, jours);
  }
  
  return date;
}

export function parseDateGetTime(dateStr: string) {
  if (dateStr === " - " || dateStr === "" || dateStr == null || dateStr == undefined) {
    return null;
  } else {
    return stringToDateHour(dateStr).getTime();
  }
}

export function compareDateTime(dateA: number, dateB: number) {
  if (dateA === null && dateB === null) {
    return 0;
  } else if (dateA === null) {
    return 1;
  } else if (dateB === null) {
    return -1;
  }

  return dateB - dateA;
}

export function isMobile() {
  const userAgent = window.navigator.userAgent;
  const isMobile = /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(userAgent);
  return isMobile;
}

export function getHoursMinutes(date: Date){
  const hours = date.getHours();
  const minutes = date.getMinutes();
  let result;

  if(minutes < 10){
    result = hours + "h" +  "0"+ minutes 
  }else{
    result = hours + "h" + minutes;
  }

  return result
}