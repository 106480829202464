import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Grid, Button, DialogContentText, DialogActions, makeStyles } from '@material-ui/core';
import { isEmptyVal } from '../utils/utils';


const useStyles = makeStyles(() => ({
    dialogContainer: {
        "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "flex-end",
        },
    },
    borderBottomCustom: {
        borderBottom : "1px solid #0075b5"
    },
    borderTopCustom: {
        borderTop : "1px solid #0075b5"
    },
}))

export interface DialogNotificationObject {
    title: string;
    body: string;
    image?: string;
    urlRedirect?: string;
    imageBody?: string;
}


interface DialogNotificationProps {
    onClose: () => void;
    onConfirm: () => void;
    confirmText: string;
    closeText: string;
    notification: DialogNotificationObject;
}

const DialogNotification = (props: DialogNotificationProps) => {
    const { onConfirm, onClose, confirmText, closeText, notification } = props;
    const { dialogContainer, borderBottomCustom, borderTopCustom } = useStyles();

    const handleConfirm = () => {
        onConfirm()
    }

    const handleClose = () => {
        onClose()
    }

    return (
        <>
            {notification && (
                <Dialog
                    className={dialogContainer}
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={true}
                >
                    <DialogContent>
                        {notification.title && (
                            <DialogTitle>
                                <Typography variant="h4" align='center' gutterBottom>{notification.title}</Typography>
                            </DialogTitle>
                        )}
                        <DialogContentText color="initial">
                            <Grid container>
                                {!isEmptyVal(notification.image) && (
                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                        <img height={"95px"} src={notification.image} alt="Notification Image" />
                                    </Grid>
                                )}
                                {notification.body && (
                                    <Grid item xs={12} >
                                        <Typography variant="h5" align='center' gutterBottom>{notification.body}</Typography>
                                    </Grid>
                                )}
                                {!isEmptyVal(notification.imageBody) && (
                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                        <img height={"50px"} src={notification.imageBody} alt="Notification Image Body" />
                                    </Grid>
                                )}
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid container alignContent='center'>
                            {onConfirm && (
                                <Grid item xs={12} style={{ textAlign: "center"}} className={onClose ? borderBottomCustom : borderTopCustom}>
                                    <Button key={"OUI"} size='small' onClick={handleConfirm} color="secondary">{confirmText}</Button>
                                </Grid>
                            )}
                            {onClose && (
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Button key={"NON"} size='small' style={{ color: "red" }} onClick={handleClose} color="secondary">{closeText}</Button>
                                </Grid>
                            )}
                        </Grid>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default DialogNotification;
