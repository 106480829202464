import { createStyles, makeStyles } from "@material-ui/core";

export const useGlobalStyles = makeStyles(theme =>
  createStyles({
    margeContainer: {
      padding: 0,
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 5),
        "&.MuiTextField-root label": {
          marginLeft: 15
        }
      }
    },
    margeContainerParent: {
      "& > *:not(.noMarge)": {
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(0, 5),
          "&.MuiTextField-root label": {
            marginLeft: 15
          }
        }
      }
    },
    mobileSpacing: {
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
      }
    },
    mobileSpacingParent: {
      "& > *:not(.noMobileSpacing)": {
        [theme.breakpoints.down("xs")]: {
          "&:not(:first-of-type)": {
            marginTop: theme.spacing(5)
          },
          "&:not(:last-of-type)": {
            marginBottom: theme.spacing(5)
          }
        }
      }
    },
    marginBottom: {
      marginBottom: theme.spacing(5)
    },
    fullHeight: {
      height: "100%"
    },
    fullWidth: {
      width: "100%"
    },
    alignRight: {
      textAlign: "right"
    }
  })
);