import React, { useEffect, useState } from 'react';
import { Grid, Popover, Typography, FormControlLabel, Checkbox, Button, makeStyles, Slider } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MyTextField from "../../components/MyTextField";
import DateFnsUtils from '@date-io/date-fns';
import { fr } from "date-fns/locale";


const useStyles = makeStyles(() => ({
  buttonColorStyle: {
    color: "white",
    backgroundColor: "#0075b5",
    "&:hover": {
      backgroundColor: "#93C4DF",
    }
  },
  mark: {
    color: "black"
  }
}))

export interface PopoverFiltreProps {
  openFiltre: boolean;
  anchorElFiltre: (val: Element) => Element;
  handleCloseFiltre: () => void;
  recherche: string;
  setRecherche: (val: string) => void;
  dateDebut: Date;
  setDateDebut: (val: Date) => void;
  dateFin: Date;
  setDateFin: (val: Date) => void;
  filterConversation: () => void;
  filtreNonLu: boolean;
  setFiltreNonLu: (val: boolean) => void;
  maxArchive: string;
  setMaxArchive: (val: string) => void;
}

const PopoverFiltre = (props: PopoverFiltreProps) => {
  const { openFiltre, recherche, dateDebut, dateFin, anchorElFiltre, handleCloseFiltre, setRecherche, setDateDebut, setDateFin, filterConversation, filtreNonLu, setFiltreNonLu, maxArchive, setMaxArchive } = props;
  const { buttonColorStyle, mark } = useStyles();
  const idFiltre = openFiltre ? 'filtre-popover' : undefined;
  const [sliderValue, setSliderValue] = useState<number | number[]>(0);

  const marksSlider = [
    {
      value: 0,
      label: "50"
    },
    {
      value: 33,
      label: "100"
    },
    {
      value: 66,
      label: "200"
    },
    {
      value: 100,
      label: "TOUS"
    }
  ];

  function initMaxArchive() {
    const element = marksSlider.filter((element) => {
      if (element.value === sliderValue) {
        return true
      }
      return false
    })
    setMaxArchive(element[0].label)
  }

  function initSliderValue() {
    const element = marksSlider.filter((element) => {
      if (element.label === maxArchive) {
        return true
      }
      return false
    })

    setSliderValue(element[0].value)
  }

  useEffect(() => {
    initSliderValue()
  }, [maxArchive]);

  useEffect(() => {
    initMaxArchive()
  }, [sliderValue]);

  return (
    <Grid container>
      {/* FILTRE */}
      <Popover
        id={idFiltre}
        open={openFiltre}
        anchorEl={anchorElFiltre}
        onClose={handleCloseFiltre}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: { width: '280px', minHeight: "410px", borderRadius: 3 },
        }}
      >
        <Grid container alignItems='center' justify='center' spacing={2} style={{ padding: 10 }}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h4'>Filtres :</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MyTextField
              margin="normal"
              size='small'
              inputProps={{
                style: {
                  paddingLeft: "6px",
                },
              }}
              label={"Recherche"}
              value={recherche}
              onChange={(e) => setRecherche(e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant='h5'>Dates de recherche:</Typography>
          </Grid>
          {/* date début */}
          <Grid item xs={12} sm={10}>
            <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                format="dd/MM/yyyy"
                value={dateDebut}
                onChange={(e) => {
                  if (e !== null) {
                    setDateDebut(new Date(e.toDateString()))
                  } else {
                    setDateDebut(null)
                  }
                }}
                animateYearScrolling
                variant="inline"
                inputVariant="outlined"
                inputProps={{ style: { padding: "5px" } }}
                maxDate={dateFin}
                style={{ marginLeft: "5px", marginRight: "10px", marginBottom: "5px", fontSize: "80%" }}
                invalidDateMessage={"Format invalide, le format doit être JJ/MM/AAAA"}
                maxDateMessage={"La date de début ne peut pas être supérieure à la date de fin."}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={12} >
            <Typography variant='body1'>aux</Typography>
          </Grid>
          {/* date fin */}
          <Grid item xs={12} sm={10}>
            <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                format="dd/MM/yyyy"
                value={dateFin}
                onChange={(e) => {
                  if (e !== null) {
                    setDateFin(new Date(e.toDateString()))
                  } else {
                    setDateFin(null)
                  }
                }}
                animateYearScrolling
                variant="inline"
                inputVariant="outlined"
                minDate={dateDebut}
                inputProps={{ style: { padding: "5px" } }}
                style={{ marginLeft: "5px", marginRight: "10px", marginBottom: "5px" }}
                invalidDateMessage={"Format invalide, le format doit être JJ/MM/AAAA"}
                minDateMessage={"La date de début ne peut pas être supérieure à la date de début."}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={12} >
            <FormControlLabel control={
              <Checkbox
                color="primary"
                name="statutCode"
                checked={filtreNonLu}
                onChange={() => setFiltreNonLu(!filtreNonLu)} />}
                label={"Non lu"}
            />
          </Grid>
          <Grid item xs={12} sm={12} >
            <Typography variant='body1'>Maximum archives affichées</Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{paddingLeft: 10, paddingRight: 10}}>
            <Slider
              color="secondary"
              value={sliderValue}
              onChangeCommitted={(event, value) => setSliderValue(value)}
              aria-label="Always visible"
              defaultValue={0}
              step={null}
              marks={marksSlider}
              valueLabelDisplay="off"
              classes={{
                markLabel: mark
              }}
            />
          </Grid>
          <Grid item xs={8} sm={8} style={{ textAlign: "center" }}>
            <Button variant="contained" className={buttonColorStyle} onClick={() => filterConversation()} endIcon={<Search />}>
              Rechercher
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  )
}

export default PopoverFiltre;