import React from 'react';
import { Grid, Badge, Typography, makeStyles } from "@material-ui/core";
import { Mail } from "@material-ui/icons";
import { useMobileMode } from "../../utils/utils";
import { useCurrentUser } from "../auth/authApi";
import { Conversation } from "./MessagerieType";

const useStyles = makeStyles(() => ({
  notificationStyle: {
    fontWeight: "bold"
  },
  menuItemStyle: {
    minHeight: "50px"
  },
}))

export interface MenuConvItemProps {
  conversation: Conversation;
}

const MenuConvItem = (props: MenuConvItemProps) => {
  const { conversation } = props;
  const { notificationStyle, menuItemStyle } = useStyles();
  const mobileMode = useMobileMode();
  const { proprietes } = useCurrentUser();

  return (
    <Grid container className={menuItemStyle}>
      {conversation.messagesNonLus > 0 && (
        <Grid item xs={12} >
          <Badge badgeContent={conversation.messagesNonLus} color='secondary'>
            <Mail htmlColor='#545454' fontSize='small' />
          </Badge>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography className={conversation.messagesNonLus > 0 ? notificationStyle : ""} style={{ fontSize: mobileMode ? "100%" : "90%" }} variant="h5" gutterBottom >
          {proprietes.TYPE_UTILISATEUR?.valeur === "UTILISATEUR" ? (conversation.titre + " : " + conversation.sujet.libelle) : conversation.titre}
        </Typography>
      </Grid>
      {conversation.new !== true && (
        <Grid item xs={12}>
          <Typography className={conversation.messagesNonLus > 0 ? notificationStyle : ""} style={{ fontSize: "85%" }} variant="body1" gutterBottom >{"Dernier message : " + conversation.dateDernierEchange}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default MenuConvItem;