import 'reactjs-popup/dist/index.css';
import { Grid, Box, Paper, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGlobalStyles } from '../../style/globalStyles';
import { isEmptyVal, isMobile, useMobileMode } from '../../utils/utils';
import { useContexte, useCurrentUser } from '../auth/authApi';
import { Enquete } from "../enquete/EnqueteType";
import BlocEnqueteSlideAccueil from "../enquete/EnqueteSlideAccueil";
import clsx from 'clsx';
import { fetchListEnquetes, fetchListActualite } from "../enquete/PublicationApi";
import RoundedChart from './RoundedChart';
import ColumnChartKpi from './ColumnChartKpi';
import BlocEnqueteAccueilAucune from '../enquete/EnqueteAccueilAucune';
import AddToHomeScreenButton from './AddToHomeScreen';
import { addFcmToken } from './AccueilApi';
import IosNotification from './IosNotification';
import { getToken } from '../../firebase';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10
  },
  container: {
    textAlign: "center",
  }
})
)

const AccueilPage = () => {

  const dispatch = useDispatch();
  const globalClasses = useGlobalStyles();
  const { paper, container } = useStyles();
  const { mobileSpacing, margeContainer, marginBottom } = globalClasses;
  const mobileMode = useMobileMode();

  const user = useCurrentUser();
  const contexte = useContexte();

  const [enquetesPanel, setEnquetesPanel] = useState<Enquete[]>([]);
  const [actualitesPanel, setActualitesPanel] = useState<Enquete[]>([]);

  async function initToken() {
    const token = await getToken();
    if (token) {
      const listeToken: string[] = [];

      for (const key in user.proprietes) {
        if (key.includes("FCM_TOKEN")) {
          listeToken.push(user.proprietes[key].valeur)
        }
      }

      const savedToken = listeToken.find(element => element === token);
      if (isEmptyVal(savedToken)) {
        if (token) {
          dispatch(addFcmToken(user.userConvention.idUsersConvention, token, () => {/* */}));
        }
      }
    }
  }

  useEffect(() => {
    if(isMobile()){
      initToken()
    }
  }, []);

  useEffect(() => {
    function intiPublication(data: Enquete[]) {
      if (data) {
        setEnquetesPanel(data);
      } else {
        setEnquetesPanel([]);
      }
    }
    function intiActualite(data: Enquete[]) {
      if (data) {
        setActualitesPanel(data);
      } else {
        setActualitesPanel([]);
      }
    }

    dispatch(fetchListEnquetes(user.userConvention.idUsersConvention, intiPublication));
    dispatch(fetchListActualite(user.userConvention.idUsersConvention, intiActualite));

  }, [user, dispatch]);


  return (
    <Grid container alignContent='center' alignItems='center' spacing={mobileMode ? 0 : 10}>
      <Grid item xs={12} sm={12}>
        <AddToHomeScreenButton />
        <IosNotification />
      </Grid>
      <Grid item xs={12} sm={10} className={mobileSpacing}>
        {contexte === "PANELISTE" && (
          <>
            <Box className={clsx(margeContainer, marginBottom)}>
              {enquetesPanel && enquetesPanel.length > 0 && (<BlocEnqueteSlideAccueil titre={"Mes enquêtes en cours"} enquetes={enquetesPanel} type="ENQUETE" origine="ACCUEIL" />)}
              {enquetesPanel && enquetesPanel.length < 1 && (<BlocEnqueteAccueilAucune type="ENQUETE" origine="ACCUEIL" />)}
              {actualitesPanel && actualitesPanel.length > 0 && (<BlocEnqueteSlideAccueil titre={"Actualités"} enquetes={actualitesPanel} type="ACTUALITE" origine="ACCUEIL" />)}
              {actualitesPanel && actualitesPanel.length < 1 && (<BlocEnqueteAccueilAucune type="ACTUALITE" origine="ACCUEIL" />)}
            </Box>
          </>
        )}
        {contexte !== "PANELISTE" && (
          <>
            <Grid container spacing={mobileMode ? 0 : 10}>
              <Grid item xs={12}><Typography variant="h3">Tableau de bord agriscoop</Typography></Grid>
            </Grid>
            <Paper className={paper}>
              <Grid container className={container} spacing={mobileMode ? 0 : 10} justify="center">
                <Grid item xs={9} sm={4}>
                  <RoundedChart typePublication={"ENQUETE"} />
                </Grid>
                <Grid item xs={9} sm={4}>
                  <RoundedChart typePublication={"ACTUALITE"} />
                </Grid>
              </Grid>
              <Grid container className={container} spacing={mobileMode ? 0 : 10} alignItems="center" justify="center">
                <Grid item xs={12} sm={9}>
                  <ColumnChartKpi />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
      </Grid>
    </Grid >

  );
}

export default AccueilPage;