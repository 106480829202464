/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Paper, makeStyles, Grid, Typography, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import PageTemplate from '../../page/PageTemplate';
import { ChangeHandler, NavElement } from '../../utils/types';
import { useMobileMode } from '../../utils/utils';
import { useGlobalStyles } from '../../style/globalStyles';
import { useCurrentUser } from '../auth/authApi';
import { useDispatch } from 'react-redux';
import { TableSujets } from './MaterialTableSujets';
import { Sujet, UserSujetDto } from '../messagerie/MessagerieType';
import MyTextField from '../../components/MyTextField';
import { fetchListAllUsers, fetchListSujetUsers, fetchSujetsAdmin, saveSujet, saveUsersSujet } from '../messagerie/MessagerieApi';
import { TableUsersSujets } from './MaterialTableUsersSujet';
import { ArrowLeft } from '@material-ui/icons';

const NAV_ELEMENTS: NavElement[] = [{ name: "Messagerie", path: "/Messagerie" }];

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10
  },
})
)

const SujetsPage = () => {
  const dispatch = useDispatch();
  const { paper } = useStyles();
  const globalClasses = useGlobalStyles();
  const { mobileSpacing } = globalClasses;
  const mobileMode = useMobileMode();
  const { userConvention, proprietes } = useCurrentUser();
  const [stepSujet, setStepSujet] = useState<number>(0);
  const [sujetEncours, setSujetEncours] = useState<Sujet>(null);
  const [listeSujet, setListeSujet] = useState<Sujet[]>(null);
  const [showError, setShowError] = useState<boolean>(false);

  const [usersSelected, setUsersSelected] = useState<number[]>([]);
  const [users, setUsers] = useState<UserSujetDto[]>([]);

  const validForm = sujetEncours !== null && sujetEncours.libelle.trim() !== "" && sujetEncours.statut.trim() !== "";

  const handleChange: ChangeHandler = e => {
    const target = e.target

    setSujetEncours((prevState) => ({
      ...prevState, [target.name]: target.value
    }))
  }

  const handleChangeCheckBoxActif: ChangeHandler = e => {
    const target = e.target
    let value = 'I'

    if (target.checked) {
      value = '*'
    }

    setSujetEncours((prevState) => ({
      ...prevState, [target.name]: value
    }))
  }

  function initSujets(data: Sujet[]) {
    if (data) {
      setListeSujet(data)
    }
  }

  useEffect(() => {
    dispatch(fetchSujetsAdmin(initSujets))
  }, []);

  function fctCreateSujet() {
    const nouveauSujet: Sujet = {
      id: null,
      libelle: "",
      statut: "*",
      statutLibelle: "Actif"
    }
    setSujetEncours(nouveauSujet)
    setStepSujet(1)
  }

  function fctEditSujet(sujet: Sujet) {
    setSujetEncours(sujet)
    setStepSujet(2)
  }

  function initUsers(data: UserSujetDto[]) {
    if (data) {
      setUsers(data)
    }
  }

  function initUsersSelected(data: number[]) {
    if (data) {
      setUsersSelected(data)
    }
  }

  function fctDetailSujet(sujet: Sujet) {
    dispatch(fetchListAllUsers(initUsers))
    dispatch(fetchListSujetUsers(sujet.id, initUsersSelected))
    setSujetEncours(sujet)
    setStepSujet(3)
  }

  function fctAnnuler() {
    setSujetEncours(null)
    setStepSujet(0)
  }

  function fctEnregistrementSuccess(data: boolean) {
    if (data) {
      setShowError(false)
      dispatch(fetchSujetsAdmin(initSujets))
      setStepSujet(0)
    }
  }

  function fctEnregisterSujet() {
    if (validForm) {
      dispatch(saveSujet(sujetEncours, fctEnregistrementSuccess))
    } else {
      setShowError(true)
    }
  }

  function fctEnregisterUsersSujet() {
    dispatch(saveUsersSujet(usersSelected,sujetEncours.id, fctEnregistrementSuccess))
  }

  // OnSelect
  function selectUser(user: UserSujetDto) {
    const tmpUserSelected = [];
    let find = false;
    for (const idUsersConvention of usersSelected) {
      if (user.idUsersConvention == idUsersConvention) {
        find = true;
      } else {
        tmpUserSelected.push(idUsersConvention);
      }
    }
    if (!find) {
      tmpUserSelected.push(user.idUsersConvention);
    }
    setUsersSelected(tmpUserSelected);
  }


  return (
    <PageTemplate elements={NAV_ELEMENTS}>
      <Grid container spacing={mobileMode ? 0 : 10}>
        <Grid item xs={12} sm={12} className={mobileSpacing}>
          {/* TABLEAU DE BORD */}
          {stepSujet == 0 && (
            <>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12} sm={12}>
                  <Typography variant="h2" gutterBottom >Gestion des sujets</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={fctCreateSujet}>Créer un nouveau sujet</Button>
                </Grid>
                <Grid item xs={12} sm={9} className={mobileSpacing}>
                  <TableSujets fctEditSujet={fctEditSujet} fctDetailSujet={fctDetailSujet} values={listeSujet}></TableSujets>
                </Grid>
              </Grid>
            </>
          )}
          {/* CREATION/MODIFICATION */}
          {(stepSujet == 1 || stepSujet == 2) && (
            <>
              <Paper className={paper}>
                <Typography variant="h3" gutterBottom >{stepSujet == 1 ? "Création d'un nouveau sujet" : ("Edition du sujet : " + sujetEncours.libelle)}</Typography>
                <Grid container>
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Libellé"}</Typography>
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <MyTextField fullWidth margin="normal" label="" name="libelle" value={sujetEncours.libelle} onChange={handleChange} inputProps={{ style: { padding: "6px" } }} style={{ margin: "5px" }}
                      errorMsg={(sujetEncours.libelle == null || sujetEncours.libelle.trim() === "") && showError ? "Veuillez remplir le champ" : ""} />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Typography variant="h6" color="textPrimary" gutterBottom align="left" >{"Statut"}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <FormControlLabel control={
                      <Checkbox color="primary" name="statut" checked={sujetEncours.statut === '*'} onChange={handleChangeCheckBoxActif} />}
                      label={"Actif"}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" justify="center" spacing={10}>
                  <Grid item xs={12} sm={5} >
                    <Button fullWidth size="small" variant="outlined" color="primary" onClick={fctAnnuler}>ANNULER</Button>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Button fullWidth size="small" disabled={!validForm && showError} variant="contained" color="primary" onClick={fctEnregisterSujet}>ENREGISTRER</Button>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {/* GESTION DES USERS */}
          {stepSujet == 3 && (
            <>
              <Paper className={paper}>
                <Grid container alignItems="center" justify="center">
                  <Grid item xs={12} sm={12} className={mobileSpacing}>
                    <Button variant="text" color="primary" disableElevation startIcon={<ArrowLeft htmlColor="#A0A0A0" fontSize="large" />} size="large" onClick={fctAnnuler}>
                      {"Retour au tableau de bord"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} className={mobileSpacing}>
                    <Typography variant="h2" color="textPrimary" gutterBottom align="center" >{"Sujet : " + sujetEncours.libelle}</Typography>
                  </Grid>
                  <Grid item xs={10} sm={8}>
                    <TableUsersSujets userSelected={usersSelected} onSelect={selectUser} values={users}></TableUsersSujets>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" justify="center" spacing={10} style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={4} >
                    <Button fullWidth size="small" variant="outlined" color="primary" onClick={fctAnnuler}>ANNULER</Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button fullWidth size="small" disabled={!validForm && showError} variant="contained" color="primary" onClick={fctEnregisterUsersSujet}>ENREGISTRER</Button>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </Grid>
      </Grid>
    </PageTemplate>
  )
}

export default SujetsPage;