import { Breadcrumbs, Link, makeStyles, Typography } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import { NavElement } from '../utils/types';
import colors from '../style/colors';
import { PATH } from '../utils/constants';

const useStyles = makeStyles({
  root: {
    color: colors.gris10,
    paddingBottom: "15px"
  }
})

export interface BreadCrumbProps {
  elements: NavElement[];
}

const BreadCrumb = (props: BreadCrumbProps) => {
  const { elements } = props;
  const { root } = useStyles();

  const breadCrumbItems = elements.map((element: NavElement, index: number) => (
    index === elements.length - 1 || !element.path ?
      <Typography key={index}>{element.name}</Typography> :
      <Link color="inherit" key={index} href={PATH + element.path}>{element.name}</Link>
  ))

  return (
    <Breadcrumbs color="textPrimary" className={root} separator={<NavigateNextIcon fontSize="small" />}>
      <Link color="inherit" href={PATH + "/Accueil"}>Accueil</Link>
      {breadCrumbItems}
    </Breadcrumbs>
  );
}

export default BreadCrumb;