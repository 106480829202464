import 'reactjs-popup/dist/index.css';
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ConnexionDetail, ConnexionsKpiObj } from './AccueilType';
import { fetchKpiConnexionsDetails } from './AccueilApi';
import { MaterialTableUserConnexion } from './MaterialTableUserConnexion';


export interface DetailsConnexionTableProps {
  dateDebut: Date;
  dateFin: Date;
  dateOk: boolean;
  closeFct: () => void;
}

const DetailsConnexionTable = (props: DetailsConnexionTableProps) => {
  const { dateDebut, dateFin, dateOk, closeFct } = props;

  const dispatch = useDispatch();
  const [dataConnexion, setDataConnexion] = useState<ConnexionDetail[]>([]);


  function initConnexionDetails(data: ConnexionDetail[]) {
    if (data) {
      setDataConnexion(data)
    }
  }

  useEffect(() => {
    if (dateOk) {
      const dateDebutStr = dateDebut.toLocaleDateString("fr-FR");
      const dateFinStr = dateFin.toLocaleDateString("fr-FR");

      const connexionKpiObj: ConnexionsKpiObj =
      {
        dateDebut: dateDebutStr,
        dateFin: dateFinStr
      };

      dispatch(fetchKpiConnexionsDetails(connexionKpiObj, initConnexionDetails));
    }

  }, [dateDebut, dateFin]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Button variant='contained' color='primary' onClick={closeFct}>
          Fermer détails des connexions
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} style={{ marginTop: "20px", marginBottom: "10px" }}>
        <Typography variant="h3" color="textPrimary" gutterBottom align="center" >{"Détails des connexions"}</Typography>
      </Grid>
      {dataConnexion.length > 0 ? (
        <>
          <Grid item xs={12} sm={12}>
            <MaterialTableUserConnexion values={dataConnexion}></MaterialTableUserConnexion>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} sm={12}>
          <Typography variant='h4'>{"Aucune donnée sur la période"}</Typography>
        </Grid>
      )}

    </Grid>
  );
}

export default DetailsConnexionTable;