import React, { useState,useEffect } from 'react';
import { Paper, Grid, makeStyles, Hidden,Typography,OutlinedInput,InputAdornment,IconButton,Button} from "@material-ui/core";
import images from '../../img/images';
import { useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {fetchControleCodeCrypte,fetchChangeMDP} from "./InscriptionApi";
import {InscriptionInfoCompte} from "./InscriptionType";
import { ChangeHandler } from '../../utils/types';
import {Visibility,VisibilityOff,Block,CheckCircle} from "@material-ui/icons/";
import colors from '../../style/colors';
import { PATH } from "../../utils/constants";
import { push } from "connected-react-router";
import { isEmptyVal } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding:10
  },
  divider: {
    margin: "20px 0",
    backgroundColor: colors.noir +"! important"
  }, paperConfirm: {
    padding: theme.spacing(10)
  },
  paperText: {
    fontWeight: theme.typography.fontWeightMedium
  }
}))

export interface ValidationInscriptionPageProps {
    init: boolean;
  }

const ValidationInscriptionPage = (props: ValidationInscriptionPageProps) => {
  
const {init} = props;
  const { paper} = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

 function rechercheParametre(key: string){
    const tabParams = location.search.replace("?","").split("&");
    if(tabParams){
        for (const param of tabParams) {
            if(param){
                if(param.split("=")[0] === key){
                    return param.split("=")[1]
                }
            }
        }
    }
  }
  const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&.{}(),><:|~=])[A-Za-z\\d@$!%*?&.{}(),><:|~=]{8,}$');
  const minusRegex = new RegExp('([a-z])');
  const majuRegex = new RegExp('([A-Z])');
  const caraRegex = new RegExp('([@$!%*?&.{}(),><:|~=])');
  const chifRegex = new RegExp('([0-9])');
  const [msgErreur,setMsgErreur]= useState<string>(null);

  function checkPassWord(pass: string,type: string){

    if(pass===undefined || pass==="" || pass===null)return false;

    if(type==="ALL"){
        setMsgErreur(null);
        const chars = pass.split('');
        for (const chhh of chars){
                 if(majuRegex.test(chhh))continue;
                 if(minusRegex.test(chhh))continue;
                 if(caraRegex.test(chhh))continue;
                 if(chifRegex.test(chhh))continue;
                 setMsgErreur("<font color='"+colors.rouge+"'>Votre mot de passe ne doit pas contenir le caractère : \"   <strong style='font-size: 20px;'>" +chhh+"</strong>\"</font>");
                 break;  
         }
    }
    
    if(type==="ALL")return strongRegex.test(pass) ;
    if(type==="TAILLE")return pass && pass.length>=8 ;
    if(type==="MAJ") return majuRegex.test(pass);
    if(type==="MINUS") return minusRegex.test(pass);
    if(type==="CARAC") return caraRegex.test(pass);
    if(type==="CHIF") return chifRegex.test(pass);
    return false;
   }

  const [step,setStep]= useState<number>(1);
  const [codeCrypt,setCodeCrypt]= useState<string>(null);
  const [loadInfo,setLoadInfo]= useState<boolean>(false);
  const [infoCompte,setInfoCompte] = useState<InscriptionInfoCompte>(null);

  const [loginUser,setLoginUser]= useState<string>(null);

  const [validPass,setValidPass]= useState<boolean>(false);
  const [validPass2,setValidPass2]= useState<boolean>(false);
  const [passWord,setPassWord]= useState<string>(null);
  const [showPassWord,setShowPassWord]= useState<boolean>(false);
  const [passWord2,setPassWord2]= useState<string>(null);
  const [showPassWord2,setShowPassWord2]= useState<boolean>(false);
  const handlePassWord: ChangeHandler = e => setPassWord(e.target.value);
  const handlePassWord2: ChangeHandler = e => setPassWord2(e.target.value);


  function handleShowPassWord(){
    setShowPassWord(!showPassWord);
  }
  function handleShowPassWord2(){
    setShowPassWord2(!showPassWord2);
  }

  useEffect(() => {
    setCodeCrypt(rechercheParametre("h"));
   }, []);

   useEffect(() => {
    function initInfo(data: InscriptionInfoCompte){
        if(data){
            setInfoCompte(data);
        }else{
            setInfoCompte(null);
        }
        setLoadInfo(true);
    }
    if(codeCrypt!=null && codeCrypt!=undefined){
        dispatch(fetchControleCodeCrypte(codeCrypt,init,initInfo));
    }
   }, [codeCrypt]);


   useEffect(() => {
    setValidPass(checkPassWord(passWord,"ALL"));
   }, [passWord]);

   useEffect(() => {
    setValidPass2(checkPassWord(passWord2,"ALL"));
   }, [passWord2]);
   

   function activation(){
    function succesActivation(data: string){
        if(!isEmptyVal(data)){
            setLoginUser(data);
            setStep(2);
        }
    }
    dispatch(fetchChangeMDP(codeCrypt,passWord,init,succesActivation));
   }

   function next(){
        dispatch(push(PATH + "/Login"))
    }

  return (
    <>
    <Grid container direction="row">
        <Hidden xsDown><Grid item xs={3}></Grid></Hidden>
        <Grid item xs={12} sm={6}>
            <Paper className={paper}>
                <Grid container alignContent="center" justify="center" alignItems="center">
                    <Grid item xs={12}><img src={images.logos.emc2[0]} height={80} /></Grid>
                </Grid>
                {!loadInfo && (
                    <>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                           <Typography variant="h3" gutterBottom >{"Récupération de vos informations"}</Typography>
                        </Grid>
                    </Grid>
                    </>
                )}
                {loadInfo && !infoCompte && (
                    <>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                           <Typography variant="h3" gutterBottom >{"Erreur lors de la récupération de vos informations"}</Typography>
                        </Grid>
                    </Grid>
                    </>
                )}
                {loadInfo && infoCompte && step==1 && (
                    <>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h3" gutterBottom >{"Bonjour " +infoCompte.nomContact + " ("+infoCompte.raisonSociale+")"}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {init && 
                            (
                                <>
                                    <Typography variant="h5" color="textPrimary"  gutterBottom align="left" >{"Afin d'activer votre accès Agriscoop, vous devez initialiser votre mot de passe."}</Typography>
                                </>
                            )}
                            {!init && 
                            (
                                <>
                                    <Typography variant="h5" color="textPrimary"  gutterBottom align="left" >{"Modification du mot de passe."}</Typography>
                                </>
                            )}
                          </Grid>
                        <Grid item xs={12} sm={12} style={{paddingTop:"15px"}}>
                            <Typography variant="h6" color="textPrimary"  gutterBottom align="left" >{"Veuillez saisir votre nouveau mot de passe."}</Typography>
                        </Grid>
                        <Grid item xs={12}  sm={12}>
                            <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassWord ? 'text' : 'password'}
                                    value={passWord}
                                    onChange={handlePassWord}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassWord} >
                                        {showPassWord ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    label="Password"
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{paddingTop:"15px"}}>
                            <Typography variant="h6" color="textPrimary"  gutterBottom align="left" >{"Veuillez confirmer votre nouveau mot de passe."}</Typography>
                        </Grid>
                        <Grid item xs={12}  sm={12}>
                             <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassWord2 ? 'text' : 'password'}
                                    value={passWord2}
                                    onChange={handlePassWord2}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassWord2} >
                                        {showPassWord2 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    label="Password"
                                />
                        </Grid>
                        <Grid container justify='center' style={{paddingTop:"15px"}}>
                            <Grid item xs={12} sm={12}>
                                <Grid container>
                                    <Grid item xs={2} sm={1} style={{textAlign:"right"}}>
                                        {checkPassWord(passWord,"TAILLE") ? <CheckCircle htmlColor={colors.vert}/> : <Block htmlColor={colors.rouge} />}
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Typography variant="h6" color="textPrimary"  gutterBottom align="left" > au moins 8 caractères</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container>
                                    <Grid item xs={2} sm={1} style={{textAlign:"right"}}>
                                        {checkPassWord(passWord,"MINUS") ? <CheckCircle htmlColor={colors.vert}/> : <Block htmlColor={colors.rouge} />}
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Typography variant="h6" color="textPrimary"  gutterBottom align="left" > au moins une minuscule</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container>
                                    <Grid item xs={2} sm={1} style={{textAlign:"right"}}>
                                        {checkPassWord(passWord,"MAJ") ? <CheckCircle htmlColor={colors.vert}/> : <Block htmlColor={colors.rouge} />}
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Typography variant="h6" color="textPrimary"  gutterBottom align="left" > au moins une majuscule</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container>
                                    <Grid item xs={2} sm={1} style={{textAlign:"right"}}>
                                        {checkPassWord(passWord,"CHIF") ? <CheckCircle htmlColor={colors.vert}/> : <Block htmlColor={colors.rouge} />} 
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Typography variant="h6" color="textPrimary"  gutterBottom align="left" > au moins un chiffre</Typography> 
                                    </Grid>
                                </Grid>
                            </Grid>  
                            <Grid item xs={12} sm={12}>
                                <Grid container>
                                    <Grid item xs={2} sm={1} style={{textAlign:"right"}}>
                                        {checkPassWord(passWord,"CARAC") ? <CheckCircle htmlColor={colors.vert}/> : <Block htmlColor={colors.rouge} />}
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Typography variant="h6" color="textPrimary"  gutterBottom align="left" > {"au moins un caractère spécial (@$!%*?&.{}(),><:|~=)"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>  
                            {((!validPass || !validPass2 || passWord!==passWord2) && (!isEmptyVal(passWord) || !isEmptyVal(passWord2))) &&
                             <Grid item xs={12} sm={12}>
                                <Typography variant="h5" color="textPrimary"  gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "<font color='"+colors.rouge+"'>Votre mot de passe n'est pas correct.</font>"}} ></Typography>
                             </Grid> 
                        }
                            {(!isEmptyVal(passWord) || !isEmptyVal(passWord2)) && passWord!==passWord2 && (
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="h5" color="textPrimary"  gutterBottom align="left" dangerouslySetInnerHTML={{ __html: "<font color='"+colors.rouge+"'>Vous devez saisir deux fois le même mot de passe.</font>"}} ></Typography>
                                </Grid>    
                            )}    
                            {!isEmptyVal(msgErreur) && (
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="h5" color="textPrimary"  gutterBottom align="left" dangerouslySetInnerHTML={{ __html: msgErreur}} ></Typography>
                                </Grid>    
                            )}   
                        </Grid>
                        <Grid container spacing={10}>
                            <Grid item xs={12} sm={6}><Button fullWidth variant="contained" color="primary" onClick={activation} disabled={!validPass || !validPass2 || passWord!==passWord2} >{init?"ACTIVER MON ACCES":"CREER NOUVEAU MOT DE PASSE"}</Button></Grid>
                        </Grid>
                    </Grid>
                    </>
                )}
                {loadInfo && step===2 && init && 
                (
                    <>
                        <Grid container>
                            <Grid item xs={12}><Typography  variant="h3" dangerouslySetInnerHTML={{ __html: "Votre accès à Agriscoop est activé"}}  gutterBottom ></Typography> </Grid>
                            <Grid item xs={12}><Typography variant="h5" dangerouslySetInnerHTML={{ __html: "Votre identifiant est <strong>"+loginUser+"</strong>"}}  gutterBottom ></Typography> </Grid>
                            <Grid item xs={12} sm={6} style={{textAlign:"center",paddingBottom:"20px"}}><Button fullWidth variant="contained" color="primary" onClick={next}>ME CONNECTER</Button></Grid>
                            <Grid item xs={12}><Typography variant="h6">{"Vous pouvez nous contacter par courriel à contact@agriscoop.fr."}</Typography></Grid>
                         </Grid>
                    </>
                )}
                {loadInfo && step===2 && !init && 
                (
                    <>
                        <Grid container>
                            <Grid item xs={12} sm={12}><Typography variant="body2" component="div" dangerouslySetInnerHTML={{ __html: "Votre mot de passe a bien été modifié." }}  gutterBottom ></Typography></Grid>
                            <Grid item xs={12} sm={6} style={{textAlign:"center"}}><Button fullWidth variant="contained" color="primary" onClick={next}>ME CONNECTER</Button></Grid>
                        </Grid>
                    </>
                )}
            </Paper>
        </Grid>
    <Hidden xsDown><Grid item xs={3}></Grid></Hidden>
    </Grid>
    </>
  );
}

export default ValidationInscriptionPage;
