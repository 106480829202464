/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, Link, Button, Menu, MenuItem } from '@material-ui/core';
import { isEmptyVal, stringToDateHour, useMobileMode } from '../../utils/utils';
import { Conversation, Sujet } from './MessagerieType';
import { Add, Clear, Folder, FolderOpen, Search } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { fetchSujets } from './MessagerieApi';
import { useCurrentUser } from '../auth/authApi';
import MenuConvItem from './MenuConvItem';
import PopoverFiltre from './PopoverFiltre';

const useStyles = makeStyles(() => ({
  categorieStyle: {
    borderBottom: "2px solid #3F6B84",
    marginBottom: 2
  },
  conversationStyle: {
    padding: "4px !important",
    cursor: "pointer",
    borderBottom: "1px solid #3F6B84",
    "&:hover": {
      backgroundColor: "#e5f1f7",
      borderRadius: "3px 0px 0px 3px",
      boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
      borderBottom: "0px !important"
    }
  },
  selectStyle: {
    padding: "4px !important",
    backgroundColor: "#B7D7EA",
    borderRadius: "3px 0px 0px 3px",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    color: "white !important",
    "&:hover": {
      backgroundColor: "#B7D7EA !important",
      opacity: "0.7 !important"
    },
  },
  notificationStyle: {
    fontWeight: "bold"
  },
  conversationStackStyle: {
    paddingTop: 10,
    maxHeight: "225px",
    overflow: "auto"
  },
  menuItemStyle: {
    minHeight: "50px"
  },
  buttonDeleteStyle: {
    color: "#E60000",
    borderColor: "#E60000",
    "&:hover": {
      color: "#EE5858",
      borderColor: "#EE5858",
    }
  },
  buttonColorStyle: {
    color: "white",
    backgroundColor: "#0075b5",
    "&:hover": {
      backgroundColor: "#93C4DF",
    }
  },
}))


export interface MenuPageProps {
  conversationActive: Conversation[];
  conversationArchive: Conversation[];
  fctSelectConversation: (conversation: Conversation) => void;
  currentConversation: Conversation;
  fctCreateConversation: (sujet: Sujet) => void;
  maxArchive: string;
  setMaxArchive: (val: string) => void;

}

const MenuPage = (props: MenuPageProps) => {
  const dispatch = useDispatch();
  const { proprietes } = useCurrentUser();
  const { categorieStyle, conversationStyle, selectStyle, conversationStackStyle, buttonColorStyle, buttonDeleteStyle } = useStyles();
  const { conversationActive, conversationArchive, fctSelectConversation, currentConversation, fctCreateConversation, maxArchive, setMaxArchive } = props;
  const mobileMode = useMobileMode();
  const [currentConversationId, setCurrentConversationId] = useState<number>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [sujets, setSujets] = useState<Sujet[]>([]);

  //POPOVER FILTRE
  const [openFiltre, setOpenFiltre] = useState<boolean>(false);
  const [anchorElFiltre, setAnchorElFiltre] = useState(null);
  const [dateDebut, setDateDebut] = useState<Date>(null);
  const [dateFin, setDateFin] = useState<Date>(null);
  const [recherche, setRecherche] = useState<string>("");
  const [filtreNonLu, setFiltreNonLu] = useState<boolean>(false);

  const [localConvActive, setLocalConvActive] = useState<Conversation[]>([]);
  const [localConvArchive, setLocalConvArchive] = useState<Conversation[]>([]);


  const handleOpenFiltre = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFiltre(event.currentTarget)
    setOpenFiltre(true)
  };

  const handleCloseFiltre = () => {
    setAnchorElFiltre(null)
    setOpenFiltre(false)
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function addConv(sujet: Sujet) {
    setAnchorEl(null)
    fctCreateConversation(sujet)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function filterConversation() {

    const convActive = conversationActive.filter((conv => {
      let valid = true;
      if (recherche.trim() !== "") {
        valid = valid && conv.titre.toLowerCase().includes(recherche.trim().toLowerCase())
      }

      if (conv.dateDernierEchange !== null && conv.dateDernierEchange !== undefined) {
        const dateDernierEchange = stringToDateHour(conv.dateDernierEchange)
        if (dateDebut !== null) {
          if (dateDernierEchange >= dateDebut) {
            valid = valid && true
          } else {
            valid = false
          }
        }
        if (dateFin !== null) {
          if (dateDernierEchange <= dateFin) {
            valid = valid && true
          } else {
            valid = false
          }
        }
      }

      if (filtreNonLu) {
        if (conv.messagesNonLus > 0) {
          valid = valid && true
        } else {
          valid = false
        }
      }

      return valid
    }));

    const convArchive = conversationArchive.filter((conv => {
      let valid = true;
      if (recherche.trim() !== "") {
        valid = valid && conv.titre.toLowerCase().includes(recherche.trim().toLowerCase())
      }
      if (conv.dateDernierEchange !== null && conv.dateDernierEchange !== undefined) {
        const dateDernierEchange = stringToDateHour(conv.dateDernierEchange)
        if (dateDebut !== null) {
          if (dateDernierEchange >= dateDebut) {
            valid = valid && true
          } else {
            valid = false
          }
        }
        if (dateFin !== null) {
          if (dateDernierEchange <= dateFin) {
            valid = valid && true
          } else {
            valid = false
          }
        }
      }

      if (filtreNonLu) {
        if (conv.messagesNonLus > 0) {
          valid = valid && true
        } else {
          valid = false
        }
      }

      return valid
    }));

    setLocalConvActive(convActive)
    setLocalConvArchive(convArchive)
    handleCloseFiltre()
  }

  function deleteFiltre() {
    setDateDebut(null)
    setDateFin(null)
    setRecherche("")
    setFiltreNonLu(false)
    setLocalConvActive(conversationActive)
    setLocalConvArchive(conversationArchive)
  }

  useEffect(() => {
    filterConversation()
  }, [conversationActive, conversationArchive]);

  useEffect(() => {
    if (!isEmptyVal(currentConversation)) {
      setCurrentConversationId(currentConversation.idConversation)
    } else {
      setCurrentConversationId(null)
    }
  }, [currentConversation]);

  function initSujets(data: Sujet[]) {
    if (data) {
      setSujets(data)
    }
  }

  useEffect(() => {
    dispatch(fetchSujets(initSujets));
  }, []);

  function handleChangeSelect(conv: Conversation) {
    fctSelectConversation(conv)
  }

  return (
    <Grid container alignItems="flex-start" justify="center" spacing={mobileMode ? 0 : 10} style={{ height: "625px" }}>
      {/* BLOC 1 */}
      <Grid container item alignItems="center" justify="center">
        {/* NOUVELLE CONVERSATION */}
        {proprietes.TYPE_UTILISATEUR?.valeur === "PANELISTE" && (
          <Grid item xs={12} sm={12} alignItems="center" justify="center" style={{ marginBottom: 5 }} >
            <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                size='small'
                endIcon={<Add />}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={buttonColorStyle}
              >
                Nouvelle Conversation
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {sujets.map(sujet =>
                  <MenuItem key={sujet.id} onClick={() => addConv(sujet)} >{sujet.libelle}</MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
        )}
        {/* FILTRES */}
        {proprietes.TYPE_UTILISATEUR?.valeur === "UTILISATEUR" && (
          <Grid container spacing={2} alignItems="center" justify="center" style={{ marginBottom: 5, height: 40, textAlign: "center" }} >
            <Grid item style={{ textAlign: "center", height: 40 }}>
              <Button
                variant="contained"
                size='small'
                endIcon={<Search />}
                onClick={handleOpenFiltre}
                className={buttonColorStyle}
              >
                FILTRES
              </Button>
            </Grid>
            {(dateDebut !== null || dateFin !== null || filtreNonLu || recherche.trim() !== "") && (
              <Grid item style={{ textAlign: "center", height: 40 }}>
                <Button
                  variant="outlined"
                  size='small'
                  endIcon={<Clear />}
                  onClick={deleteFiltre}
                  className={buttonDeleteStyle}
                >
                  SUPPRIMER
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        <Grid container alignItems="center" justify="center" className={categorieStyle}>
          <Grid item xs={2} style={{ paddingBottom: 4 }} >
            <FolderOpen fontSize='large' />
          </Grid>
          <Grid item xs={10} >
            <Typography variant="h4" gutterBottom >Conversations actives</Typography>
          </Grid>
        </Grid>
        {/* ACTIF */}
        <Grid container alignItems="flex-start" justify="center" className={conversationStackStyle} spacing={0}>
          {localConvActive?.map(conv => (
            <Grid key={conv.idConversation} item xs={12} sm={12} className={conv.idConversation === currentConversationId ? selectStyle : conversationStyle}>
              <Link onClick={() => handleChangeSelect(conv)} underline='none'>
                <MenuConvItem conversation={conv} />
              </Link>
            </Grid>
          ))}
          {/* AUCUNE ACTIF */}
          {localConvActive.length === 0 && (
            <Grid item xs={12} sm={12}>
              <Typography align='center' variant="body1" gutterBottom >Aucune conversation active</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* BLOC 2 */}
      <Grid container item alignItems="center" justify="center">
        <Grid container alignItems="center" justify="center" className={categorieStyle}>
          <Grid item xs={2} style={{ paddingBottom: 4 }} >
            <Folder fontSize='large' />
          </Grid>
          <Grid item xs={10} >
            <Typography variant="h4" gutterBottom >Conversations archivées</Typography>
          </Grid>
        </Grid>
        {/* ARCHIVES */}
        <Grid container alignItems="flex-start" justify="center" className={conversationStackStyle} spacing={0}>
          {localConvArchive?.map(conv => (
            <Grid key={conv.idConversation} item xs={12} sm={12} className={conv.idConversation === currentConversationId ? selectStyle : conversationStyle}>
              <Link onClick={() => handleChangeSelect(conv)} underline='none'>
                <MenuConvItem conversation={conv} />
              </Link>
            </Grid>
          ))}
          {/* AUCUNE ARCHIVE */}
          {localConvArchive.length === 0 && (
            <Grid item xs={12} sm={12}>
              <Typography align='center' variant="body1" gutterBottom >Aucune conversation archivée</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      <PopoverFiltre
        openFiltre={openFiltre}
        anchorElFiltre={anchorElFiltre}
        handleCloseFiltre={handleCloseFiltre}
        recherche={recherche}
        setRecherche={setRecherche}
        dateDebut={dateDebut}
        setDateDebut={setDateDebut}
        dateFin={dateFin}
        setDateFin={setDateFin}
        filterConversation={filterConversation}
        filtreNonLu={filtreNonLu}
        setFiltreNonLu={setFiltreNonLu}
        maxArchive={maxArchive}
        setMaxArchive={setMaxArchive}
      />
    </Grid>
  )
}

export default MenuPage;
